/*---- Charts -----*/

.chart-visitors {
	min-height: 18rem;
}

.chart-tasks {
	height: 15rem;
}

.chart-donut,
.chart-pie {
	height: 21rem;
}

.chartsh {
	height: 16rem;
}

.chartwidget {
	height: 17rem;
}

.chartmorris {
	height: 19rem;
}

.dropshadow {
	-webkit-filter: drop-shadow(0 -6px 4px rgb(227, 232, 239));
	filter: drop-shadow(0 -6px 4px rgb(227, 232, 239));
}

.chart,
.map {
	position: relative;
	padding-top: 56.25%;
}

.chart-square,
.map-square {
	padding-top: 100%;
}

.chart-content,
.map-content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}


/*------ Maps -----*/

.map-header {
	margin-bottom: 1.5rem;
	height: 15rem;
	position: relative;
	margin-bottom: -1.5rem;
	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 10rem;
		background: linear-gradient(to bottom, rgba(245, 247, 251, 0) 5%, $background 95%);
		pointer-events: none;
	}
}

.map-header-layer {
	height: 100%;
}

.map1 {
	height: 100%;
	position: relative;
	margin-bottom: -1.5rem;
	margin-bottom: 1.5rem;
}

.map-static {
	height: 120px;
	width: 100%;
	max-width: 640px;
	background-position: center center;
	background-size: 640px 120px;
}

@-webkit-keyframes status-pulse {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: .32;
	}
}

@keyframes status-pulse {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: .32;
	}
}


/*------ Chart-Circle ------*/

.chart-circle {
	display: block;
	height: 8rem;
	width: 8rem;
	position: relative;
	margin: 0 auto;
	canvas {
		margin: 0 auto;
		display: block;
		max-width: 100%;
		max-height: 100%;
	}
}

.chart-circle-xs {
	height: 2.5rem;
	width: 2.5rem;
	font-size: .8rem;
}

.chart-circle-xs1 {
	height: 110px;
	width: 110px;
	font-size: .8rem;
}

.chart-circle-sm {
	height: 4rem;
	width: 4rem;
	font-size: .8rem;
}

.chart-circle-lg {
	height: 10rem;
	width: 10rem;
	font-size: .8rem;
}

.chart-circle-value {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	bottom: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-direction: column;
	flex-direction: column;
	line-height: 1;
	small {
		display: block;
		color: #a7b4c9;
		font-size: .9375rem;
	}
}

.sparkgraph {
	display: inline-block;
	vertical-align: top;
	width: 100%;
	height: 43px;
	canvas {
		width: 100% !important;
	}
}

.chips {
	margin: 0 0 -.5rem;
}

.team i {
	margin-left: 10px;
	float: right;
	margin-top: 10px;
	color: #313148;
}

.chips .chip {
	margin: 0 .5rem .5rem 0;
}

.chip {
	display: inline-block;
	height: 2rem;
	line-height: 2rem;
	font-size: .875rem;
	font-weight: 500;
	color: $color;
	margin: 2px;
	padding: 0 .75rem;
	border-radius: 1rem;
	background-color: #f6f7fb;
	transition: .3s background;
	.avatar {
		float: left;
		margin: 0 .5rem 0 -.75rem;
		height: 2rem;
		width: 2rem;
		border-radius: 50%;
	}
}

a.chip:hover {
	color: $white;
	text-decoration: none;
}

.emp-tab table {
	margin-bottom: 0;
	border: 0;
}

.stamp {
	color: $white;
	background: #868e96;
	display: inline-block;
	min-width: 2rem;
	height: 2rem;
	padding: 0 .25rem;
	line-height: 2rem;
	text-align: center;
	border-radius: 3px;
	font-weight: 600;
}

.stamp-md {
	min-width: 2.5rem;
	height: 2.5rem;
	line-height: 2.5rem;
}

.stamp-lg {
	min-width: 3rem;
	height: 3rem;
	line-height: 3rem;
}

.amcharts-chart-div a,
.canvasjs-chart-container a {
	display: none !important;
}

.rotation {
	-webkit-animation: rotation 2s infinite linear;
	animation: rotation 2s infinite linear;
}

@-webkit-keyframes rotation {
	from {
		-webkit-transform: rotate(0);
	}
	to {
		-webkit-transform: rotate(359deg);
	}
}

.amChartsLegend.amcharts-legend-div {
	display: none;
}

.map-header1 {
	margin-bottom: 1.5rem;
	height: 23.2rem;
	position: relative;
	margin-bottom: -1.5rem;
	border: 1px solid #e8ebf3;
}

.axgmap {
	position: relative;
	width: 100%;
	height: 600px;
}

.axgmap-img {
	width: 30px;
	height: 30px;
	text-align: center;
	background: $white;
}

.gm-style .gm-style-iw {
	padding: 18px;
	overflow: hidden;
	display: block;
	margin: 0 auto;
	padding-bottom: 0;
	text-align: center;
	min-width: 260px !important;
	width: 260px !important;
	h4 {
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
	}
}

.gm-ui-hover-effect {
	opacity: 1;
    top: 10px !important;
    right: 10px !important;
    background: #dadae2 !important;
    border-radius: 50px;
}

.gm-style .gm-style-iw a.btn {
	margin-bottom: 6px;
}

@media (min-width: 991.98px) and (max-width: 1359px) {
	.map-content-width {
		width: 80%;
		overflow:auto;
	}
	.map-width {
		width: 20%;
	}
}

@media (max-width: 991.98px) and (min-width: 767px) {
	.map-width {
		display: contents;
	}
}

@media (min-width: 1360px) {
	.map-content-width {
		width: 65%;
		overflow:auto;
	}
	.map-width {
		width: 35%;
	}
}
.mh-500 {
	max-height: 500px;
}

.relative {
	position: relative !important;
}

.zindex1 {
	z-index: 1 !important;
}

.zindex2 {
	z-index: 2 !important;
}

.map-absolute {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 30px;
	border-bottom: 0 !important;
}
@media (max-width:991.98px){
	.map-content-search{
		position: relative;
		margin-top: 0;
		margin: 0 20px;
		width: auto;
		border-radius: 8px !important;
	}
	.map-content-width .vscroll{
		margin-top: 50px;
		height: auto;
	}
	.full-height-banner .axgmap{
		margin-top:0px;
	}
}