.social-box {
	min-height: 160px;
	margin-bottom: 20px;
	text-align: center;
	background: $white;
	border-radius: 8px;
	box-shadow: 0 0 0 1px rgba(181, 201, 227, 0.12), 0 8px 16px 0 rgba(181, 201, 227, 0.24);
	i {
		display: block;
		font-size: 40px;
		background: #e9ecef;
		line-height: 110px;
	}
	
	&.facebook i {
		color: $white;
		background: #5e5baa;
		border-radius: 8px 8px 0 0;
	}

	ul {
		list-style: none;
		display: inline-block;
		margin: 7px 0 0;
		padding: 10px;
		width: 100%;
		li {
			color: #949ca0;
			font-size: 14px;
			font-weight: 700;
			padding: 0 10px 0 0;
			text-align: center;
			display: block;
			float: left;
			width: 50%;
			line-height: 2;
			strong {
				display: block;
				font-size: 17px;
				line-height: 1;
				color: $color;
			}
			span {
				font-size: 15px;
				font-weight: 500;
				color: $color;
				text-transform: uppercase;
			}
			&:last-child {
				padding-left: 10px;
				padding-right: 0;
				text-align: center;
			}
		}
	}

	&.twitter i {
		color: $white;
		background: #00bcd4;
		border-radius: 8px 8px 0 0;
	}

	&.linkedin i {
		color: $white;
		border-radius: 8px 8px 0 0;
	}

	&.google-plus i {
		color: $white;
		background: #ff3b2e;
		border-radius: 8px 8px 0 0;
	}
}

.statistics-box {
	&.with-icon {
		position: relative;
		padding-left: 80px;
		text-align: right;
		.ico {
			&.small {
				font-size: 50px;
			}
			position: absolute;
			top: 8px;
			left: 0;
			width: 80px;
			height: 80px;
			font-size: 70px;
			line-height: 80px;
			text-align: center;
		}
	}
	padding-top: 14px;
	min-height: 80px;
	text-align: center;
	position: relative;
	padding-left: 80px;
	text-align: right;
}

.content-box h4 {
	font-size: 43px !important;
}

.counter {
	font-size: 35px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.counter-1 {
	font-size: 40px;
	font-weight: 600;
}

.todo-item {
	+.todo-item {
		margin-top: 8px;
	}

	.checkbox {
		margin-bottom: 6px;
	}

	input:checked+span {
		text-decoration: line-through;
	}
}

.iconfont {
	h2 {
		font-size: 45px;
		font-weight: 600;
		margin-bottom: 10px !important;
	}
	
	h5 {
		font-size: 16px;
		font-weight: 600;
	}
	
	i {
		font-size: 15px;
	}
}

.wx h1 {
	font-size: 65px !important;
	margin-bottom: 15px;
	margin-top: 0;
}

.self {
	justify-content: flex-end;
	align-items: flex-end;
	.msg {
		order: 1;
		border: 1px solid #e8ebf3;
		border-bottom-right-radius: 0;
		box-shadow: 0 0 0 1px rgba(243, 247, 253, 0.12), 0 8px 16px 0 rgba(243, 247, 253, 0.24);
	}

	.avatar {
		order: 2;
		&:after {
			content: "";
			position: relative;
			display: inline-block;
			bottom: -14px;
			right: 26px;
			width: 0;
			height: 0;
			border: 5px solid #e8ebf3;
			border-right-color: transparent;
			border-top-color: transparent;
			box-shadow: 0 0 0 1px rgba(243, 247, 253, 0.12), 0 8px 16px 0 rgba(243, 247, 253, 0.24);
		}
	}
}

.msg {
	background: $white;
	min-width: 50px;
	margin: 0 10px;
	padding: 10px;
	border-radius: 2px;
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.07);
	p {
		font-size: 13px;
		margin: 0 0 .2rem 0;
		color: #313148;
	}

	img {
		position: relative;
		display: block;
		width: 450px;
		border-radius: 5px;
		box-shadow: 0 0 3px #eee;
		transition: all 0.4s cubic-bezier(0.565, -0.26, 0.255, 1.41);
		cursor: default;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
	}

	time {
		font-size: .7rem;
		color: #a7b4c9;
		margin-top: 3px;
		float: right;
		cursor: default;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		&:before {
			content: "\f017";
			color: #a7b4c9;
			font-family: FontAwesome;
			display: inline-block;
			margin-right: 4px;
		}
	}
}

@media screen and (max-width: 800px) {
	.msg img {
		width: 300px;
	}
}

@media screen and (max-width: 550px) {
	.msg img {
		width: 200px;
	}
}

.social-title {
	text-transform: uppercase;
	letter-spacing: 1px;
	text-shadow: none;
	color: $white !important;
}

.socials {
	display: flex;
	li {
		margin: 0;
		margin-right: 0;
	}
}

.text-shadow1 {
	text-shadow: 0 10px 10px rgba(39, 29, 96, 0.4);
}

.count {
	font-size: 37px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.description-block {
	display: block;
	margin: 10px 0;
	text-align: center;
	> {
		.description-header {
			margin: 0;
			padding: 0;
			font-weight: 600;
			font-size: 16px;
		}

		.description-text {
			text-transform: uppercase;
			color: $color;
		}
	}
}

.socailicons {
	.fa {
		font-size: 70px;
		text-align: right;
		position: absolute;
		top: 13px;
		right: -5px;
		outline: 0;
	}

	color: $white;
	text-shadow: 1px 1px 2px $black-5;
	a {
		text-decoration: none;
	}
}


/*------ Custom Pages -------*/

.background {
	position: fixed;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	z-index: 0;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	opacity: .7;
	z-index: 1;
}

.masthead {
	position: relative;
	overflow: hidden;
	padding-bottom: 3rem;
	z-index: 2;
	.masthead-bg {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		min-height: 35rem;
		height: 100%;
	}

	.masthead-content {
		h1 {
			font-size: 2rem;
		}

		p {
			font-size: 1rem;
			strong {
				font-weight: 700;
			}
		}

		.input-group-newsletter {
			input {
				height: auto;
				font-size: 1rem;
				padding: 1rem;
			}

			button {
				font-size: .8rem;
				font-weight: 700;
				text-transform: uppercase;
				letter-spacing: 1px;
				padding: 1rem;
			}
		}
	}
}

@media (min-width: 1025px) {
	.masthead .masthead-content {
		padding-left: 7rem;
		padding-right: 7rem;
	}
}

@media (min-width: 992px) {
	.masthead {
		height: 100%;
		min-height: 0;
		width: 40%;
		padding-bottom: 0;
		float: right;
		.masthead-bg {
			min-height: 0;
		}
		.masthead-content {
			h1 {
				font-size: 2.5rem;
			}
			p {
				font-size: 1rem;
			}
		}
	}

	.search-background {
		.navbar-toggler {
			border-radius: 0;
		}
	}

	.sticky-wrapper.is-sticky {
		.horizontal-main {
			box-shadow: 2px 3px 4.7px 0.3px rgba(0, 0, 0, 0.24);
			height: 80px !important;
    			padding-top: 0 !important;
			.ad-post {
				margin-top: 0 !important;
			}
			&:after {
				content: '';
				position: absolute;
				height: 0px;
				border-top: 0;
				border-radius: 0px;
				z-index: 1;
				width: 100%;
				bottom: 0;
			}
		}
		.horizontalMenu>.horizontalMenu-list>li>a {
			color: $color;
			>.fa {
				color: $color;
			}
		}
		.desktoplogo-1 {
			padding: 1.25rem 0;
			margin: 0;
			float: left;
			display: block !important;
		}
		.horizontalMenu>.horizontalMenu-list>li {
			padding: 1.25rem 0;
		}
	}

	.desktoplogo-1 {
		display: none;
	}

	.sticky-wrapper.is-sticky {
		.desktoplogo {
			display: none;
		}
		.top-postbtn {
			margin-top: 1.55rem !important;
		}
	}
}

.ico-listing.table {
	td,
	th {
		padding: .75rem;
		vertical-align: middle;
	}
}

.sticky-wrapper {
	z-index: 100;
	position: absolute;
	left: 0;
	right: 0;
}

.notifyimg {
	color: $white;
	float: left;
	height: 40px;
	line-height: 40px;
	margin-right: 10px;
	text-align: center;
	vertical-align: middle;
	width: 40px;
	border-radius: 50%;
}

.app-selector ul {
	margin-bottom: 0;
}

.block {
	display: block !important;
}

.user-semi-title {
	font-size: 12px;
}

.floating {
	animation: floating 3s ease infinite;
	will-change: transform;
}


/*------ Gloabal-Loader ------*/

#global-loader {
	position: fixed;
	z-index: 50000;
	background: $white;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
}

.loader-img {
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	top: 45%;
	margin: 0 auto;
	background-image:url(../../assets/images/loader.svg);
	width: 100px;
    height: 100px;
	line-height:96px;
}

.loader-logo-image{
    text-align: center;
    margin: 0 auto;
    background: #fff;
    border-radius: 50%;
    border: 0px solid #e8ebf3;
    width: 45px;
    height: 45px;
    padding: 6px;
}

ul li ul:hover {
	visibility: visible;
	opacity: 1;
	display: block;
}

.user-head {
	.inbox-avatar {
		float: left;
		width: 65px;
		img {
			border-radius: 4px;
		}
	}
	.user-name {
		display: inline-block;
		margin: 0 0 0 10px;
		h5 {
			font-size: 14px;
			font-weight: 300;
			margin-bottom: 0;
			margin-top: 15px;
			a {
				color: $white;
			}
		}

		span a {
			color: #87e2e7;
			font-size: 12px;
		}
	}
}

#popup {
	position: absolute;
	width: auto;
	height: 30px;
	background: #fe6b1f;
	display: none;
	color: $white;
	border-radius: 5px;
}

#copy {
	background: 0 0;
	color: $white;
	font-weight: 700;
	padding: 8px 25px;
	border: 0;
}


/*------ Headers Heading  -----*/

.heading-inverse {
	color: #333;
	padding: 5px;
}

.heading-primary {
	color: $primary !important;
	padding: 5px;
}

.heading-success {
	color: $success !important;
	padding: 5px;
}

.heading-info {
	color: $info !important;
	padding: 5px;
}

.heading-warning {
	color: $yellow !important;
	padding: 5px;
}

.heading-danger {
	color: $danger !important;
	padding: 5px;
}

@media (max-width: 576px) {
	.header-text h1 {
		font-size: 2rem;
	}

	.classified-video h1 {
		font-size: 1.2rem;
	}

	.section-title h2 {
		font-size: 1.5rem;
	}

	.content-text .display-5 {
		font-size: 1.8rem;
	}
	
	#ui-datepicker-div .ui-datepicker-group {
		float: none;
		padding-right: 0;
	}

	.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-prev::before {
		left: -4px !important;
	}

	.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-next::before {
		right: -9px !important;
	}
}

@media print {
	*,
	 ::after,
	 ::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}

	a:not(.btn) {
		text-decoration: underline;
	}

	abbr[title]::after {
		content: " (" attr(title) ")";
	}

	pre {
		white-space: pre-wrap !important;
	}

	blockquote,
	pre {
		border: 1px solid #adb5bd;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group;
	}

	img,
	tr {
		page-break-inside: avoid;
	}

	h2,
	h3,
	p {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}

	body,
	.container {
		min-width: 992px !important;
	}

	.navbar {
		display: none;
	}

	.badge {
		border: 1px solid $black;
	}

	.table,
	.text-wrap table {
		border-collapse: collapse !important;
	}

	.table {
		td,
		th {
			background-color: $white !important;
		}
	}

	.text-wrap table {
		td,
		th {
			background-color: $white !important;
		}
	}

	.table-bordered {
		td,
		th {
			border: 1px solid #e8ebf3 !important;
		}
	}

	.text-wrap table {
		td,
		th {
			border: 1px solid #e8ebf3 !important;
		}
	}
}


/*---- Blockquote -----*/

blockquote {
	color: $color;
	padding-left: 2rem;
	border-left : 3px solid rgba(227, 237, 252, 0.5);
	margin: 0 0 1rem;
}

.blockquote-reverse {
	padding-right: 15px;
	padding-left: 0;
	text-align: right;
	border-right : 3px solid rgba(227, 237, 252, 0.5);
	border-left : 0;
}

blockquote {
	&.float-end {
		padding-right: 15px;
		padding-left: 0;
		text-align: right;
		border-right : 3px solid rgba(227, 237, 252, 0.5);
		border-left : 0;
	}
	p {
		margin-bottom: 1rem;
	}
	cite {
		display: block;
		text-align: right;
		&:before {
			content: '— ';
		}
	}
}

pre code {
	padding: 0;
	border-radius: 0;
	border: none;
	background: 0 0;
	font-size: inherit;
	color: inherit;
	word-break: normal;
}

img {
	max-width: 100%;
}

.section-nav {
	background-color: #f8f9fa;
	margin: 1rem 0;
	padding: .5rem 1rem;
	border: 1px solid #e8ebf3;
	border-radius: 3px;
	list-style: none;
	&:before {
		content: 'Table of contents:';
		display: block;
		font-weight: 600;
	}
}

@media print {
	.container {
		max-width: none;
	}
}

html {
	font-size: 16px;
	height: 100%;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: none;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	-webkit-font-feature-settings: "liga" 0;
	font-feature-settings: "liga" 0;
	overflow-y: scroll;
	position: relative;
}

.page {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	min-height: 100vh;
}

body.fixed-header .page {
	padding-top: 4.5rem;
}

@media (min-width: 1600px) {
	body.aside-opened .page {
		margin-right: 22rem;
	}
}

.page-main {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}

.page-content {
	margin: .75rem 0;
}

@media (min-width: 768px) {
	.page-content {
		margin: 1.5rem 0 !important;
	}
}

@media (max-width: 768px) {
	.item-det {
		ul.d-flex,
		.d-flex {
			display: block !important;
		}

		.rating-stars.d-flex {
			display: flex !important;
		}
	}
}


/*----- Page Header -----*/

.page-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 0;
	position: relative;
	min-height: 50px;
	background: $white;
}

.users-list .page-header {
	background: 0 0;
	padding: 0;
	border: 0;
}

.bannerimg .breadcrumb {
	display: inline-flex;
}

.page-title {
	margin: 0;
	font-size: 18px;
	font-weight: 500;
	line-height: 2.5rem;
}

.page-title-icon {
	color: #a7b4c9;
	font-size: 1.25rem;
}

.page-subtitle {
	font-size: .8125rem;
	color: #5f6877;
	position: relative;
	top: 19px;
	right: 59rem;
	a {
		color: inherit;
	}
}

.page-options {
	margin-left: auto;
}

.page-description {
	margin: .25rem 0 0;
	color: #5f6877;
	a {
		color: inherit;
	}
}

.page-single {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.content-heading {
	font-weight: 400;
	margin: 2rem 0 1.5rem;
	font-size: 1.25rem;
	line-height: 1.25;
	&:first-child {
		margin-top: 0;
	}
}

.aside {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	width: 22rem;
	background: $white;
	border-left : 1px solid #e8ebf3;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	z-index: 100;
	visibility: hidden;
	box-shadow: 0 5px 20px $black-1;
}

@media (min-width: 1600px) {
	body.aside-opened .aside {
		visibility: visible;
	}
}

.aside-body {
	padding: 1.5rem;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	overflow: auto;
}

.aside-footer {
	padding: 1rem 1.5rem;
	border-top: 1px solid #e8ebf3;
}

.aside-header {
	padding: 1rem 1.5rem;
	border-bottom: 1px solid #e8ebf3;
}

a.icon {
	text-decoration: none;
	cursor: pointer;
	&:hover {
		color: #1a1a1a !important;
	}
}

.o-auto {
	overflow: auto !important;
}

.o-hidden {
	overflow: hidden !important;
}

.user-img {
	margin-top: 2px;
}


/*------- Responsive -------*/

@media (max-width: 480px) {
	.list-media .info {
		.text-end {
			display: none;
		}
		padding-bottom: 15px;
	}
	.tabs-menu ul li {
		width: 100%;
		margin: 5px 15px;
		a {
			width: 100%;
		}
	}
	.cal1 .clndr .clndr-table tr {
		height: 50px !important;
	}
	.tabs-menu1 ul li {
		width: 100%;
		margin: 2px;
		a {
			width: 100%;
		}
	}
	.wizard-card .nav-pills>li {
		text-align: center;
		padding: 9px !important;
	}
	.form-control.form-select.w-auto {
		display: none;
	}
	.mail-inbox .badge {
		margin: 0;
	}
	.construction .display-5 {
		font-size: 1.5rem;
	}
	.jumbotron .display-3 {
		font-size: 2.5rem;
	}
	.mail-option .hidden-phone {
		display: none;
	}
}

@media (max-width: 1080px) {
	.avatar {
		max-width: inherit;
	}
}

@media (max-width: 480px) {
	.app-header .header-brand {
		min-width: 2.8rem;
	}
	.app-header1 .header-brand {
		min-width: auto;
	}
	.menu-toggle-button {
		margin-top: 4px;
	}
}

@media (max-width: 767px) {
	.avatar {
		max-width: inherit;
	}
	.card-tabs {
		display: block !important;
	}
	.header-brand {
		line-height: 2.7rem;
	}
	.header .input-icon.mt-2 {
		margin-top: 5px !important;
	}
	.footer .privacy {
		text-align: center !important;
	}
	.shop-dec .col-md-6 {
		&.pr-0 {
			padding-right: 0.75rem !important;
		}
		&.pl-0 {
			padding-left: 0.75rem !important;
		}
	}
}

@media (max-width: 375px) {
	.nav-link span,
	.searching1 i {
		margin-top: .5rem;
	}
	.construction h3 {
		font-size: 2.8rem !important;
	}
}

@media (max-width: 576px) {
	.nav-link span,
	.searching1 i {
		margin-top: .5rem;
	}
}

@media (max-width: 576px) {
	.nav-link span {
		margin-top: .6rem;
	}
	.searching1 i {
		margin-top: .5rem;
		top: 14px !important;
	}
}

@media (max-width: 768px) {
	.nav-tabs .nav-link {
		width: 100%;
	}
	.page-subtitle {
		display: none;
	}
	.richText .richText-toolbar ul li a {
		border: rgba(0, 40, 100, 0.12) solid 1px;
	}
	.ticket-card {
		.col-md-1 {
			width: 100%;
			display: block;
		}
		img {
			display: block;
			text-align: Center;
			margin: 0 auto;
		}
	}
	.dataTables_wrapper {
		.dataTables_info,
		.dataTables_paginate {
			margin-top: 10px;
		}
	}
	.page-title {
		line-height: 1.5rem;
	}
	.carousel-caption {
		display: none;
	}
	.demo-gallery>ul>li {
		width: 100% !important;
	}
	ul.inbox-pagination li span {
		display: none;
	}
}

@media (max-width: 991px) and (min-width: 767px) {
	.carousel-caption {
		top: 10%;
	}
}

@media (max-width: 568px) and (min-width: 480px) {
	.container-messages {
		position: absolute;
		top: 50px !important;
	}
	.messages-list {
		top: 24px !important;
		bottom: 73px !important;
	}
	.messages-right {
		top: 10px !important;
		bottom: 71px !important;
	}
	.messages-left-footer {
		bottom: 40px !important;
		display: none;
	}
	.messages-left .card-header {
		display: none;
	}
	.message-footer,
	.message-header {
		height: 50px !important;
	}
	.message-body {
		top: 50px !important;
		bottom: 50px !important;
	}
}

@media (max-width: 991px) and (min-width: 569px) {
	.message-header,
	.message-footer {
		height: 50px !important;
	}
	.messages-right {
		top: 10px !important;
		bottom: 80px !important;
	}
	.message-body {
		top: 50px !important;
		bottom: 50px !important;
	}
	.messages-left .card-header {
		display: none;
	}
	.messages-list {
		top: 24px !important;
	}
}

@media (max-width: 990px) {
	.header-toggler {
		margin: 6px;
	}
	.nav-tabs .nav-link {
		width: 100%;
	}
	form.convFormDynamic button.submit {
		margin: 4px !important;
	}
	.messages-list .media {
		padding: 9px !important;
	}
	.nav-tabs {
		z-index: 1000;
	}
}

.page-breadcrumb {
	background: 0 0;
	padding: 0;
	margin: 1rem 0 0;
	font-size: .875rem;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	.breadcrumb-item {
		color: #a7b4c9;
		&.active {
			color: #5f6877;
		}
	}
}

@media (min-width: 768px) {
	.page-breadcrumb {
		margin: -.5rem 0 0;
	}
}

@media (max-width: 320px) {
	.fc-toolbar .fc-right {
		float: right;
		width: 100%;
		text-align: center;
		margin: 10px 0;
	}
	.construction h3 {
		font-size: 2.3rem !important;
	}
}

.page-total-text {
	margin-right: 1rem;
	-ms-flex-item-align: center;
	align-self: center;
	color: #5f6877;
}

.tilebox-one i {
	font-size: 62px;
}

.reg {
	text-align: center;
	font-size: 50px;
	color: #2e1170;
	float: right;
}

.profie-img .flex-md-row img {
	width: 60px;
}

ul li .legend-dots {
	width: 1rem;
	height: 1rem;
	border-radius: 100%;
	display: inline-block;
	vertical-align: text-bottom;
	margin-right: .5rem;
}

.legend li {
	padding: 5px;
	display: -webkit-inline-box;
}

.stretch-card {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: stretch;
	align-items: stretch;
	-webkit-justify-content: stretch;
	justify-content: stretch;
	>.card {
		width: 100%;
		min-width: 100%;
	}
}

.icon-size {
	font-size: 2.5rem !important;
}

.statistics-box {
	.ico {
		&.small {
			font-weight: 900;
			font-size: 42px;
		}
		position: absolute;
		top: 0;
		left: 0;
		width: 80px;
		height: 80px;
		font-size: 70px;
		line-height: 80px;
		text-align: center;
	}
	h2 {
		font-weight: 600 !important;
	}
}

.app-selector {
	box-shadow: 0 1px 2px 0 $black-05;
	min-width: 16rem;
}

.visitor-list {
	p {
		font-size: 12px;
	}
	i {
		font-size: 20px;
	}
}

.sales-relative {
	position: relative;
}

.img-gallery {
	margin-right: -.25rem;
	margin-left: -.25rem;
	margin-bottom: -.5rem;
	> {
		.col,
		[class*=col-] {
			padding-left: .25rem;
			padding-right: .25rem;
			padding-bottom: .5rem;
		}
	}
}

.link-overlay {
	position: relative;
	&:hover .link-overlay-bg {
		opacity: 1;
	}
}

.link-overlay-bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: $black-5;
	display: -ms-flexbox;
	display: flex;
	color: $white;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 1.25rem;
	opacity: 0;
	transition: .3s opacity;
}

textarea[cols] {
	height: auto;
}

.status-icon {
	content: '';
	width: .5rem;
	height: .5rem;
	display: inline-block;
	background: currentColor;
	border-radius: 50%;
	-webkit-transform: translateY(-1px);
	transform: translateY(-1px);
	margin-right: .375rem;
	vertical-align: middle;
}

.status-animated {
	-webkit-animation: 1s status-pulse infinite ease;
	animation: 1s status-pulse infinite ease;
}

.imagecheck {
	margin: 0;
	position: relative;
	cursor: pointer;
}

.imagecheck-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.imagecheck-figure {
	border: 1px solid #e8ebf3;
	border-radius: 3px;
	margin: 0;
	position: relative;
	height: 100%;
}

.imagecheck-input:checked~.imagecheck-figure {
	border-color: rgba(0, 40, 100, 0.24);
}

.imagecheck-figure:before {
	content: '';
	position: absolute;
	top: .25rem;
	left: .25rem;
	display: block;
	width: 1rem;
	height: 1rem;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	z-index: 1;
	border-radius: 5px;
	opacity: 0;
	transition: .3s opacity;
}

.imagecheck-input:checked~.imagecheck-figure:before {
	opacity: 1;
}

.imagecheck-image {
	max-width: 100%;
	opacity: .64;
	transition: .3s opacity;
	margin-top:-22px;
	&:first-child {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
	&:last-child {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
}

.imagecheck-input {
	&:checked~.imagecheck-figure .imagecheck-image,
	&:focus~.imagecheck-figure .imagecheck-image {
		opacity: 1;
	}
}

.imagecheck:hover .imagecheck-image {
	opacity: 1;
}

.imagecheck-caption {
	text-align: center;
	padding: .25rem .25rem;
	color: #a7b4c9;
	font-size: .875rem;
	transition: .3s color;
}

.imagecheck-input {
	&:checked~.imagecheck-figure .imagecheck-caption,
	&:focus~.imagecheck-figure .imagecheck-caption {
		color: #3d4e67;
	}
}

.imagecheck:hover .imagecheck-caption {
	color: #3d4e67;
}

.colorinput {
	margin: 0;
	position: relative;
	cursor: pointer;
}

.colorinput-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.colorinput-color {
	display: inline-block;
	width: 1.75rem;
	height: 1.75rem;
	border-radius:5px;
	border: 1px solid #e8ebf3;
	color: $white;
	box-shadow: 0 1px 2px 0 $black-05;
	&:before {
		content: '';
		opacity: 0;
		position: absolute;
		top: .25rem;
		left: .25rem;
		height: 1.25rem;
		width: 1.25rem;
		transition: .3s opacity;
		background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center (center / 50%) 50%;
	}
}

.colorinput-input:checked~.colorinput-color:before {
	opacity: 1;
}


/*------ Backtop ------*/

#back-to-top {
	color: $white;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 99;
	display: none;
	text-align: center;
	border-radius: 8px;
	-moz-border-radius: 8px;
	-webkit-border-radius: 8px;
	-o-border-radius: 8px;
	z-index: 999;
	height: 50px;
	width: 50px;
	line-height:56px;
	background-repeat: no-repeat;
	background-position: center;
	transition: background-color .1s linear;
	-moz-transition: background-color .1s linear;
	-webkit-transition: background-color .1s linear;
	-o-transition: background-color .1s linear;
	i {
		padding-top: 0px;
		font-size: 20px;
	}
}

.features {
	overflow: hidden;
	h2 {
		font-weight: 600;
		margin-bottom: 12px;
		text-align: center;
		font-size: 2.2em;
	}
	h3 {
		font-size: 20px;
		font-weight: 500;
	}
	span {
		color: #43414e;
		display: block;
		font-weight: 400;
		text-align: center;
	}
}

.feature {
	.feature-svg {
		width: 25%;
	}
	.feature-svg3 {
		width: 12%;
	}
}

.col-sm-2 .feature {
	padding: 0;
	border: 0;
	box-shadow: none;
}

.feature {
	.fea-icon {
		position: relative;
		display: inline-block;
		width: 4.5rem;
		height: 4.5rem;
		vertical-align: middle;
		line-height: 4.9rem;
		border-radius: 50%;
		color: $white;
		i {
			font-size: 1.5rem;
		}
	}
	p {
		margin-bottom: 0;
	}
}

.box-shadow-0 {
	box-shadow: none !important;
}

#messages-main {
	position: relative;
	margin: 0 auto;
	&:after,
	&:before {
		content: " ";
		display: table;
	}
	.ms-menu {
		position: absolute;
		left: 0;
		top: 0;
		border-right : 1px solid #e8ebf3;
		padding-bottom: 50px;
		height: 100%;
		width: 240px;
		background: $white;
		&.toggled {
			display: block;
		}
	}
	.ms-body {
		overflow: hidden;
	}
	.ms-user>div {
		overflow: hidden;
		padding: 3px 5px 0 15px;
		font-size: 11px;
	}
	#ms-compose {
		position: fixed;
		bottom: 120px;
		z-index: 1;
		right: 30px;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
	}
}

#ms-menu-trigger {
	i {
		font-size: 21px;
	}
	&.toggled i:before {
		content: '\f2ea';
	}
}

.message-feed {
	padding: 10px;
	&.right {
		>.pull-right {
			margin-left: 15px;
		}
		.mf-content {
			background: $background;
		}
	}
}

.mf-content {
	padding: 9px;
	border-radius: 2px;
	display: inline-block;
	max-width: 80%;
	position: relative;
}

.message-feed {
	&:not(.right) .mf-content:before {
		content: '';
		display: block;
		position: absolute;
		border: 10px solid transparent;
		left: -20px;
		top: 11px;
	}
	&.right .mf-content:before {
		content: '';
		display: block;
		position: absolute;
		border: 10px solid transparent;
		border-left-color: $background;
		right: -20px;
		top: 11px;
	}
}

.mf-date {
	display: block;
	margin-top: 7px;
	>i {
		font-size: 14px;
		line-height: 100%;
		position: relative;
		top: 1px;
	}
}

.msb-reply {
	position: relative;
	margin-top: 3px;
	background: $white;
	padding: 10px;
	border-radius: 0 7px 7px 7px;
}

.four-zero,
.lc-block {
	box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
}

.msb-reply {
	textarea {
		width: 100%;
		font-size: 13px;
		border: 0;
		padding: 10px 15px;
		resize: none;
		background: 0 0;
	}
	button {
		top: 0;
		right: 0;
		border: 0;
		height: 100%;
		width: 60px;
		font-size: 20px;
		color: $white;
		border-radius: 0 3px 3px 0;
		line-height: 36px;
	}
}

.img-avatar {
	height: 37px;
	border-radius: 2px;
	width: 37px;
}

.p-15 {
	padding: 15px !important;
}

.action-header {
	position: relative;
	background: #f8f8f8;
	padding: 15px 13px 15px 17px;
}

.ah-actions {
	z-index: 3;
	float: right;
	margin-top: 7px;
	position: relative;
}

.actions {
	list-style: none;
	padding: 0;
	margin: 0;
	>li {
		display: inline-block;
	}
	&:not(.a-alt)>li>a>i {
		color: #939393;
	}
	>li>a {
		>i {
			font-size: 20px;
		}
		display: block;
		padding: 0 10px;
	}
}

.ms-body {
	background: $white;
	border-radius: 7px;
}

#ms-menu-trigger {
	user-select: none;
	position: absolute;
	left: 0;
	top: 0;
	width: 50px;
	height: 100%;
	padding-right: 10px;
	padding-top: 19px;
	cursor: pointer;
	text-align: right;
}

.message-feed.right {
	text-align: right;
}

#ms-menu-trigger,
.toggle-switch {
	-webkit-user-select: none;
	user-select: none;
	-moz-user-select: none;
}

.message-feed {
	&.media .media-body,
	&.right .media-body {
		overflow: visible;
	}
}

.job-box-filter {
	label {
		width: 100%;
	}
	select.input-sm {
		display: inline-block;
		max-width: 120px;
		margin: 0 5px;
		border: 1px solid #e8ebf3;
		border-radius: 2px;
		height: 34px;
		font-size: 15px;
	}
	label input.form-control {
		max-width: 200px;
		display: inline-block;
		border: 1px solid #e8ebf3;
		border-radius: 2px;
		margin-left: 5px;
		font-size: 15px;
	}
	padding: 12px 15px;
	border-bottom: 1px solid #e8ebf3;
}

.job-box {
	display: inline-block;
	width: 100%;
	padding: 0;
}

.job-box-filter {
	a.filtsec {
		margin-top: 8px;
		display: inline-block;
		margin-right: 15px;
		padding: 4px 10px;
		font-family: 'Roboto', sans-serif;
		transition: all ease .4s;
		background: #edf0f3;
		border-radius: 50px;
		font-size: 13px;
		color: #81a0b1;
		border: 1px solid #e2e8ef;
		&.active {
			color: $white;
			background: #16262c;
			border-color: #16262c;
		}
		i {
			color: #03a9f4;
			margin-right: 5px;
		}
		&:focus,
		&:hover {
			color: $white;
			background: #07b107;
			border-color: #07b107;
		}
		&:focus i,
		&:hover i {
			color: $white;
		}
	}
	h4 i {
		margin-right: 10px;
	}
}

@media (max-width: 600px) {
	.job-box-filter label {
		width: 100%;
		text-align: center;
	}
	.message-body h5 span {
		&.pending,
		&.unread {
			display: none;
		}
	}
}

#index-video {
	.index-video-container {
		position: relative;
		overflow: hidden;
		&:after {
			bottom: 0;
			content: "";
			left: 0;
			opacity: .7;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;
		}
		.videocaption {
			left: 1%;
			position: absolute;
			right: 1%;
			text-align: center;
			top: 30%;
			z-index: 2;
			h2 {
				color: $white;
				font-size: 4.5em;
				font-weight: 900;
				-webkit-box-sizing: content-box;
				-moz-box-sizing: content-box;
				box-sizing: content-box;
				border: none;
				color: rgba(255, 255, 255, 1);
				text-align: center;
				-o-text-overflow: clip;
				text-overflow: clip;
				text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.0980392), 0 0 5px rgba(0, 0, 0, 0.0980392), 0 1px 3px rgba(0, 0, 0, 0.298039), 0 3px 5px $black-2, 0 5px 10px rgba(0, 0, 0, 0.247059), 0 10px 10px $black-2, 0 20px 20px rgba(0, 0, 0, 0.14902);
				-webkit-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
				-moz-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
				-o-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
				transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
			}
			h4 {
				color: $white;
				font-size: 2em;
				margin-top: 25px;
			}
			.btn-slide {
				background: #f62459;
				color: $white;
				font-size: 16px;
				font-weight: 300;
				margin: 25px auto 10px auto;
				padding: 20px 50px;
				border-radius: 0;
				text-transform: uppercase;
				&:hover {
					background: $white;
					color: #333;
				}
			}
		}
	}
	video {
		width: 100%;
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0;
	}
}

@media (min-width: 569px) and (max-width: 991px) {
	#index-video .index-video-container .videocaption {
		top: 33%;
	}
	.item-card-img h3.text-white {
		font-size: 1rem;
	}
	.item-card2 h4.bg-light.p-3 {
		font-size: .8rem;
	}
}

@media (max-width: 448px) {
	#index-video .index-video-container .videocaption {
		top: 12%;
	}
}


/*------ Video -----*/

.video-list-thumbs {
	>li {
		margin-bottom: 12px;
		>a {
			display: block;
			position: relative;
			color: #6b6f80;
			padding: 8px;
			border-radius: 3px;
			transition: all .5s ease-in-out;
			border-radius: 4px;
		}
	}
	h2 {
		bottom: 0;
		font-size: 14px;
		height: 33px;
		margin: 8px 0 0;
	}
	span {
		font-size: 50px;
		opacity: .8;
		position: absolute;
		right: 0;
		left: 0;
		top: 42%;
		margin: 0 auto;
		text-align: center;
		transition: all .5s ease-in-out;
	}
	>li>a:hover .fa {
		color: $white;
		opacity: 1;
		text-shadow: 0 1px 3px $black-8;
	}
	.duration {
		background-color: rgb(0, 0, 0);
		border-radius: 0;
		color: $white;
		font-size: 13px;
		font-weight: 700;
		left: 12px;
		line-height: 9px;
		padding: 5px 8px;
		position: absolute;
		bottom: 12px;
		transition: all .5s ease;
	}
	>li>a:hover .duration {
		background-color: $black;
	}
}

@media (max-width: 768px) {
	.video-list-thumbs span {
		top: 29%;
	}
}

@media (min-width: 320px) and (max-width: 480px) {
	.video-list-thumbs {
		.fa {
			font-size: 35px;
		}
		h2 {
			bottom: 0;
			font-size: 12px;
			height: 22px;
			margin: 8px 0 0;
		}
	}
}

@media (max-width: 991.98px) {
	.search-background {
		background: 0 0;
		border-radius: 0;
		.form-group {
			margin-bottom: 10px !important;
			border-radius: 8px;
		}
	}

	.top-bar .top-bar-start .socials li:last-child a {
		border-right: 1px solid #eff2f7;
	}

	.header-main-color .top-bar .top-bar-start ul li a {
		border-right: 0 !important;
	}

	.filter-product-info .table td {
		display: -webkit-box;
		padding: 3px !important;
	}

	.product-singleinfo .border-start {
		border-left : 0 !important;
	}

	.support .border-end {
		border-right : 0 !important;
	}

	.wideget-user-info .wideget-user-warap .wideget-user-warap-r {
		margin-left: 5rem !important;
	}

	.wideget-user-desc .user-wrap {
		margin-top: 0 !important;
		margin-left: 10px !important;
	}

	.product-singleinfo .product-item2-align dt {
		width: 0 !important;
		float: none !important;
		display: -webkit-box !important;
	}

	dd {
		display: -webkit-box !important;
	}

	.product-singleinfo .product-item2-align dd {
		margin-left: 0 !important;
	}

	.item-single .item-single-desc .item-single-desc1 {
		display: block !important;
	}

	.product-5-desc a {
		float: right;
	}

	.get-contact a:last-child {
		margin-right: 0;
	}

	.header-search .header-nav .nav-cart .icon-cart span {
		display: none;
	}

	.header-nav .header-search-logo .header-logo .header-brand-img {
		height: auto;
		line-height: 2rem;
		vertical-align: bottom;
		margin-right: .5rem;
		width: auto;
	}

	.header-search .header-search-logo {
		text-align: center;
	}

	.wideget-user .wideget-user-desc .user-wrap a.btn {
		margin-bottom: 5px;
	}
	
	.product-single {
		.product-thumbimg,
		.product-info a {
			margin-bottom: 10px;
		}
	}

	.item-single .item-single-desc .input-group {
		margin-bottom: 10px;
	}

	.card-blogs .card-item-desc.p-0 {
		margin-left: 15px;
	}

	.license-card-price.text-center {
		text-align: left !important;
	}

	.product-details td a.btn {
		margin-bottom: 5px;
	}

	.wideget-user-info .wideget-user-warap {
		margin-top: 15px;
	}

	.product-singleinfo .product-item2-desc {
		margin-bottom: 10px;
	}

	.header-search .header-inputs {
		.input-group-text,
		.input-group>.form-control {
			width: 100%;
			margin-bottom: 10px;
		}
		.input-group-text {
			width: 100%;
			margin-bottom: 15px;
			>.btn {
				width: 100%;
				display: block;
			}
		}
	}

	.header-main {
		.get-contact {
			a {
				border-left : 0 !important;
				padding-left: 0 !important;
			}
			margin-bottom: 15px;
		}
		.social-icons {
			text-align: center;
			float: none !important;
			li:last-child {
				margin-right: 0;
			}
		}
	}

	.header-search .header-icons {
		float: none !important;
		text-align: center !important;
	}

	.top-bar {
		text-align: center;
		.top-bar-start .contact {
			border-left : 0 !important;
			margin-left: 0 !important;
			padding-left: 0 !important;
			li {
				margin: 0 !important;
			}
		}
	}

	.item-search-menu ul li {
		text-align: center;
		border-radius: 3px;
		a {
			border-radius: 3px !important;
		}
	}

	.item-search-tabs {
		.form-group {
			&:last-child {
				margin-bottom: 0 !important;
			}
			margin-bottom: 10px !important;
		}
		.bg-white {
			background: 0 0 !important;
		}
		.tab-content {
			background: 0 0 !important;
			border: 0 !important;
		}
	}

	.item1-card {
		.item1-card-btn,
		.item1-card-info {
			margin-top: 15px;
		}
	}

	.items-blog-tab-heading .items-blog-tab-menu li {
		text-align: center;
		a {
			display: block;
		}
	}

	.item2-gl .item2-gl-nav select,
	.item2-gl-nav h6 {
		text-align: center;
	}

	.item-card .item-card-img img,
	.item-card2-img img,
	.item-card9-img img,
	.product-info .product-info-img img,
	.item-card4-img img,
	.item-card5-img img,
	.item-card7-img img,
	.item-card8-img img,
	.overview .overview-img img,
	.video-list-thumbs img {
		width: 100%;
	}

	.search1 {
		background: 0 0 !important;
		.form-group {
			margin-bottom: 15px !important;
		}
	}

	.search2 a.btn {
		margin-top: 15px;
	}

	.search3 {
		background: 0 0 !important;
		.form-group {
			margin-bottom: 15px !important;
		}
	}

	.item-user-icons {
		margin-left: 10px;
	}

	.item1-card-tab .item1-tabs-menu ul li {
		width: 100%;
		text-align: center;
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0;
		}
	}

	.user-wideget h6 {
		margin-top: 5px;
	}

	.header-main .top-bar {
		.contact,
		&.p-3 .contact {
			display: none;
		}
	}

	.bannerimg .header-text h1 {
		display: block;
		margin-bottom: .5rem;
		font-size: 2rem;
	}

	.all-categories .row .card-body {
		border-bottom: 0 !important;
	}

	.ace-responsive-menu {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.item-card9-desc a,
	.item-card2-footer a,
	.item7-card-desc a {
		font-size: 12px;
	}

	.map-header1 {
		margin-bottom: 1.5rem !important;
	}

	.app-btn a {
		width: 100%;
		margin-bottom: 1rem;
		&:last-child {
			margin-bottom: 0;
		}
	}

	.sub-newsletter {
		margin-bottom: 1rem;
		text-align: center;
		display: block !important;
	}

	.fea-ad .card,
	.cat-slide .card {
		margin-bottom: 1.5rem !important;
	}

	.advertisment-block img {
		width: 100%;
	}

	.usertab-list li {
		width: 100% !important;
	}

	.userprof-tab {
		.media {
			display: block !important;
		}
		.media-body {
			display: contents;
			width: 100%;
			.card-item-desc {
				margin-left: 0 !important;
			}
		}
		.btn {
			margin-bottom: .3rem;
		}
	}

	.page-header .page-select {
		width: 35% !important;
	}

	.customerpage .card {
		margin-bottom: 1.5rem !important;
	}

	.ads-tabs .tabs-menus ul {
		li {
			a {
				padding: 8px 10px 8px 10px !important;
				text-align: center;
			}
			display: -webkit-box;
			text-align: center;
			margin-bottom: 1.5rem;
			&:last-child {
				margin-bottom: 0;
			}
		}
		text-align: center;
	}
}

@media (max-width: 568px) {
	.items-blog-tab-heading .items-blog-tab-menu li {
		width: 100%;
		text-align: center;
		margin-bottom: 10px;
	}

	.header-main .top-bar-end .custom {
		text-align: right;
		float: right;
		margin-bottom: 0 !important;
	}
	
	.settings-tab .tabs-menu li {
		width: 100% !important;
		margin-bottom:10px;
	}

	.top-bar .top-bar-end {
		text-align: center;
		display: block !important;
		margin: 0 auto;
	}

	.header-main .top-bar-end .custom li {
		margin-bottom: 0;
	}

	.item2-gl-nav {
		display: block !important;
		.item2-gl-menu li {
			text-align: center;
			margin-bottom: 10px;
			margin: 0 auto;
			padding: 10px 0;
		}
		label {
			display: block;
			float: none;
			text-align: center;
		}
	}
	.header-main .top-bar-end .custom li a span {
		display: none;
	}
	.top-bar .top-bar-start .socials li {
		float: left !important;
		margin: 0px !important;
	}
	.item2-gl .item2-gl-nav select {
		width: 100% !important;
	}
}

@media (min-width: 569px) and (max-width: 991px) {
	.top-bar .top-bar-start .socials li {
		margin: 0 !important;
	}
}

@media (min-width: 481px) and (max-width: 991px) {
	.owl-product .owl-productimg {
		width: 100% !important;
		height: 100% !important;
		margin: 0 auto;
	}
	.art-wideget .art-details {
		margin-top: 10px;
	}
	.header-search .header-icons .header-icons-link1 .main-badge1 {
		top: -11px !important;
	}
	.user-wideget .widget-image {
		top: 50% !important;
	}
	.banner1 .header-text {
		h2 span,
		h3 span {
			padding: 0 !important;
		}
		top: 20% !important;
	}
	.item-card:hover .item-card-btn {
		left: 42%;
	}
	.product-img {
		margin: 0 auto;
		text-align: center;
	}
	.banner-2 {
		.header-text {
			top: 0.5rem !important;
		}
		img {
			height: 33rem;
		}
	}
	.item-search-tabs {
		margin-top: 1rem !important;
	}
	.userprof-tab {
		.media {
			display: block !important;
		}
		.media-body {
			display: contents;
			width: 100%;
			.card-item-desc {
				margin-left: 0 !important;
			}
		}
		.btn {
			margin-bottom: .3rem;
		}
	}
	.wideget-user-tab {
		.tab-menu-heading .nav li a {
			font-size: 14px !important;
		}
	}
	.header-search .header-search-logo {
		margin-top: 5px;
	}
	.header-main .post-btn {
		margin-top: 3px;
	}
}
@media (max-width:767px){
	.wideget-user-tab.wideget-user-tab3 .tabs-menu1 ul li a{
		border-radius: 7px !important;
		margin: 0px 0 10px 0 !important;
	}

	.header-main-color .top-bar .top-bar-start ul {
		border-right: 1px solid $white-1;
	}


}
@media (min-width: 992px) and (max-width: 1024px) {
	.item-card .item-card-img img,
	.item-card2-img img,
	.item-card9-img img,
	.product-info .product-info-img img,
	.item-card4-img img,
	.item-card5-img img,
	.item-card7-img img,
	.item-card8-img img,
	.overview .overview-img img,
	.video-list-thumbs img {
		width: 100%;
	}
	.header-main .get-contact {
		a {
			border-left : 0 !important;
			padding-left: 0 !important;
		}
		margin-bottom: 15px;
	}
	.header-search .header-inputs {
		margin-bottom: 15px;
	}
	.header-main .social-icons {
		text-align: center;
		float: none !important;
		li:last-child {
			margin-right: 0;
		}
	}
	.header-search .header-icons {
		.header-icons-link {
			float: right;
		}
		float: none !important;
		text-align: center !important;
	}
	.top-bar {
		text-align: center;
		.top-bar-start {
			text-align: center;
			.contact {
				margin-left: 0 !important;
				padding-left: 0 !important;
				margin: 0 !important;
			}
			.socials li {
				margin: 0 !important;
			}
		}
		.top-bar-end {
			text-align: center;
			display: block !important;
			margin: 0 auto;
		}
	}
	.product-multiinfo .product-ship p a:first-child,
	.product-singleinfo .product-ship p a:first-child {
		margin-bottom: 5px;
	}
	.banner-2 img {
		height: 27rem;
	}
	.items-blog-tab-heading .items-blog-tab-menu li {
		text-align: center;
		margin-bottom: 10px;
	}
	.ace-responsive-menu {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.header-main .top-bar-end .custom {
		display: -webkit-inline-box !important;
		margin-bottom: 0rem !important;
		margin-top: 0rem !important;
	}
	.icon-card li {
		font-size: 10px;
	}
	.support-service.bg-dark {
		margin-bottom: 10px;
	}
	.cat-slide .card,
	.fea-ad .card {
		margin-bottom: 1.5rem !important;
	}
	.advertisment-block img {
		width: 100%;
	}
	.header-main .post-btn {
		margin-top: 5px;
	}
	.customerpage .card {
		margin-bottom: 1.5rem !important;
	}
	.ads-tabs .tabs-menus ul li a {
		padding: 8px 10px 8px 10px !important;
	}
}

@media (max-width: 480px) {
	.item-all-card img.imag-service {
		width: 20% !important;
	}

	.header-main .top-bar .header-style10 .top-bar-start .socials li a, .header-main .top-bar .header-style10 .top-bar-end .custom li a {
		padding: 6px;
	}

	.section-title h1 {
		font-size: 2rem;
	}

	.item-all-cat .category-type .item-all-card img {
		width: 4rem !important;
		height: 4rem !important;
		padding: 1rem 0 !important;
	}

	.banner1 .header-text {
		h2 {
			span {
				padding: 0 !important;
			}
			font-size: 18px !important;
		}
		h3 {
			font-size: 14px !important;
			span {
				padding: 0 !important;
			}
		}
	}

	.card-blogs .card-item-desc.p-0 .card-item-wrap .footer-wrap-price {
		del {
			display: none;
		}
		span.h5 {
			font-size: 12px !important;
		}
	}

	.card-pay .tabs-menu li {
		width: 100% !important;
		margin:5px;
	}
	
	.header-search .header-icons .header-icons-link1 .main-badge1 {
		top: -19px !important;
	}

	.product-details .card-item-desc {
		display: none !important;
	}

	.art-wideget .art-details {
		margin-top: 10px;
	}

	.support .support-service {
		i {
			float: none !important;
			margin-right: 0 !important;
			margin-bottom: 20px;
		}
		text-align: center;
	}

	.banner-1.sptb-2 {
		padding-top: 0 !important;
		padding-bottom: 10rem !important;
	}

	.item-card7-desc ul li,
	.item-card2-desc ul li {
		font-size: 12px;
	}

	.banner-2 {
		.header-text {
			top: 0.5rem !important;
		}
		img {
			height: 33rem;
		}
	}

	.item-search-tabs {
		margin-top: 1rem !important;
	}

	.bannerimg .header-text {
		font-size: 1.5rem;
		.breadcrumb-item {
			font-size: 14px;
		}
	}

	.icons a {
		margin-bottom: .5rem;
	}

	.item-det ul li {
		font-size: 11px;
		margin-right: 0.5rem !important;
	}

	.userprof-tab {
		.media {
			display: block !important;
		}
		.media-body {
			display: contents;
			width: 100%;
			.card-item-desc {
				margin-left: 0 !important;
			}
		}
		.btn {
			margin-bottom: .3rem;
		}
	}
}

@media (max-width: 991px) {
	.horizontal-header .container {
		position: relative;
	}
}

@media (max-width: 375px) {

	.product-item2-desc .d-flex.product-item2-rating1 {
		display: block !important;
	}
}

@media (max-width: 1336px) {
	.filter-product-info .table td {
		display: -webkit-box;
		padding: 3px !important;
	}
}

.all-categories .row {
	.card-body {
		border-bottom: 1px solid #e8ebf3;
	}
	&:last-child .card-body {
		border-bottom: 0 !important;
	}
}

.clear,
.current,
.ok {
	background: #e9ecfb;
	border-radius: 20px;
}

.today {
	background: #e9ecfb;
}

.graph canvas {
	width: 100% !important;
}

.trend {
	i {
		font-size: 10px !important;
		vertical-align: middle;
	}
	.media-body {
		margin-left: 10px;
	}
}

.datebox {
	top: 0;
	left: 0;
	border-radius: 7px;
	background: $white;
	padding: 0 15px 0 15px;
}

.iconbage .badge {
	position: absolute;
	top: 14px;
	right: 13px;
	padding: .2rem .25rem;
	min-width: 1rem;
	font-size: 13px;
}

.single-product .product-desc .product-icons .socialicons a {
	width: 2em;
	height: 2em;
	line-height: 2em;
	border-radius: 50%;
	font-size: 1em;
	display: inline-block;
	vertical-align: middle;
	border: 1px solid #eef2f9;
	color: $color;
	margin: 0 auto;
	text-align: center;
}

.blogimg {
	width: 100px;
}

.subnews img {
	width: 25%;
	height: 25%;
}

.task-img img,
.status-img img {
	width: 70px;
	height: 70px;
	margin: 0 auto;
}

.product-hover {
	position: relative;
	.product-info-buttons {
		position: absolute;
		display: none;
		text-align: center;
		top: 45%;
		margin: 0 auto;
		left: 0;
		width: 100%;
		height: 100%;
		right: 0;
	}
	&:hover {
		&.product-hover:before {
			content: "";
			background: $black-5;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 100;
		}
		.product-info-buttons {
			display: block;
			z-index: 1000;
		}
	}
}

.spacing {
	padding: 1.5rem;
}

.search-background {
	background: $white;
	border-radius: 8px;
	overflow: hidden;
	.navbar-toggler.navresponsive-toggler {
		font-size: 14px;
		line-height: 2.4;
	}
}

.search1 {
	background: $white;
	border-radius: 2px;
	overflow: hidden;
}

.search2 {
	background: $white;
	padding: 30px;
	border-radius: 2px;
	overflow: hidden;
}

.search3 {
	background: $white;
	border-radius: 2px;
}

.product-single .product-thumbimg img {
	width: 130px;
	height: 130px;
}

.page-header .page-select {
	width: 20%;
}

.social li {
	margin-right: 15px;
	display: inline-block;
	list-style: none;
	font-size: 15px;
}

.social-icons li{ 
	display:inline-block;
	a{
		color: $white;
		margin-right: 15px;
		display: inline-block;
		list-style: none;
		font-size: 15px;
		width: 34px;
		height: 34px;
		margin: 1px;
		border-radius: 8px;
		border: 0px solid rgba(255,	255, 255, 0.2);
		background:$white-1;
		line-height: 34px;
		text-align:center;
	}
}

.social li a {
	color: $white-6;
	line-height: 0;
}

.product-tags {
	a {
		float: left;
		margin: 4px;
		padding: 5px 10px;
		border-radius: 5px;
		font-size: 12px;
		border: 1px solid #e8ebf3;
	}
	li a:hover {
		color: $white !important;
	}
}

.box-shadow {
	box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.15) !important;
}

.support-service {
	padding: 1.5rem 1.5rem;
	border: 1px solid #e8ebf3;
	background: #f2f3f8;
	i {
		float: left;
		margin-right: 15px;
		font-size: 1.5rem;
		line-height: 44px;
		width: 45px;
		text-align: center;
		height: 45px;
		border-radius: 50%;
		background: $white-1;
		border: 0px solid $white-3;
	}
	h6 {
		font-size: 20px;
		font-weight: 400;
		margin-bottom: 0;
	}
	p {
		margin-bottom: 0;
		font-size: 15px;
		color: $white-5;
	}
}

.counter-icon {
	margin-bottom: 2rem;
    display: inline-block;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    line-height: 4.2rem;
    text-align: center;
    font-size: 24px;
	position:relative;
	i {
		font-size: 24px;
	}
	&:before{
		content: '';
		position: absolute;
		width: 5.2rem;
		height: 5.2rem;
		margin: 0 auto;
		display: block;
		left: -9px;
		right: 0;
		top: -10px;
		background: transparent;		
		border-radius: 50%;
	}
	&:after{
		content: '';
		position: absolute;
		width: 10px;
		height: 10px;		
		border-radius: 50%;
		top: -9px;
	}
}

.get-contact {
	a {
		color: #212529;
		margin-right: 15px;
		font-size: 16px;
		display: inline-block;
	}
	i {
		font-size: 18px;
	}
}

.filter-product-social {
	.social-icon2 li {
		color: #212529;
		margin-right: 35px;
		display: inline-block;
		list-style: none;
		font-size: 20px;
	}
	.social-icons li a {
		color: #212529;
	}
}

.rated-products {
	.media {
		padding-bottom: 15px;
		border-bottom: 1px solid #e8ebf3;
		&:last-child {
			padding-bottom: 0;
			border-bottom: 0;
		}
		img {
			width: 80px;
			height: 80px;
		}
	}
	img {
		border: 1px solid #e8ebf3;
		padding: 5px;
		background: #f6f7fb;
	}
}

.product-info-img a {
	position: absolute;
	top: 35px;
	right: 30px;
}

.product-filter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	margin-bottom: 15px;
	.product-filter-img {
		width: 60px;
		height: 60px;
	}
}

.product-filter-desc .product-filter-icons a {
	width: 2.5em;
	height: 2.5em;
	line-height: 2.6em;
	border-radius: 8px;
	font-size: 1em;
	display: inline-block;
	vertical-align: middle;
	&:hover {
		color: $white;
	}
}

.info-box small {
	font-size: 14px;
}

.info-box-icon {
	display: block;
	float: left;
	height: 90px;
	width: 90px;
	text-align: center;
	font-size: 45px;
	line-height: 90px;
	background: $black-2;
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.info-box {
	display: block;
	min-height: 90px;
	background: $white;
	width: 100%;
	border-radius: 2px;
	margin-bottom: 15px;
	box-shadow: 0 0 0 1px #dce3ec, 0 8px 16px 0 #dce3ec;
}

.info-box-icon>img {
	max-width: 100%;
}

.info-box-content {
	padding: 1.5rem 1.5rem;
	margin-left: 90px;
}

.info-box-number {
	display: block;
}

.info-box-text {
	display: block;
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: uppercase;
}

.info-box-more {
	display: block;
}

.product-item {
	img {
		height: 180px;
		width: 180px;
	}
	span {
		top: 15px;
		left: 15px;
		position: absolute;
	}
}

.product-item-wrap .product-item-price {
	.newprice {
		font-size: 18px;
		font-weight: 600;
	}
	.oldprice {
		margin-left: 5px;
		font-size: 18px;
		font-weight: 600;
	}
}

.product-item1 {
	position: relative;
	.item-overlay {
		border-radius: .2rem .2rem 0 0;
		overflow: hidden;
		padding: 15px;
		text-align: center;
		img {
			height: 200px;
			width: 200px;
		}
	}
	.btn-overlay {
		position: absolute;
		display: none;
		text-align: center;
		top: 45%;
		margin: 0 auto;
		left: 0;
		width: 100%;
		height: 100%;
		right: 0;
		z-index: 1;
	}
	&:hover {
		&.product-item1:before {
			content: "";
			background-color: $white-8;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
		}
		.btn-overlay {
			display: block;
			z-index: 1;
			opacity: 1;
		}
	}
}

.product-item2 .product-item2-img {
	padding: 1.5rem 1.5rem;
}

.product-item2-rating {
	margin-right: 10px;
	display: inline-block;
	vertical-align: middle;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
	line-height: 1;
	white-space: nowrap;
	clear: both;
}

.product-item2 {
	border-bottom: 1px solid #e8ebf3;
	background: #e7e9f1;
}

.owl-productimg img {
	background: #e7e9f1;
	padding: 20px;
}

.product-item2-desc .label-rating {
	margin-right: 10px;
	display: inline-block;
	vertical-align: middle;
}

.product-details {
	.media {
		margin-top: 0;
	}
	.card-item-desc {
		padding: 0;
		margin-left: 15px;
		margin-top: 8px;
	}
}

.product-singleinfo .product-item2-align dt,
dd {
	display: inline-block;
}

.product-singleinfo {
	.product-item2-align {
		dd {
			margin-left: 2rem;
			margin-bottom: 0;
			vertical-align: baseline;
		}
		dt {
			width: 100px;
			float: left;
		}
	}
	dl {
		margin-bottom: 5px;
	}
}

.oldprice {
	font-size: 25px;
	font-weight: 600;
}

.product-singleinfo .product-ship .product-item-price {
	.newprice {
		font-size: 25px;
		font-weight: 600;
	}
	.oldprice {
		margin-left: 5px;
	}
}

.product-multiinfo {
	.card {
		box-shadow: none;
		margin-bottom: 0;
		border-bottom: 1px solid #e8ebf3;
	}
	.product-item img {
		width: 120px;
		height: 120px;
	}
}

.single-productslide .product-gallery .product-item {
	margin-bottom: 10px;
	overflow: hidden;
	background-color: $white;
}

.product-gallery {
	.product-item img {
		height: 350px;
		width: auto;
		display: inline-block;
		cursor: -webkit-zoom-in;
		cursor: zoom-in;
	}
	.product-sm-gallery {
		text-align: center;
		.item-gallery {
			img {
				max-width: 100%;
				max-height: 100%;
				-o-object-fit: cover;
				object-fit: cover;
				border-radius: 2px;
				cursor: -webkit-zoom-in;
				cursor: zoom-in;
			}
			width: 80px;
			height: 80px;
			border: 1px solid #e8ebf3;
			padding: 5px;
			display: inline-block;
			overflow: hidden;
			line-height: 4.5;
		}
	}
}

.single-productslide .product-gallery-data {
	padding: 30px;
}

.product-gallery-data .product-gallery-data1 dt,
dd {
	display: inline-block;
}

.product-gallery-data {
	dl {
		margin-bottom: 5px;
	}
	.product-gallery-data1 {
		dt {
			width: 100px;
			float: left;
		}
		dd {
			margin-left: 2rem;
			margin-bottom: 0;
			vertical-align: baseline;
		}
	}
}

.product-gallery-rating {
	margin-right: 10px;
	display: inline-block;
	vertical-align: middle;
	list-style: none;
	margin: 0;
	font-size: 18px;
	padding: 0;
	position: relative;
	line-height: 1;
	white-space: nowrap;
	clear: both;
}

.product-gallery-rats {
	margin-top: 20px;
}

.product-gallery-data {
	.label-rating {
		margin-right: 10px;
		display: inline-block;
		vertical-align: middle;
	}
	.product-gallery-quty dt {
		display: inline-block;
	}
}

dd,
.product-gallery-data .product-gallery-size dt,
dd {
	display: inline-block;
}

.product-gallery-data .product-gallery-size .product-gallery-checks label {
	display: inline-block;
	margin-left: 10px;
}

.product-card-img img {
	height: 180px;
	width: 180px;
}

.product-card-icons {
	position: absolute;
	top: 15px;
	left: 15px;
	right: 15px;
	display: inline-block;
}

.product-card-footer .product-footer-wrap .footer-wrap-price {
	font-size: 16px;
	font-weight: 600;
	del {
		margin-left: 5px;
	}
}

.product-card4-footer .product-card4-wrap .product-card4-price h6,
.product-card5-footer .product-card5-price h6 {
	font-size: 25px;
	font-weight: 600;
}

.product-card6-footer {
	.product-card6-price h6 {
		font-size: 25px;
		font-weight: 600;
	}
	.product-card6-info del {
		margin-right: 5px;
	}
}

.product-card7-footer .product-card7-price {
	h6 {
		font-size: 25px;
		font-weight: 600;
	}
	del,
	span {
		margin-left: 5px;
	}
}

.team-section .team-img img {
	max-width: 150px;
	box-shadow: 0 0 0 1px #dce3ec, 0 8px 16px 0 #dce3ec;
}

.checklist-task .checklist-desc .check-data {
	span {
		font-size: 13px;
	}
	.check-icon {
		margin-top: 0;
	}
}

.banner1 {
	.carousel-inner img {
		width: 100%;
		max-height: 460px;
	}
	.carousel-item:before {
		content: '';
		display: block;
		position: absolute;
		background: $black-6;
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
	}
	.carousel-control {
		width: 0;
		&.left,
		&.right {
			opacity: 1;
			background-image: none;
			background-repeat: no-repeat;
			text-shadow: none;
		}
		&.left span,
		&.right span {
			padding: 15px;
		}
		.glyphicon-chevron-left,
		.glyphicon-chevron-right,
		.icon-next,
		.icon-prev {
			position: absolute;
			top: 45%;
			z-index: 5;
			display: inline-block;
		}
		.glyphicon-chevron-left,
		.icon-prev {
			left: 0;
		}
		.glyphicon-chevron-right,
		.icon-next {
			right: 0;
		}
		&.left span,
		&.right span {
			background: $white-1;
			color: $white;
		}
		&.left span:hover,
		&.right span:hover {
			background: $white-3;
		}
	}
	.header-text {
		position: absolute;
		left: 0;
		right: 0;
		color: $white;
	}
	.slider .header-text {
		top: 29%;
	}
	.header-text {
		h2 {
			font-size: 40px;
		}
		h3 {
			font-size: 25px;
		}
		h2 span {
			padding: 10px;
		}
		h3 span {
			padding: 15px;
		}
	}
}

@media (max-width: 480px) {
	.banner1 {
		.slider {
			.header-text {
				top: 30% !important;
			}
			img {
				height: 600px;
			}
		}
		&.slider-images .header-text {
			top: 5% !important;
		}
	}
	.sptb-2 {
		padding-top: 0 !important;
		padding-bottom:10rem !important;
	}
	.sptb-tab.sptb-2.pt-10 {
		padding-top: 6.5rem !important;
	}
}

@media (min-width: 481px) and (max-width: 767px) {
	.banner1 .slider {
		.header-text {
			top: 35% !important;
		}
		img {
			height: 500px;
		}
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.banner1 .slider .header-text {
		top: 32% !important;
	}
}

@media (min-width: 993px) and (max-width: 1240px) {
	.banner1 .slider .header-text {
		top: 23% !important;
	}
}

.input-indec {
	.quantity-left-minus.btn {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right : 0;
	}
	.quantity-right-plus.btn {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-left : 0;
	}
}

.cat-item {
	position: relative;
	a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	.cat-img img {
		width: 60px;
		height: 60px;
		margin: 0 auto;
		top:7px;
	}
	.cat-desc {
		margin-top: 15px;
		color: #495057;
	}
	.cat-img-circle{
		width:80px;
		height:80px;
		line-height:80px;
		border-radius:50%;
		background:rgba(0,0,0,0.1);
		margin:0 auto;
	}
}

.about img {
	width: 60px;
	height: 60px;
	text-align: center;
}

.business-item .item-card .item-card-desc .item-card-text {
	top: 40%;
}

@media (max-width: 767px) {
	#count-down1 {
		.clock-presenter {
			width: 50%;
			float: left;
			.digit {
				font-size: 18px;
			}
		}
		.hours_dash {
			border-right : none;
		}
	}
	.banner1 .slide-header-text {
		top: 12% !important;
	}
}

@media (max-width: 1300px) and (min-width: 400px) {
	.classifieds-content .item-search-menu ul li a,
	.item-search-menu ul li a {
		display: block;
		margin: 3px;
		border-radius: 3px;
	}
}

.accent-2 {
	width: 60px;
}

.sptb {
	padding-top:6rem;
	padding-bottom: 6rem;
}

.sptb-sm {
	padding-top:3rem;
	padding-bottom: 3rem;
}

.sptb-1 {
	padding-top: 8.5rem;
	padding-bottom: 4.5rem;
}

.sptb-2 {
	padding-top: 9.5rem;
	padding-bottom: 16rem;
}

.sptb-3,
.sptb-4 {
	padding-top: 6.2rem;
	padding-bottom: 11rem;
}

.sptb-8 {
	padding-top: 7rem;
	padding-bottom: 12rem;
}

.categories {
	margin-top: -35px;
}

.categories-1 {
	margin-top: -120px;
}

.section-title {
	p {
		font-size: 16px;
		margin-bottom: 0;
	}
	padding-bottom: 3rem;
	h1 {
		margin-bottom: .5rem;
		color:  $color;
		font-size: 36px;
		font-weight: 400;
		z-index: 1;
	}
	h2{
		margin-bottom: .5rem;
		color: $color;
		font-size: 30px;
		font-weight: 500;
		z-index: 1;
	}
	.spcl-heading{
		font-size: 14px;
		text-transform: uppercase;
		font-weight: 600;
		margin-bottom: 0px;
		font-family: 'Poppins', sans-serif;
	}
}

.spcl-heading {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0px;
    font-family: 'Poppins', sans-serif;
}

.index-search-select .select2 {
	width: 100% !important;
}

@media (min-width: 992px) {
	.select2-container .select2-selection--single {
		border-radius: 0px !important;
	}
	.select2-radius-single .select2-container .select2-selection--single {
		border-radius: 8px !important;
	}
	.slider-images img.slide {
		background-size: cover;
		left: 0;
		right: 0;
		width: 100% !important;
		&.location-gps{
			left: 12px !important;
			right: auto !important;
		}
	}
	.banner1 .slider .header-text {
		top: 29%;
	}
	.input-field {
		margin-top: 7.55rem !important;
	}
	.classified-video {
		margin-top: 2.1rem;
	}
}

@media (min-width: 768px) and (max-width: 800px) {
	.banner1 .slide-header-text {
		top: 17% !important;
	}
}

@media (min-width: 800px) and (max-width: 991px) {
	.banner1 .slide-header-text {
		top: 20% !important;
	}
}

@media (max-width: 991px) {
	.select2-container .select2-selection--single {
		border-radius: 8px !important;
	}

	.map-content-width .header-text1 .search-background .form-control:first-child {
		border: 1px solid #e8ebf3;
	}

	#main section {
		height: 600px !important;
	}

	.sptb-4 {
		padding-top: 0rem;
		padding-bottom: 8rem;
	}

	.sptb-3 {
		padding-top: 0rem;
		padding-bottom: 10rem;
	}

	.banner-1 .item-search-tabs .search-background .form-control {
		border-radius: 3px !important;
		border-bottom: 1px !important;
	}

	.item-card9-img {
		height: auto !important;
	}

	.blog-list-1 {
		height: 100% !important;
	}

	.banner-1 .search-background {
		.form-control {
			border-radius: 8px !important;
		}
		.form1 {
			border-radius: 0;
			border-left : 1px solid #e8ebf3 !important;
		}
	}

	.item-search-tabs a.btn {
		border-top-left-radius: 8px !important;
		border-bottom-left-radius: 8px !important;
		right: 0;
	}

	.content-text .display-5 {
		font-size: 1.8rem;
	}

	.counter-status.status {
		margin-top: 1.5rem;
	}

	.margin-top {
		margin-top: 4.5rem !important;
	}

	.desktoplogo-1 {
		display: none;
	}

	.sptb-1 {
		padding-top: 4.5rem;
		padding-bottom: 4.5rem;
	}

	.search-background {
		background: 0 0 !important;
		border-radius: 0;
	}

	.padding-bottom {
		padding-bottom: 0 !important;
	}

	.banner-2 .form-control {
		border-radius: 3px !important;
	}
	
	.item-search-tabs .form-control.border {
		border-radius: 0px !important;
	}
	
	.banner-1 .search-background .btn-lg {
		border-radius: 8px !important;
		right: 0 !important;
	}

	.sptb-2 {
		padding-top: 0;
		padding-bottom: 11rem;
	}

	.banner-1.sptb-2 {
		padding-top: 0;
		padding-bottom: 10rem;
	}

	.sticky-wrapper.is-sticky {
		.horizontal-main {
			background: 0 0;
		}
		.desktoplogo-1 {
			display: none !important;
		}
	}

	.bannerimg {
		padding: 3rem 0 3rem 0 !important;
		background-size: cover;
	}
	
	.sticky-wrapper {
		height: 0 !important;
	}

	.select2.select2-container.select2-container--default.select2-container--focus {
		width: 100% !important;
		overflow: hidden;
	}
}

.banners-image .horizontalMenu>.horizontalMenu-list>li>a {
	color: $color !important;
}

.sticky-wrapper.absolute.banners {
	.horizontal-main {
		background: $white;
		&:after {
			content: '';
			position: absolute;
			height: 0px;
			border-top: 0;
			border-radius: 5px;
			z-index: 3;
			width: 100%;
			bottom: 0;
		}
	}
}

.banner-1 {
	// .item-search-tabs .search-background .form-control {
	// 	border-radius: 0;
	// 	// border-bottom: 0 !important;
	// }

	.search-background {
		.form-control {
			border-radius: 10px;
			border-right : 0;
		}

		.form1 {
			border-radius: 0;
			border-left : 1px solid #e8ebf3 !important;
		}

		.btn-lg {
			border-radius: 8px;
			position: relative;
		}

		.select2-container--default .select2-selection--single {
			border-radius: 10px;
			border-right : 0 !important;
		}
	}

	.header-text,
	.header-text1 {
		left: 0;
		right: 0;
		color: $white;
	}

	.header-text {
		h1 {
			margin-bottom: .3rem;
			font-weight: 400;
			font-size:40px;
		}
		
		p {
			margin-bottom: 1.5rem;
			font-size: 16px;
		}
	}
}

.banner-2 {
	.form-control {
		border-top-left-radius: 2px;
		border-top-right-radius: 2px;
		border: 0 !important;
	}
	
	.header-text {
		left: 0;
		right: 0;
		color: $white;
		p {
			margin-bottom: 0;
			font-size: 16px;
		}
	}
}

.bannerimg {
	padding: 6.5rem 0 3rem 0;
	background-size: cover;
	.header-text h1 {
		margin-bottom: .75rem;
	}
}

.about-1 {
	position: relative;
	.header-text {
		left: 0;
		right: 0;
		color: $white;
	}
}

.country .dropdown-menu {
	height: 233px;
	overflow: scroll;
}

.test-carousel {
	.carousel-control-next i,
	.carousel-control-prev i {
		font-size: 2rem;
		color: $black;
		padding: .5rem;
	}
}

.support-list li {
	a {
		margin-bottom: .5rem;
		display: flex;
	}
	i {
		margin-right: .7rem;
		margin-top: 4px;
	}
}

.profile-pic-img {
	position: relative;
	line-height: 5rem;
	width: 165px;
    height: 165px;
	font-size: 2rem;
	text-align: center;
	margin: 0 auto;
	z-index:2;
	span {
		position: absolute;
		width: 1rem;
		height: 1rem;
		border-radius: 50px;
		right: -5px;
		top: -5px;
		border: 3px solid #b8e4d4;
	}
}

.sub-newsletter {
	display: inline-block;
}

.user-tabel span {
	margin: 0 auto;
}

.settings-tab .tabs-menu {
	margin-bottom: 25px;
	li {
		width: 33.3%;
		display: block;
		a {
			padding: .75rem 1rem;
			background: #f2f3f8;
			color: $black;
			display: block;
			text-align: center;
			border-radius: 8px;
			margin:0 4px;
			&.active {
				color: $white;
				border-radius: 8px;
				text-align: center;
			}
		}
	}
}

.ads-tabs .tabs-menus {
	margin-bottom: 2rem;
	ul li {
		a {
			border: 1px solid transparent;
			color: $color;
			padding: 10px 14px 11px 14px;
			border-radius:8px;
		}
		margin-right: 1rem;
		&:last-child {
			margin-right: 0;
		}
	}
}

.select-languages {
	color: $color;
	border-radius: 3px;
	&:focus {
		color: #3d4e67;
		background-color: $white;
	}
}

.img-flag {
	width: 25px;
	height: 12px;
	margin-top: -2px;
}

#price {
	border: 0;
	font-weight: 500;
	font-size: 18px;
}

.showmore-button {
	text-align: center;
}

.showmore-button-inner {
	display: inline-block;
	padding: 10px 19px;
	line-height: 14px;
	font-size: 14px;
	border-radius: 8px;
	text-decoration: none;
	cursor: pointer;
	margin-top: 20px;
}

.showmore-button1 {
	text-align: center;
	.showmore-button1-inner {
		display: inline-block;
		line-height: 14px;
		font-size: 14px;
		color: #a7b4c9;
		text-decoration: none;
		cursor: pointer;
		margin-top: 8px;
		margin-bottom: 20px;
	}
}

.fea-ad .card,
.cat-slide .card {
	margin-bottom: 0;
}

.fade.in {
	opacity: 1;
}

.owl-carousel {
	position: relative;
	.owl-item {
		position: relative;
		cursor: url(../images/other/cursor.png), move;
		overflow: hidden;
	}
}

.owl-nav {
	display: block;
}

#small-categories .owl-nav {
	.owl-next,
	.owl-prev {
		top: 58%;
	}
}

.slider .owl-nav {
	.owl-prev {
		left: 15px;
	}
	.owl-next {
		right: 15px;
	}
}

.owl-nav {
	.owl-prev {
		position: absolute;
		top: 50%;
		left: -25px;
		right: -1.5em;
		margin-top: -1.65em;
	}
	.owl-next {
		position: absolute;
		top: 50%;
		right: -25px;
		margin-top: -1.65em;
	}
	button {
		display: block;
		font-size: 1rem !important;
		line-height: 3.1em !important;
		border-radius: 50%;
		width: 3rem;
		height: 3rem;
		text-align: center;
		background: $white !important;
		border: 1px solid #e8ebf3 !important;
		z-index: 99;
		box-shadow: 0 4px 15px rgba(67, 67, 67, 0.15);
		&:before {
			content: "";
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: #2098d1;
			-webkit-transform: scaleY(0);
			transform: scaleY(0);
			-webkit-transform-origin: 50% 0;
			transform-origin: 50% 0;
			-webkit-transition-property: transform;
			transition-property: transform;
			-webkit-transition-duration: .5s;
			transition-duration: .5s;
			-webkit-transition-timing-function: ease-out;
			transition-timing-function: ease-out;
		}
	}
}

@media (max-width: 576px) {
	.owl-nav {
		.owl-prev {
			left: -8px;
		}
		.owl-next {
			right: -8px;
		}
	}

	.header-main .top-bar .top-bar-start .socials li a,
	.header-main .top-bar .top-bar-end .custom li a {
		padding: 5px 11px;
	}

	.countdown-timer-wrapper {
		.timer .timer-wrapper .time {
			width: 70px;
			height: 70px;
			font-size: 1.3rem !important;
			margin-bottom: -25px;
		}
		margin: 39px auto 15px auto !important;
	}

	.categories-1 .banner-1 .header-text p {
		margin-bottom: .5rem;
		font-size: 14px;
	}
}

.owl-carousel:hover .owl-nav button {
	background: rgb(255, 255, 255) !important;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-duration: .5s;
	transition-duration: .5s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
	animation: sonarEffect 1.3s ease-out 75ms;
}

.owl-nav>div i {
	margin: 0;
}

.owl-theme .owl-dots {
	text-align: center;
	-webkit-tap-highlight-color: transparent;
	position: absolute;
	bottom: .65em;
	left: 0;
	right: 0;
	z-index: 99;
	.owl-dot {
		display: inline-block;
		zoom: 1;
		span {
			width: 1em;
			height: 1em;
			margin: 5px 7px;
			background: $black-3;
			display: block;
			-webkit-backface-visibility: visible;
			backface-visibility: visible;
			transition: opacity .2s ease;
			border-radius: 30px;
		}
	}
}

.testimonial-owl-carousel img {
	margin: 0 auto;
	text-align: center;
}

.center-block {
	float: none;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	float: inherit !important;
}
.owl-carousel.owl-drag .owl-item {
	left: 0 !important;
	right: 0;
}

.wishlist {
	background: $color;
	&.active {
		background: #e62a72;
	}
}

.coupon-code .coupon {
	background: rgba(242, 187, 26, 0.1);
	border: 2px dashed #f2bb1a;
	text-align: center;
	border-radius: 2px;
}

.eductaional-tabs.nav {
	display: flex;
	justify-content: center;
	flex-direction: row;
}

@media (max-width: 767px) {
	.tabs-menu ul.eductaional-tabs li {
		width: 50%;
		text-align: center;
	}
	.icon-card li {
		&.mb-0 {
			&:last-child {
				margin-bottom: 0 !important;
			}
			margin-bottom: 0.5rem !important;
		}
		width: 100% !important;
	}
	.item-cards7-ic li {
		width: 100% !important;
	}
	.item-card7-desc ul.d-flex li {
		margin-bottom: 10px;
		width: 100% !important;
	}
	.counter-status.status-1 {
		margin-top: 1.5rem;
	}
	.item-card7-desc ul.d-flex {
		display: block !important;
	}
	.tabs-menu ul.eductaional-tabs li a {
		display: block;
	}
	.item-card2-desc ul.d-flex li {
		margin-bottom: 10px;
	}
	.item7-card-desc.d-flex,
	.item-card2-desc ul.d-flex {
		display: block !important;
	}
	.navtab-wizard.nav-tabs .nav-link{
		margin:3px;
	}
}

.horizontalMenu>.horizontalMenu-list>li>a.btn:hover {
	background: #fb9512;
}

.profile-pic {
	text-align: center;
	.d-md-flex {
		text-align: left;
	}
}

@media (max-width: 480px) {
	.d-flex.ad-post-details {
		display: block !important;
	}
	.tabs-menu1 ul li a {
		margin: 2px;
	}
	.list-id .col {
		display: block;
		width: 100%;
	}
	.ads-tabs .tabs-menus ul li {
		a {
			width: 100%;
			display: block;
		}
		margin-bottom: 10px;
		margin-right: 0;
		width: 100%;
	}
	.zoom-container .arrow-ribbon2 {
		font-size: 20px;
		&:before {
			right: -17px;
		}
	}
}

@media (min-width: 561px) and (max-width: 767px) {
	.navtab-wizard.nav-tabs li {
		width: 50%;
		display: contents;
	}
	.tabs-menu1 ul li {
		display: block;
		width: 49%;
		margin: 1px;
	}
	.navtab-wizard.nav-tabs .nav-link {
		border-top: 1px solid #e8ebf3;
		margin:3px;
	}
}

.owl-carousel {
	.owl-dots {
		margin: 0 auto;
		text-align: center;
	}
	button.owl-dot {
		margin: 10px 10px 0 10px;
		border-radius: 50%;
		width: 10px;
		height: 10px;
		text-align: center;
		display: inline-block;
		border: none;
	}
}

.owl-carousel-icons5 .owl-nav {
	.owl-prev {
		position: absolute;
		top: 42%;
		left: auto;
		right: -24px;
		margin-top: -1.65em;
	}
	.owl-next {
		position: absolute;
		top: 58%;
		left: auto;
		right: -24px;
		margin-top: -1.65em;
	}
}

.owl-carousel-icons4.owl-carousel .owl-item img {
	margin: 0 auto;
}

#exzoom {
	width: 100%;
}

:focus {
	outline: 0 !important;
}

.marketplace-or {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	font-size: 20px;
	line-height: 56px;
	text-align: center;
	margin: 0 auto;
	border: 3px solid rgba(255, 162, 43, 0.1);
	background: rgba(255, 162, 43, 0.3);
}

.marketplace-alignment {
	.text-center {
		margin: 0 auto;
		text-align: center;
		display: block;
	}
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
}

.ecommerce-menu {
	float: none !important;
	&.horizontalMenu>.horizontalMenu-list>li>a {
		padding: 9px 30px;
		.fa {
			margin-right: 9px;
		}
	}
}

@media (max-width: 340px) {
	.auction-content .product-item2-desc .product-item2-rating {
		display: block;
	}
}

@media (max-width: 767px) and (min-width: 569px) {
	.auction-content .product-item2-desc .product-item2-rating {
		display: block;
	}
}

@media (max-width: 1024px) and (min-width: 992px) {
	.auction-content .product-item2-desc .product-item2-rating,
	.owl-carousel-icons6 .product-item2-desc .product-item2-rating {
		display: block;
	}
}

@media (min-width: 992px) and (max-width: 1350px) {
	.ecommerce-menu.horizontalMenu>.horizontalMenu-list>li>a {
		padding: 9px 15px;
	}

	.product-item2-desc .d-flex.product-item2-rating1 {
		display: block !important;
	}
}

@media (min-width: 568px) and (max-width: 767px) {
	#container1 {
		min-height: 460px !important;
	}
}

@media (max-width: 567px) {
	#container1 {
		min-height: 515px !important;
	}
}

@media (max-width: 991px) {
	.header-menu1 {
		.input-group {
			padding: 10px !important;
		}
		a.btn {
			width: 220px;
			text-align: center !important;
			margin: 0 auto !important;
			float: none !important;
		}
	}
}

.register-right .nav-tabs {
	.nav-link {
		padding: 12px 25px;
		margin: 1px;
		text-align: center;
		display: block;
		border-radius: 3px;
	}
	background: $white;
	margin: 0 auto;
	border-radius: 3px;
	box-shadow: 0 10px 40px 0 rgba(62,57,107,0.1),0 2px 9px 0 rgba(62,57,107,0.1);
	.nav-item {
		display: block;
		text-align: center;
	}
}

@media (max-width: 320px) {
	.Marketplace .tab-pane .card-footer .footerimg-r {
		display: none;
	}
}

#homeVideo button.btn.btn-default {
	background: rgba(34, 40, 74, 0.7);
	position: absolute;
	right: 5px;
	top: 5px;
	border-radius: 25px;
	padding: 6px 3px;
	line-height: 25px;
	z-index: 5;
	color: $white;
	border:0;
	&:hover {
		background: rgb(34, 40, 74);
	}
}

.vertical-scroll {
	.news-item {
		border: 1px solid #e8ebf3 !important;
		margin-bottom: 15px;
		padding: 10px;
		td:last-child {
			width: 76%;
		}
	}
	.item {
		border: 1px solid #e8ebf3 !important;
		margin-bottom: 20px;
		margin-top: 0px;
		.p-5 {
			padding: 1rem !important;
		}
	}
	margin-bottom: 0;
}

.vertical-scroll1 .item {
	border: 1px solid #e8ebf3 !important;
	margin-bottom: 15px;
	background: $white;
	a {
		text-decoration: intial;
	}
}

.vertical-scroll .item2 {
	border-bottom: 1px solid #e8ebf3 !important;
	padding: 12px 25px;
}

@media (max-width: 480px) {
	.employers-details {
		display: none;
	}

	.hide-details {
		height: 128px !important;
	}
}

@media (max-width: 400px) {
	.employers-btn,
	.distracted {
		display: none;
	}

	.checkboxbtns .btn {
		font-size: 9px;
	}
}

@media (min-width: 992px) {
	.header-main-banner .horizontalMenu>.horizontalMenu-list>li>a {
		color: $white-8;
		>.fa {
			color: $white-8;
		}
	}
}

.pt-10 {
	padding-top: 8rem !important;
}

@media (max-width: 480px) {
	.reviews-categories .widget-spec1 li {
		margin-bottom: .5rem;
		font-size: .9rem;
		display: flow-root;
		span {
			margin: 0;
		}
	}
}

@media (max-width: 767px) {
	.books-categories.item-all-cat .row .item-all-card,
	.doctor-categories.item-all-cat .row .item-all-card {
		margin-bottom: .5rem;
	}

	.rating-stars .rating-stars-container .rating-star.sm {
		font-size: 13px;
	}
}

@media (max-width: 568px) {
	.items-gallery .items-blog-tab-heading {
		padding: 15px 0;
	}

	.items-blog-tab-heading .items-blog-tab-menu li a {
		margin-left: 0;
		margin-right: 0;
	}

	.items-gallery .items-blog-tab-heading {
		display: block;
	}

	.books-categories.item-all-cat .row .item-all-card {
		margin-bottom: .5rem;
	}
}

.horizontalMenu>.horizontalMenu-list,
.horizontal-header {
	background-color: transparent;
}

.horizontalMenu>.horizontalMenu-list>li {
	>a {
		color: $white-7;
		&.btn {
			color: $white;
			font-size: .85rem;
		}
		>.fa{
			color: $white-4;
		}
		&.active .fa {
			color: $white;
		}
	}

	&:hover>a>.fa {
		color: $white;
	}
	> {
		a:hover .fa {
			color: $white;
		}
		ul.sub-menu>li {
			a i {
				color: #333;
			}
		}
	}
}

.view-btn {
	i {
		line-height: 40px !important;
	}
}

button#ihavecookiesBtn {
	margin-left: 0 !important;
}
@media (max-width:991px){
	#gdpr-cookie-message {
		position: fixed;
		z-index: 999;
		left: 0;
		bottom: 30px;
		max-width: 375px;
		background-color: rgb(255, 255, 255);
		padding: 20px;
		border-radius: 5px;
		border: 1px solid #e8ebf3;
		box-shadow: 0 8px 20px 0 rgba(40, 37, 89, 0.6);
		margin-left: 30px;
		text-align: center;
		display: block;
		h4 {
			font-size: 18px;
			font-weight: 500;
			margin-bottom: 10px;
		}
		h5 {
			font-size: 15px;
			font-weight: 500;
			margin-bottom: 10px;
		}
		p,
		ul {
			color: $color;
			font-size: 15px;
			line-height: 1.5em;
		}
		p:last-child {
			margin-bottom: 10px;
			text-align: center;
		}
		li {
			width: 49%;
			display: -webkit-inline-box;
		}
		a {
			text-decoration: none;
			font-size: 15px;
			padding-bottom: 2px;
			transition: all .3s ease-in;
		}
		button {
			border: none;
			color: $white;
			font-size: 15px;
			padding: 7px 18px;
			border-radius: 8px;
			margin-top: 4px;
			cursor: pointer;
			transition: all .3s ease-in;
		}
	}

	.header-main-absolute .horizontal-main{
		margin-top:0 !important;
	}

	.slide_carosel .owl-item img{
		height:600px !important;
	}
}
@media (min-width:992px){
	#gdpr-cookie-message {
		position: fixed;
		z-index: 999;
		left: 0;
		bottom: 0px;
		max-width: 100%;
		width: 98%;
		background-color: rgb(255, 255, 255);
		padding: 20px;
		border-radius: 5px;
		border: 1px solid #e8ebf3;
		box-shadow: 0 8px 20px 0 rgba(40, 37, 89, 0.6);
		margin: 1%;
		text-align: left;
		display: flex;
		h4 {
			font-size: 18px;
			font-weight: 500;
			margin-bottom: 10px;
		}
		h5 {
			font-size: 15px;
			font-weight: 500;
			margin-bottom: 10px;
		}
		p,
		ul {
			color: $color;
			font-size: 15px;
			line-height: 1.5em;
		}
		p:last-child {
			margin-bottom: 0;
			text-align: right;
			margin-left:auto;
			margin-top:0px;
		}
		li {
			width: 49%;
			display: -webkit-inline-box;
		}
		a {
			text-decoration: none;
			font-size: 15px;
			padding-bottom: 2px;
			transition: all .3s ease-in;
		}
		button {
			border: none;
			color: $white;
			font-size: 15px;
			padding: 7px 18px;
			border-radius: 8px;
			margin-top: 18px;
			cursor: pointer;
			transition: all .3s ease-in;
		}
		.custom-cookie-margin{
			width:67%;			
			p{
				text-align: left;
			}
		}
	}
}

button {
	&#ihavecookiesBtn {
		border: none;
		color: $white;
		font-size: 15px;
		padding: 7px 18px;
		border-radius: 3px;
		margin-top: 4px;
		cursor: pointer;
		transition: all .3s ease-in;
	}

	&#gdpr-cookie-advanced {
		margin-left: 4px;
	}
}

@media (max-width: 390px) {
	button#gdpr-cookie-advanced {
		margin-left: 0 !important;
	}

	#gdpr-cookie-message p button {
		width: 100%;
	}
}

@media (max-width: 480px) {
	#gdpr-cookie-message {
		margin: 0 15px;
		display: block;
		right: 0;
		box-shadow: 0 0 20px 0 rgba(40, 37, 89, 0.9);
		display: none !important;
	}
}

#gdpr-cookie-message {
	button:disabled {
		opacity: .3;
	}

	input[type=checkbox] {
		float: none;
		margin-top: 0;
		margin-right: 5px;
	}
}

.marketplace-section img {
	width: 7rem;
	height: 7rem;
	border-radius: 50%;
	padding: 2.3rem 0;
}

.ui-widget-header {
	border: 1px solid #2c4359;
	color: #e1e463;
	font-weight: 700;
	a {
		color: #e1e463;
	}
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
	border: 1px solid #e8ebf3;
	font-weight: 700;
	color: #333;
}

#particles-js {
	position: absolute;
	height: 100%;
	left: 0;
	right: 0;
	width: 100%;
	top: 0;
	bottom: 0;
}

h1.animated-text {
	font-family: monospace;
	overflow: hidden;
	border-right : .15em solid orange;
	white-space: nowrap;
	margin: 0 auto;
	letter-spacing: .15em;
	animation: typing 3.5s steps(30, end), blinking-cursor 0.5s step-end infinite;
}

@keyframes typing {
	from {
		width: 0;
	}

	to {
		width: 100%;
	}
}

@keyframes blinking-cursor {
	from,
	to {
		border-color: transparent;
	}

	50% {
		border-color: orange;
	}
}

.banner1 .slide-header-text {
	top: 35%;
	z-index: 98 !important;
}

.slider-header .item:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	display: block;
	z-index: 1;
	top: 0;
}

a.typewrite {
	color: $white !important;
	font-size: 3rem;
}

.pattern {
	background: url(../images/banners/12.png);
	    background-repeat: no-repeat;
    background-size: cover;
	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		right: 0;
		display: block;
		z-index: 0;
		top: 0;
		background:rgba(46, 43, 80, 0.55);
	}
	.owl-carousel{
		.owl-nav button{
			background:rgba(46, 43, 80, 0.55) !important;
			color:$white !important;
			border-color:rgba($white, 0.1) !important;
		}
		&:hover{
			.owl-nav button{
				color:$white !important;
			}
		}
	}
}

.pattern-1 {
	background: linear-gradient($black-3, $black-3), url(../images/banners/banners.jpg) center;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	background-size: cover;
}

.pattern-2 {
	background: linear-gradient(-225deg, rgba(74, 61, 184, 0.8) 48%, rgba(50, 228, 179, 0.8) 100%), url(../images/products/products/subscribe.jpg);
}

.single-page {
	button.dark {
		border-color: #ff4931;
		background: #ff4931;
	}
	.move button.dark {
		border-color: #e0b722;
		background: #e0b722;
	}
	.splits p {
		font-size: 18px;
	}
	button {
		&:active {
			box-shadow: none;
		}
		&:focus {
			outline: 0;
		}
	}
	>.wrapper3 {
		width: 100%;
		padding-top: 8rem;
	}
	.wrapper {
		&.wrapper2 {
			background: $white;
			width: 100%;
			background: $white;
			transition: all .5s;
			color: $white;
			overflow: hidden;
			border-radius: 8px;
		}
		background: $white;
		box-shadow: 0 10px 40px 0 rgba(62,57,107,0.1),0 2px 9px 0 rgba(62,57,107,0.1);
		transition: all .5s;
		color: #838ab6;
		overflow: hidden;
		border-radius: 7px;
		>form {
			width: 100%;
			transition: all .5s;
			background: $white;
			width: 100%;
		}
		.card-body {
			padding: 30px;
		}
		>form:focus {
			outline: 0;
		}
		#login {
			visibility: visible;
		}
		#register {
			transform: translateY(-80%) translateX(100%);
			visibility: hidden;
		}
		&.move {
			#register {
				transform: translateY(-80%) translateX(0);
				visibility: visible;
			}
			#login {
				transform: translateX(-100%);
				visibility: hidden;
			}
		}
		>form>div {
			position: relative;
			margin-bottom: 15px;
		}
		label {
			color: $color;
		}
		h3,
		h4 {
			margin-bottom: 25px;
			font-size: 22px;
			color: $color;
		}
		input {
			height: 40px;
			padding: 5px 15px;
			width: 100%;
			border: solid 1px #e8ebf3;
			border-radius:5px;
			&:focus {
				outline: 0;
				border-color: #e8ebf3;
			}
		}
	}
	>.wrapper.move {
		left: 45%;
		input:focus {
			border-color: #e0b722;
		}
	}
	width: 100%;
	margin: 0 auto;
	display: table;
	position: relative;
	border-radius: 7px;
	&.single-pageimage {
		min-height: 387px;
		&::before {
			content: '';
			background: $white-5;
		}
	}
	>.log-wrapper {
		display: table-cell;
		vertical-align: middle;
		text-align: right;
		color: $white;
	}
}

@media (min-width: 1024px) {
	.single-pageimage .log-wrapper {
		margin-top: 100px;
	}
}

.customerpage .btn-icon {
	border: 1px solid rgba(96, 94, 126, 0.2);
}

.feature .icons {
	position: relative;
	display: inline-block;
	width: 3em;
	height: 3em;
	line-height: 3em;
	vertical-align: middle;
	border-radius: 50%;
	border: 1px solid $white-1;
}

.loader-img .floating {
	animation: floating 3s ease infinite;
	will-change: transform;
}

@keyframes floating {
	0% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(10px);
	}
	100% {
		transform: translateY(0px);
	}
}

.activity {
	position: relative;
	border-left : 1px solid #eaf2f9;
	margin-left: 16px;
	.img-activity {
		width: 42px;
		height: 42px;
		text-align: center;
		line-height: 34px;
		border-radius: 50%;
		position: absolute;
		left: -18px;
		-webkit-box-shadow: 0 0 0 0.5px #f8f9fa;
		box-shadow: 0 0 0 0.5px #f8f9fa;
	}
	.item-activity {
		margin-left: 40px;
		margin-bottom: 19px;
	}
}

.status-border {
	border: 1px solid #e8ebf3 !important;
}

.horizontal-main-1 {
	background: transparent;
	border-bottom: 1px solid rgba(255, 255, 255, 0.18);
}

.fa-1 {
	font-size: 1.5em;
}

@media (min-width: 1280px) {
	.container {
		max-width: 1200px;
	}
}
.blog-list .item7-card-img {
	height: 196px !important;
}

.blog-list-1 {
	height: 196px;
}

@media screen and (max-width: 1279px) {
	.blog-list .item7-card-img {
		height: 100% !important;
	}
}

.advanced-search {
	padding: 1rem;
	color: $color;
	border: 1px solid #eaeef9;
	&.advanced-search-sm{
		.custom-control-label{
			font-size:13px;
		}
	}
}

.responsive-background .navbar-collapse {
	position: absolute;
	display: none;
	margin: 0 auto;
	width: 97.4%;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0px 5px 25px rgba(72, 81, 119, 0.2);
	border:0px solid #eaeef9;
	background:$white;
	z-index:9999;
	&.show{
		display:none;
	}
	&.navbar-collapse-open{
		display:block !important;
	}
}

.rev_slider_wrapper {
	height: 0 !important;
}

.rev_slider,
.tp-fullwidth-forcer {
	height: 600px !important;
}

.rev-btn {
	z-index: 12;
	min-width: 100% px;
	max-width: 100% px;
	white-space: normal;
	font-size: 17px;
	line-height: 40px;
	font-weight: 700;
	color: $white;
	letter-spacing: px;
	display: block;
	outline: none;
	box-shadow: none;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	cursor: pointer;
	border-radius: 3px;
}

.tp-badge {
	background: $success;
	z-index: 7;
	white-space: normal;
	font-size: 13px !important;
	color: $white;
	border-radius: 3px;
	margin-bottom: 10px !important;
	display: inline-block !important;
	padding: .2em .4em !important;
	line-height: 1.2 !important;
	text-align: center !important;
	white-space: nowrap !important;
	vertical-align: baseline !important;
}

.tg-title {
	z-index: 8;
	white-space: normal;
	font-size: 16px;
	font-weight: 400;
	color: $white;
	letter-spacing: 0px;
	display: block;
	margin-bottom: 10px;
}

.tp-bg {
	z-index: 6;
	width: 100%;
	background-color: $black-8;
	color: $white;
	border-radius: 3px 0 0 0 !important;
	padding: 20px;
}

.tp-price {
	z-index: 9;
	white-space: normal;
	font-size: 40px;
	line-height: 40px;
	font-weight: 700;
	color: $white;
	letter-spacing: 0px;
	display: block;
	margin-bottom: 10px;
}

.tp-addr {
	z-index: 10;
	white-space: normal;
	font-size: 17px;
	line-height: 20px;
	font-weight: 400;
	color: #b1b9d8;
	letter-spacing: 0px;
	display: block;
	margin-bottom: 5px;
}

.tp-cat {
	z-index: 11;
	white-space: normal;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	color: #959ab1 !important;
	letter-spacing: 0px;
	display: block;
	margin-bottom: 20px;
}

.tp-bg1 {
	z-index: 13;
	width: 100%;
	background-color: $black-5;
	border-radius: 0 3px 0 0px !important;
	padding: 20px;
}

.tap-subcat {
	z-index: 14;
	min-width: 100% px;
	max-width: 100% px;
	white-space: normal;
	font-size: 16px !important;
	line-height: 20px;
	font-weight: 700;
	color: $white !important;
	letter-spacing: 0px;
	display: inline-block;
	margin-bottom: 5px !important;
}

.tap-subcat-text {
	z-index: 15;
	min-width: 100% px;
	max-width: 100% px;
	white-space: normal;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	color: #959ab1 !important;
	letter-spacing: 0px;
	display: inline-block;
	margin-bottom: 0 !important;
}

.tb-sub-feature {
	border-bottom: 1px solid $white-2;
	border-left : 1px solid $white-2;
	border-right : 1px solid $white-2;
	padding: 5px;
	border-radius: 3px;
	background: $black-5;
	display: grid;
	text-align: center;
	.tp-parallax-wrap {
		min-width: 0 !important;
		margin-left: 8px;
	}
}

.tp-main {
	margin-bottom: 0;
}

.tb-sub-feature:first-child {
	border-top: 1px solid $white-2;
}

@media (max-width: 795px) {
	.tb-sub-feature {
		border: 0;
		padding: 0;
	}
}

.search-background .form-control:first-child {
	border-right : 0;
}

.sold-out .card {
	pointer-events: none;
	opacity: 0.7;
}

.sold-ribbon {
	display: none !important;
}

.sold-out {
	.sold-ribbon {
		display: block !important;
		opacity: 1 !important;
	}
	.arrow-ribbon,
	.power-ribbon {
		display: none !important;
	}
}

.sold-out1 {
	position: relative;
	overflow: hidden;
	.card {
		pointer-events: none;
		opacity: 0.5;
	}
	.sold-ribbon {
		display: block !important;
		opacity: 1 !important;
	}
	.arrow-ribbon {
		display: none !important;
	}
}

.sold-out .power-ribbon {
	display: none !important;
}

.advnaced-slider {
	&.ui-slider-horizontal {
		height: .1em;
	}
	&.ui-slider .ui-slider-handle {
		width: 0.6em;
		height: 0.6em;
	}
	&.ui-widget-content {
		border: 1px solid #3d4e67;
	}
}

.property-slide a {
	&.carousel-control-next {
		position: absolute;
		width: 20% !important;
		height: auto;
		right: -1rem !important;
		top: 0;
		bottom: 0;
		left: auto !important ;
	}
	&.carousel-control-prev {
		position: absolute;
		left: -1rem !important;
		width: 20% !important;
		height: auto;
		top: 0;
		bottom: 0;
		right: 0;
	}
}
.op1 {
	opacity: 0.1;
}

.op2 {
	opacity: 0.2;
}

.op3 {
	opacity: 0.3;
}

.op4 {
	opacity: 0.4;
}

.op5 {
	opacity: 0.5;
}

/*Banner Owl */

.owl-carousel.owl-fade {
	display: block;
}

.owl-fade {
	.item img {
		display: block;
		width: 100%;
		height: auto;
	}
	.owl-nav {
		.owl-next {
			position: absolute;
			top: 50%;
			right: 8px;
			margin-top: -1.65em;
		}
		.owl-prev {
			position: absolute;
			top: 50%;
			left: 8px;
			right: -1.5em;
			margin-top: -1.65em;
		}
	}
	&.owl-carousel:hover .owl-nav button {
		background: rgba(255, 255, 255, 0.75) !important;
		border: 1px solid transparent !important;
		border-radius: 3px;
		color: $black !important;
	}
	.owl-nav button {
		display: block;
		font-size: 1.3rem !important;
		line-height: 2em;
		border-radius: 3px;
		width: 3rem;
		height: 3rem;
		text-align: center;
		border: 1px solid transparent !important;
		background: $white-1 !important;
		z-index: 99;
		color: $black !important;
	}
}

@media (max-width: 767px) {
	.tp-bg1 {
		display: none;
	}
	.owl-fade .banner-text {
		position: relative;
	}
	.tp-bg,
	.banner-tp-bg {
		background: $black-4;
	}
	.banner-text .container {
		padding-left: 0;
		padding-right: 0;
	}
}

@media (min-width: 767px) {
	.owl-fade .banner-text {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
	.banner-tp-bg {
		display: flex;
	}
	.tp-bg {
		position: relative;
		width: 40%;
	}
	.tp-bg1 {
		position: relative;
		width: 20%;
	}
}


/*Detail-page*/

.details-tab-content {
	border-radius: 0 5px 0px 0px;
}

.details-tab {
	border-radius: 0 0px 5px 5px !important;
}

@media (min-width: 992px) {
	.banner-slider div.item {
		height: 700px;
	}
}

@media (max-width: 1024px) and (min-width: 767px) {
	.banner-slider div.item {
		height: 450px;
	}
}

.banner-slider {
	position: relative;
	overflow: hidden;
	z-index: 1;
	&.banner-height {
		height: auto;
	}
	.slider-img {
		height: 100%;
		width: 100%;
		position: absolute;
		background-size: cover;
	}
}

.main-body,.horizontalMenucontainer {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: relative;
}
.wideget-user-desc {
    text-align: center;
}
.media .card-aside-img img {
    width: 80px;
    height: 80px;
}
.construction-image.main-body {
	justify-content : center;
}
.error-image.main-body {
	justify-content : center;
}
.first-location-input{
	padding-left:6.5rem;
}
.img-absolute-top{
	position: absolute;
    width: 300px;
    top: -66px;
    transform: rotate(45deg);
    z-index: -2;
    left: -150px;
    opacity: 0.3;
}
.img-absolute-top2{
	position: absolute;
    width: 300px;
    top: -6rem;
    z-index: 1;
    left: -157px;
    opacity: 0.7;
	&.img-absolute-top3{
		top:0;
	}
}
.property-card{
	position:relative;
	.hover-properties{
		color: $white;
		top: auto !important;
		bottom: -100px;
		padding: 20px;
		height: 50px !important;
		line-height: 30px;
		padding: 10px;
		background: $black-2;
		opacity:0;
		transition:all 0.5s;
	}		
	&:hover .hover-properties{
		bottom:0;
		opacity:1;
		transition:all 0.5s;
	}
	&:hover{
		.category-card{
			margin-top:-75px;
			transition:all 0.5s;
		}
	}
	
}
.category-card{
		margin: 0 15px;
		margin-top: -50px;
		width: 90%;
		z-index: 9;
		transition:all 0.5s
}
.special-property-offer{
	.special-property-offer-img{
		border-radius:8px;
		border:1px solid #eff2f7;
		overflow:hidden;
	}
	.special-property-offer-details{
		margin-left:-90px;
		margin-top:24px;
	}
}
@media (max-width: 767px){
	.special-property-offer{
		.special-property-offer-details{
			margin-left:0;
			margin-top: -20px;
		}
	}
}
.main-shift-img{
	position:reltive;
	&:before{
		content:'';
		width:200px;
		height:200px;
		display:block;
		top:30px;
		left:-50px;
		border-radius:50%;		
		position:absolute;
		opacity:0.15;
	}
	&.main-shift-img2{
		&:before{
			content:'';
			display:none;
		}
	}
}
.testimonia{
	position:relative;
	&:before{
		position: absolute;
		bottom: -13px;
		left: 12px;
		display: inline-block;
		border-right : 12px solid transparent;
		border-bottom: 12px solid #eff2f7;
		border-left : 12px solid transparent;
		border-bottom-color: #eff2f7;
		content: '';		
		transform: rotate(180deg);
	}
	&:after{
		position: absolute;
		bottom: -12px;
		left: 12px;
		display: inline-block;
		border-right : 12px solid transparent;
		border-bottom: 12px solid #fff;
		border-left : 12px solid transparent;
		content: '';		
		transform: rotate(180deg);
	}
}

.absolute-link2{
	position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    z-index: 9;
}
.location.item-card .item-card-desc{
	h5{
		display:none;
	}
	.item-card-text::before {
		background: $white-1;
	}
}
.location.item-card:hover .item-card-desc{
	.item-card-text::before {
		left:-30px;
		-webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
		transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
	}
}
footer{
	.select2-container--default .select2-selection--single{
		border: 1px solid $white-1 !important;
		background:rgba(255, 255, 255, .08) !important;
		border-radius: 8px !important;
		.select2-selection__rendered{
			color:$white-8 !important;
		}
	}
}
.cat-item-bg{
    width: 50px;
    height: 50px;
    line-height: 54px;
    text-align: center;
    border-radius: 5px;
	img{
		width: 40px !important;
		height: 40px !important;
		opacity: 0.7;
	}
}
ul.work-steps{
	padding-left:60px;
	position:relative;	
	&:before{
		content:'';
		width:1px;
		height:100%;
		background:#e4e8fb;
		display:block;
		position:absolute;
		left:20px;
		top:20px;
		z-index:0;
	}
	li{		
		position:relative;
		margin-bottom:25px;		
		.step-number{
			position: absolute;
			left: -60px;
			width: 40px;
			height: 40px;
			border-radius: 8px;
			line-height: 40px;
			font-size: 18px;
			text-align: center;
			font-weight: 500;
		}
	}
}
a.view-link{
	position:relative !important;
}
*::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    transition: .3s background;
}

::-webkit-scrollbar-thumb,
*:hover::-webkit-scrollbar-thumb {
    background: #e1e6f1;
}
.banner-absolute-section{
	position: absolute;
    bottom: -15rem;
    width: 100%;
}
.owl-carousel.banner-carousel-style{
	.card{
		background:#fff !important;
		border:0px solid rgba(255,255,255,0.2) !important;
		overflow:hidden;
	}
	.banner-carousel-item{
		.cat-item-bg{
			opacity:1;
			width:140px;
			height:140px;
			border-radius:0;
		}
	}
	.owl-nav button{
		background: rgba(15, 20, 41, 0.1) !important;
		border: 0px solid rgba(255, 255, 255, 0.15) !important;
		font-size: 1.5rem !important;
		color: #fff !important;
		top: 58%;
		box-shadow:none;
		transition:all 0.5s;
		&.owl-prev{
			left:-50px;
		}
		&.owl-next{
			right:-50px;
		}
		span{
			line-height:2.2;
		}
	}
	&:hover{
		.owl-nav button{
			background:rgba(15, 20, 41, 0.2) !important;
			border:0px solid rgba(255,255,255,0.15) !important;
			color:$white !important;
			transition:all 0.5s;
			&.owl-prev{
				left:-30px;
			}
			&.owl-next{
				right:-30px;
			}
		}
	}
}
.item-card{
	position:relative;
	.item-card-text-bottom{
		position: absolute;
		bottom: -28px;
		padding: 15px 30px;
		background: #fff;
		right: 0;
		font-size: 18px;
		border-radius: 8px 0 8px 0;
		z-index:1;
		transition:all 0.3s;
		h4{
			font-size:16px;
			font-weight:500;
		}
		h5{
			font-size: 13px;
			margin-top: 10px;
		}
	}
	&:hover{
		.item-card-text-bottom{
			bottom:0;
			transition:all 0.3s;
		}
	}
}

.rental-image{
	width:5rem;
	height:5rem;
}

.offer-card{
	position:relative;
	border-radius:8px;
	overflow:hidden;
	cursor:pointer;
	img{
		width:100%
	}
	&:before{
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		right: 0;
		display: block;
		z-index: 1;
		top: 0;		
	}
	.offer-absolute{
		position: absolute;
		z-index: 2;
		color: #fff;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 80%;
		height: 84%;
		margin: 10%;
		border: 0px solid rgba(255, 255, 255,0.82);		
		border-radius: 8px;
		.offer-value{
			position:absolute;
			left:0;
			top:32%;
			right:0;
			margin:0 auto;
			text-align:center;
		}
	}
}

.category-image-shape{
	position:relative;
	&:before{
		content: '';
		position: absolute;
		width: 80px;
		height: 80px;
		border-radius: 26% 74% 73% 27% / 30% 49% 51% 70%;		
		display: block;
		top: 24px;
		left: 21px;
	}
}

.country-shape-image{
	width: 100px;
	height: 100px;
	line-height: 100px;
	border-radius: 26% 74% 73% 27% / 30% 49% 51% 70%;
	img{
		width:80%;
	}
}

.shape-banner {
	position: absolute;
	pointer-events: none;
	right: 0;
	bottom: -4px;
	left: 0;
}

.shape-banner > svg {
	-webkit-transform: scale(2);
	transform: scale(2);
	width: 100%;
	height: auto;
	-webkit-transform-origin: top center;
	transform-origin: top center;
}

.view-btn{
	width: 40px;
    height: 40px;
    border-radius: 50%;
    line-height: 42px;
    display: block;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
	background:#f2f3f8;	
}

.status-img-circle{
	width:100px;
	height:100px;
	border-radius:50%;
	line-height: 100px;
    text-align: center;
	background:#e9ecfc;
	img{
		width:60px;
		height:60px;
	}
	.svg-country-img{
		width:60px;
		height:60px;		
	}
}

.icon-style-circle{
	width: 1.6rem;
    height: 1.6rem;
    line-height: 1.6rem !important;
    border-radius: 7px;
    background: #ececf3;
    display: inline-block;
    margin: 0 auto;
    text-align: center;
}

.icon-style-circle-lg{
	width: 2.3rem;
    height: 2.3rem;
    line-height: 2.3rem !important;
    border-radius: 7px;
    background: #ececf3;
    display: inline-block;
    margin: 0 auto;
    text-align: center;
	font-size:1rem;
}

.social-icon-style-circle-lg{
	width: 2.3rem;
    height: 2.3rem;
    line-height: 2.5rem !important;
    border-radius: 7px;
    background: #ececf3;
    display: inline-block;
    margin: 0 auto;
    text-align: center;
	font-size:1rem;
}

.gallery-close-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;   
    position: absolute;
    display: block;
    right: -15px;
    top: -15px;
    z-index: 9;
    line-height: 43px;
    cursor: pointer;
	margin:0 auto;
	text-align:center;
}

#gallery {
	.carousel-control-next, .carousel-control-prev{
		width:8%;
	}
	img{
		border-radius:7px;
	}
}

.spcl-cat-card{
	.spcl-hover-icon{
		position: absolute;
		left: 0;
		right: 0;
		top: 47%;
		font-size: 26px;
		opacity:0;
		color:$white;
		cursor:pointer;
		z-index:99;
		
	}
	&:hover{
		.spcl-hover-icon{
			opacity:1;			
		}
		.item-card .item-card-desc:before{
			border-radius: 8px 8px 0 0;
		}
	}
}

.sub-newsletter{
	i{
		width: 3.5rem;
		height: 3.5rem;
		line-height: 3.4rem;
		border-radius: 8px;
		display: inline-block;
		text-align: center;
		font-size: 20px;
	}
}

.full-height-banner{
	.axgmap{
		height:calc(100vh - 112px);
		margin-top:65px;
	}
}

.map-content-width .vscroll{
	height:calc(100vh - 182px);
	margin-top:135px;
}

.map-content-search{
	position: fixed;
    width: inherit;
    z-index: 99;
    margin-top: 64px;
    background: #ffffff;
    border-top: 1px solid $border-color;
	border-bottom: 1px solid $border-color;
	top: 46px;
}

.video-btn1 span {
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 1.5rem;
    background: rgba(40, 47, 63, 0.5);
    margin: 0 auto;
    text-align: center;
    top: 40%;
    position: absolute;
    left: 0;
    right: 0;
}

.details-tab .ytp-chrome-top{
	display:none !important;
}

.timeline-reply-data{
	position:relative;
	&:before{
		position: absolute;
		top: -9px;
		left: 12px;
		display: inline-block;
		border-right : 8px solid transparent;
		border-bottom: 8px solid $border-color;
		border-left : 8px solid transparent;
		border-bottom-color: rgba(0, 0, 0, 0.2);
		content: '';
	}
	&:after {
		position: absolute;
		top: -8px;
		left: 12px;
		display: inline-block;
		border-right : 8px solid transparent;
		border-bottom: 8px solid #fff;
		border-left : 8px solid transparent;
		content: '';
	}
}

.location-category-list{
	li{
		a{
			padding:10px 20px;
			border-bottom:1px solid $border-color;
			display:block;
		}
		&:nth-of-type(even){
			a{
				background:rgba(241, 238, 247, 0.3);
			}
		}
	}
}

.location-category-list li:last-child a {
	border-bottom: 0 !important;
}

.widget-hr{
	border-color: #d7d7e0;
	margin-top:1rem;
	margin-bottom:2rem;
	border:0px;
	background:#d7d7e0;
	opacity:1;
}

.near-property{
	padding: 4px 6px;
    position: absolute;
    top: 16px;
	left: 16px;
    display: flex;
    z-index: 9;
    background: #d80505;
	color:$white;
    border-radius:5px;
    font-size: 12px;
	line-height: 16px;
	overflow: hidden;
	img{
		width:14px;
		height:14px;
		margin-right:3px;
		border-radius: 50%;
	}
}

.location-pulse {
	animation: pulse-animation 1s infinite;
	overflow:hiddem;
}

.form-icon-date{
	cursor: pointer;
	height: 20px;
	line-height: 33px;
	position: absolute;
	left: 12px;
	text-align: right;
	top: 7px;
	background: $white;
	width: 15px;
	color: #a7b4c9;
	opacity:0.8;
	cursor:pointer;
	font-size:16px;
}

.location-gps {
	cursor: pointer;
    line-height: 24px;
    position: absolute;
    left: 12px;
    text-align: right;
    top: 10.5px;
    background: #ececf3;
    cursor: pointer;
    display: flex;
    padding: 1px 6px;
    border-radius: 50px;
	.location-gps-img{
		width: 15px;
	}
	span{
		color: $color;
    	margin-left: 4px;
	}
}

.loader-location{
	cursor: pointer;
    line-height: 24px;
    position: absolute;
    left: 0;
    text-align: right;
    top: 0;
    cursor: pointer;
    display: flex;
    padding: 1px 6px;
    border-radius: 50px;
}

.location-loader{
	.loader-location{
		display: none;
	}
	&.active{
		.location-gps{
			display: none;
		}
		.loader-location{
			display: block;
		}
	}
}

.calendar-form-position{
	cursor: pointer;
	height: 20px;
	line-height: 33px;
	position: absolute;
	left: 12px;
	text-align: right;
	top: 16px;
	background: $white;
	width: 15px;
	color: #a7b4c9;
	opacity:0.8;
	cursor:pointer;
}

.card-category-hover{
	transition: all 0.3s;
	&:hover{
		background: #fcfcfc;
		box-shadow: 0 0.75rem 3rem rgba(18, 38, 63, 0.2);
		transition: all 0.3s;
	}
}

.dataTables_wrapper .dataTables_filter input{
	border-color:$border-color !important;
}

.dataTables_length .select2-container--default .select2-selection--single{
	border-radius:8px !important;
}

.propert-list-gallery{
	display: flex;
	.carousel-control-prev{
		width:40px;
		height: 40px;
		background: rgb(42, 48, 66);
		border-radius: 8px;
		top: 42%;
		left: 10px;
		transform: none;
	}
	.carousel-control-next{
		width:40px;
		height: 40px;
		background: rgb(42, 48, 66);
		border-radius: 8px;
		top: 42%;
		right: 10px;
		transform: none;
	}
	.thumb-propert-list-gallery{
		width:178px;		
		.thumb-propert-list-img{
			img{
				margin: 0 0 2px 2px;
				cursor: pointer;
			}
			.carousel-control-prev{
				display: none;
			}
			.carousel-control-next{
				display: none;
			}
		}
	}
}

@media (max-width:1300px){
	.header-main .top-bar-end .custom li a{
		span{
			display:none;
		}
	}
}

@media (max-width:767px){
	.fc .fc-toolbar{
		display:block !important;
		.fc-toolbar-title {
			font-size: 1.25em;
			font-weight:600;			
		}
		.fc-toolbar-chunk{
			width:100%;
			margin-bottom:15px;
			&:last-child{
				text-align:left !important;
			}
		}
	}
	.special-property-offer-img img{
		width:100%;
	}
}

@media (min-width:992px) and (max-width:1300px){
	.wideget-user-tab.wideget-user-tab3 .tabs-menu1 ul li a{
		padding: 10px 10px 8px 10px !important;
	}
}

@media (max-width:992px){
	.banner-absolute-section{
		position:relative !important;
		margin-top: 5rem;
		border-radius:8px;
		bottom:0 !important;
	}
	.offer-card{
		margin-top:3rem;
	}
	.bg-half-50{
		position:absolute;
		right:0;
		width:100%;
		height:50%;
		left:0;
		top:50%;
		bottom:0;
		z-index:0;
	}
}

@media (min-width:992px){
	.gallery-content-width{
		width:500px;
	}
	.bg-half-50{
		position:absolute;
		right:0;
		width:50%;
		height:100%;
		top:0;
		bottom:0;
		z-index:0;
	}
}

.demo-gallery{
	.owl-nav {
		.owl-prev{
			left:25px !important;
		}
		.owl-next{
			right:25px !important;
		}
	}
}

/*-- IE Issues ---*/

.page-h,
 ::-ms-backdrop {
	height: 100%;
}

.position-absolute,
 ::-ms-backdrop {
	position: absolute !important;
}

@media (min-width: 992px) {
	*::-ms-backdrop, .item2-gl-nav label{
		display: inline-table;
	}
	*::-ms-backdrop, .cover-image.products-1{
		width:248px;
		height:189px;
	}
	*::-ms-backdrop, .card-img-top.card-img-top1{
		height:175px;
	}
}

.media-body,
.media-left,
.media-right,
 ::-ms-backdrop {
	display: list-item;
	vertical-align: top;
}

.media-left,
.media-right,
.table-responsive .media-body,
 ::-ms-backdrop {
	display: table-cell;
}

.custom-control-label,
 ::-ms-backdrop {
	position: static;
}
*::-ms-backdrop, .location-gps .location-gps-img{
	height:100%;
	margin-top:5px;
}
*::-ms-backdrop, .first-location-input{
	border-top:0;
}
*::-ms-backdrop, .ie-h100{
	height:100% !important;
}
*::-ms-backdrop, .ie-h82{
	height:82% !important;
}
*::-ms-backdrop, .page-header{
	line-height:50px;
}
.iteam-all-icon,
 ::-ms-backdrop {
	height: 100%;
}
.breadcrumb-3 li,
 ::-ms-backdrop,
.breadcrumb-4 li,
 ::-ms-backdrop,
.breadcrumb-1 li,
 ::-ms-backdrop,
.breadcrumb-2 li,
 ::-ms-backdrop {
	display: inline-block;
	text-align: center;
}
*::-ms-backdrop, .item7-card-img{
	height: auto;
}
*::-ms-backdrop, .card{
	 align-items: stretch;
	 max-width:100%;
}
*::-ms-backdrop, .d-catmb .h73{
	height:65px !important
}
*::-ms-backdrop, .d-catmb .w73{
	width:120px !important
}
*::-ms-backdrop, .bannerimg.bg-background3, .banner-1.bg-background2, .sptb-2.sptb-tab.bg-background2  ,.ie-margin-top{
	margin-top:-8px !important;
}
*::-ms-backdrop,  .sptb-2.sptb-tab.bg-background2.mt-0{
	margin-top:0 !important;
}

@media (min-width:767px){
	*::-ms-backdrop, .demo-gallery.mt-lg-8{
		margin-top:3rem !important;
	}
}
@media (min-width:767px){
	*::-ms-backdrop, .ie-image img{
		height:250px;
	}
	*::-ms-backdrop, .ie-image2 img{
		height:200px;
	}
	*::-ms-backdrop, .ie-image3 img{
		height:170px;
	}
}
*::-ms-backdrop, .direct-chat-msg.right{
	flex-direction:row;
	direction:ltr;
	margin-top:30px;
}

*::-ms-backdrop, .timeline-property .timeline-data .media-body{
	display:block;
}

@media (min-width:768px) and (max-width:991px){
	.top-bar .top-bar-end {
		border-right :1px solid #eff2f7 !important;
	}
	.header-main-color {
		.top-bar-start ul {
			border-right :1px solid rgba(255, 255, 255, 0.1) !important;
		}
		.top-bar .top-bar-end {
			border-right :1px solid rgba(255, 255, 255, 0.1) !important;
		}
		.top-bar .top-bar-start .socials li:last-child a {
			border-right: 0;
		}
	}
}
.user-tabel{
	.card-table tr:first-child {
		td, th {
		  border-top: 1px solid #e8ebf3;
		}
	  }
}

@media  (max-width:408px){
	.chat-message-popup {
		right: 0 !important;
	}
	.banner-1 .header-text h1 {
		font-size: 30px !important;
	}
	.banner-1 .header-text h1 .fs-40 {
		font-size: 30px !important;
	}
}

@media  (max-width:991px){
	#container3 {
		&.closed {
			&:before {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				bottom: 50px;
				z-index: 1;
				pointer-events: none;
			}
		}
		.items-gallery{
			.showmore-button-inner {
				margin-top: 0px;
				position: relative;
				top: 20px;
			}
		}
	}
}

@media  (max-width:580px){
	.owl-carousel-icons41{
		&.owl-carousel .owl-item img {
			display: initial;
		}
	}
}
@supports (-webkit-touch-callout: none) {

	@media screen and (max-device-width: 991px) and (orientation: portrait) {
		body.active .horizontalMenucontainer {
			position: absolute;
		}
	
		body .horizontalMenucontainer .header-absolute {
			position: absolute;
			width: 100%;
		}
		body.res-header.active .horizontalMenucontainer  {
			position: initial;
		}
		body.res-header .horizontalMenucontainer  {
			position: relative;
		}
	
		body.modal-open .horizontalMenucontainer {
			position: relative;
		}
		.banner1.banner-1 {
			margin-top:55px;
		}
		.error-image.main-body {
			justify-content: initial;
		}
		dd{
			display: block !important;
		}
	}
	
	@media screen and (max-device-width: 991px) and (orientation: landscape) {
		body.active .horizontalMenucontainer {
			position: absolute;
		}
	
		body .horizontalMenucontainer .header-absolute {
			position: absolute;
			width: 100%;
		}
		body.res-header.active .horizontalMenucontainer  {
			position: initial;
		}
		body.res-header .horizontalMenucontainer  {
			position: relative;
		}
	
	
		body.modal-open .horizontalMenucontainer {
			position: relative;
		}
		.banner1.banner-1 {
			margin-top:55px;
		}
		.error-image.main-body {
			justify-content: initial;
		}
		dd{
			display: block !important;
		}
	}
	input {
		-webkit-appearance: none;
	}
	
	textarea {
		-webkit-appearance: none;
	}
	a[href^="tel"] {
		color: inherit;
		text-decoration: none;
	}
	
}

.side-menu li a.active:before {
	border-radius: 35px;
}
@media (min-width: 992px) {
	.sticky-wrapper.is-sticky .horizontal-main {
		padding-top:0 !important;
	}
}
.top-bar .dropdown-menu {
	margin-top : -3px !important;
}
.ads-tabs .data-table-example th:nth-child(2) {
	width:100% !important;
}
.owl-carousel-icons4 {
	.item {
		.card-category-hover {
			.cat-item .cat-img-circle {
				padding: 10px;
			}
		}
	}
}

.timeline-property {
	.timeline-data {
		a {
			&.btn {
				margin-bottom:0.5rem;
			}
		}
	}
}
.item-card9-img {
	#image-slider ,#image-slider2{
		.carousel-control-next-icon {
			background-image: none;
		}
		.carousel-control-prev-icon {
			background-image: none;
		}
	}
}
@media (max-width: 382px)  {
	.item2-gl {
		.item2-gl-nav {
			.d-flex {
				display:block !important;
				.selectgroup {
					display: block;
					label {
						width: 100%;
						.selectgroup-input:checked + .selectgroup-button {
							border-radius: 8px !important;
						}
						&.selectgroup-item:not(:last-child) .selectgroup-button {
							border-radius: 8px !important;
						}
						&.selectgroup-item:not(:first-child) .selectgroup-button {
							border-radius: 8px !important;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 400px)  {
	#collapseFive1 .timeline-property li.d-flex {
		display: block !important;
	}
	
	#collapseFive1 .timeline-property .timeline-data {
		margin-left:0  !important;
		margin-right:0 !important;
	}
}

@media (max-width: 407px)  {
	.sw-toolbar-bottom{
		.sw-btn-group {
			margin-bottom: 0.5rem;
		}
	}
}


.lg-outer.lg-start-zoom.lg-use-css3.lg-css3.lg-slide.lg-grab {
	display: block;
}

@media (max-width: 991px) {
	dd {
		display: inline-block !important;
	}
}

.header-white {
	display: none;
}

.spcl-cat-card {
	.item-card {
		border-radius: 8px 8px 0 0;
	}
}

.header-main-color .top-bar .top-bar-start ul li:first-child a {
	border-left: 0;
}

.custom-control-input:focus ~ .custom-control-label::before {
	box-shadow:none !important;
}

.modal-open {
    overflow: auto !important;
}

.header-message-dropdown {
	strong {
		word-break: break-all;
	}
}

.header-message-dropdown {
    white-space: normal;
    width: 220px;
}

.layout-setting {
	.light-layout {
		display: none;
	}
}

.header-message .dropdown-menu {
    min-width: 18rem;
}

.header-notification .dropdown-menu {
    min-width: 18rem;
}

.search3.bg-transparent {
	.select2-lg .select2-container--default .select2-selection--single {
		border-left: 0 !important;
	}
}

.bg-primary {
	.clearfix .contact {
		&:first-child {
			.select2-container--default .select2-selection--single .select2-selection__arrow b {
				border-color: $white transparent transparent transparent;
			}
		}
	}
}

.manged-ad, .ads-tabs, .my-favadd, .my-orders, .payment-invoice  {
	.select2-container--default .select2-selection--single .select2-selection__arrow {
		height: 19px;
	}
}

.authors {
	.vertical-scroll {
		height: 179px !important;
	}
}



// ratings styles //

.rating-stars i {
    padding: 5px 8px;
	font-size: 30px;
}

.rating-stars {
    display: inline-block;
    cursor: pointer;
}

.rating-star.rating1 {
	.jq-star {
		width: 18px !important;
    	height: 18px !important;
	}
}

.jq-stars {
    display: inline-block;
}

.jq-rating-label {
    font-size: 22px;
    display: inline-block;
    position: relative;
    vertical-align: top;
    font-family: helvetica, arial, verdana;
}

.jq-star {
    width: 100px;
    height: 100px;
    display: inline-block;
    cursor: pointer;
}

.jq-star-svg {
    padding-left: 1px;
    width: 100%;
    height: 100%;

    path {
        stroke-linejoin: round;
    }
}

.jq-shadow {
    -webkit-filter: drop-shadow(-2px -2px 2px #888);
    filter: drop-shadow(-2px -2px 2px #888);
}

// ratings styles //

.footerimg-r {
	span {
		display: inline-block;
	}
}

.color-nav .nav-link.active {
    background-color: transparent;
}

.badge-dark-transparent {
	color: $white;
	background: $black-2;
}

.tab_wrapper.first_tab>ul li {
    border: 1px solid #ededf5;
    border-top: 1px solid #ededf5;
    border-bottom: 0;
    color: $primary;
    background: $primary-1;
}

.tab_wrapper.first_tab .content_wrapper {
    margin-top: -6px;
}

.note-editor.note-frame.panel.panel-default .panel-heading a{
	background-color: transparent;
}

.rating-star .jq-star {
    width: 15px !important;
    height: 15px !important;
}

.header-main.header-main-absolute .top-bar-end .custom li a {
    border-left-color: $white-1;
}

.header-main.header-main-absolute .top-bar .top-bar-start .socials li a {
    border-left-color: $white-1;
}

.top-bar {
	.dropdown-menu-arrow {
		&:before, &:after {
			top: -10px;
		}
	}
}

.header-main-color .top-bar .dropdown-icon {
    color: $primary;
}

.propert-list-gallery {
	.slider-wrap {
		display: none;
	}
}

.thumb-propert-list-gallery {
	.thumb {
		margin: 0;
	}

	.carousel-item-next, .carousel-item-prev, .carousel-item.active {
		display: block;
	}
}

#image-slider, #image-slider2 {
	.carousel-control-next, .carousel-control-prev {
		transform: none;
	}
}

.input-group.input-indec {
	width: 125px !important;
}

@media (min-width: 481px) and (max-width: 1200px) {
	.table-responsive.userprof-tab .media {
		display: block !important;
	}
}
.banner1.banner-1 .owl-carousel .owl-item img{
   width:100%;
}
.zindex-10 {
    z-index: 10;
}
