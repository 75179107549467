/*------ Headers ------*/

.header {
	padding-top: .75rem;
	padding-bottom: .75rem;
	width: 100%;
	z-index: 100;
}

body.fixed-header .header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1030;
}

@media print {
	.header {
		display: none;
	}
}

.header {
	.dropdown-menu {
		margin-top: 0;
	}
	.mega-menu {
		width: 350px;
	}
}

.nav-unread {
	position: absolute;
	top: .1rem;
	right: .4rem;
	background: #f18f13;
	width: .5rem;
	height: .5rem;
	border-radius: 50%;
	&.badge {
		position: absolute;
		top: 0;
		text-align: center;
		right: 0;
		width: 1.2rem;
		height: 1.2rem;
		border-radius: 50%;
		margin: 0 auto;
		text-align: center;
		padding: 0;
		font-size: 11px;
		line-height: 1.3rem;
	}
}

.nav-link.icon {
	margin: 5px;
    border-radius: 50%;
    padding: 0;
    width: 2.5rem;
    text-align: center;
    height: 2.5rem;
    font-size: 15px;
    position: relative;
    text-align: center;
    border-radius: 7px;
    background: #ececf3;
    font-size: 18px;
    line-height: 2.5rem;
	i {
		margin: 0 auto;
    	text-align: center;
	}
}

.header-brand {
	color: inherit;
	margin-right: 1rem;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
}

.app-header .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 200px;
	text-align: center;
	.header-brand-img {
		margin-right: 0;
	}
}

.app-header1 .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 215px;
	text-align: center;
	margin-top: -1.5px;
	.header-brand-img {
		margin-right: 0;
	}
}

.header-brand:hover {
	color: inherit;
	text-decoration: none;
}

.header-brand-img {
	height: 2.5rem;
	line-height: 2rem;
	vertical-align: bottom;
	margin-right: .5rem;
	width: auto;
}

.header-avatar {
	width: 2rem;
	height: 2rem;
	display: inline-block;
	vertical-align: bottom;
	border-radius: 50%;
}

.header-btn {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	font-size: 1rem;
	&.has-new {
		position: relative;
		&:before {
			content: '';
			width: 6px;
			height: 6px;
			background: $danger;
			position: absolute;
			top: 4px;
			right: 4px;
			border-radius: 50%;
		}
	}
}

.header-toggler {
	width: 2rem;
	height: 2rem;
	position: relative;
	color: $white;
	&:hover {
		color: $white;
	}
}

.header-toggler-icon {
	position: absolute;
	width: 1rem;
	height: 2px;
	color: inherit;
	background: currentColor;
	border-radius: 3px;
	top: 50%;
	left: 50%;
	margin: -2px 0 0 -.5rem;
	box-shadow: 0 5px currentColor, 0 -5px currentColor;
}

.header {
	.nav-item .badge,
	.nav-link .badge {
		position: absolute;
		top: 0;
		right: -1px;
		padding: .2rem .25rem;
		min-width: 1rem;
		font-size: 10px;
		line-height: 0.8rem;
	}
}

#headerMenuCollapse {
	.nav-item .badge,
	.nav-link .badge {
		position: relative;
		min-width: 1rem;
		font-size: 10px;
		font-weight: 500;
	}
}

.header-main .social-icons {
	float: right;
	li {
		color: #212529;
		margin-right: 35px;
		display: inline-block;
		list-style: none;
		font-size: 20px;
		a {
			color: #212529;
		}
	}
}

.header-search {
	padding: 1.5rem 1.5rem;
	background: $white;
	.header-icons {
		.header-icons-link {
			display: flex;
			margin-bottom: 0;
			li {
				a {
					display:inline;
					i{
						background: #f0f2fd;
						width: 30px !important;
						height: 30px !important;
						border-radius: 5px;
						text-align: center;
						line-height: 30px;
						margin-right: 0px;
						display:inline-block;
					}
				}
				.dropdown-menu{
					a{
						display: block;
						i{
							background: transparent !important;
							width: auto !important;
							height: auto !important;
							line-height:20px;
							margin-right: 5px;
							margin-left: 0;
						}
					}
				}
			}
			.header-icons-link1,
			.header-icons-link2 {
				width: 2.5rem;
				text-align: center;
				height: 2.5rem;
				font-size: 16px;
				position: relative;
				
			}
		}
		.header-icons-link1 .main-badge1 {
			position: absolute;
			top: -9px;
			right: -15px;
			text-align: center;
			font-size: 10px;
		}
		.header-icons-link.icons li {
			background: $white;
		}
	}
}

.top-bar {
	border-bottom: 1px solid #e8ebf3;
	background: $white;
}

.top-bar-start ul {
	margin-bottom: 0;
}

.top-bar {
	i{
		font-size: 14px;
		line-height: 26px;
	}
	.top-bar-end {
		float: right;
		display: flex;
		border-right : 1px solid #eff2f7;
	}
	.top-bar-start {
		display: flex;
		.socials li a{
			display: inline-block;
			font-size: 15px;
			padding: 9px 15px;
			margin: 0;
			border-left: 1px solid #eff2f7;
		}
		.contact li{
			display:inline-block;
			a {
				padding: 9px 15px;
				margin:0;
				display: inline-block;
				color: $color;
				&.dropdown{
					i{
						font-size: 13px;
						color: #a7b4c9 !important;
					}
				}
			}
		}
	}
	.select-country .select2-container.select2-container--default.select2-container--below.select2-container--open {
		width: 150px !important;
	}
}
.top-bar.bg-primary .select2-container--default .select2-selection--single .select2-selection__arrow i{
	color: $white-5;	
}
.header-search {
	.header-inputs .input-group-text.searchicon {
		top: 10px;
		right: 25px;
		position: relative;
	}
	.header-search-logo {
		margin-right: 1rem;
	}
	.header-nav .nav-cart .icon-cart {
		i {
			font-size: 18px;
			color: #a7b4c9;
		}
		a {
			color: #a7b4c9;
		}
	}
}

.header-nav {
	display: flex;
}

.header-links {
	padding: 3px;
	li {
		margin: 5px;
		a {
			padding: 9px 18px;
			border-radius: 3px;
		}
	}
}

.header-main {
	.top-bar .contact {
		margin: 0px 0;
		padding-left: 0px;
		border-left :1px solid #eff2f7; 
		border-right :1px solid #eff2f7; 
		li {
			margin: 0;
			display:inline-block;
		}
	}
	.top-bar-end .custom {
		display: flex;
		padding: 0;
		li {
			margin-right: 0px;
			&:last-child {
				margin-right: 0;
			}
			a{
				padding:9px 13px;
				display:block;
				border-left: 1px solid #eff2f7;
			}
		}
	}
}

.ace-responsive-menu {
	margin-right: 1rem;
}

.header-main {
	.ace-responsive-menu li a {
		color: $color;
		&:hover {
			color: $white;
		}
		&:focus {
			color: $black;
		}
	}
	.post-btn {
		float: right;
		margin-left: auto;
	}
}

.header-main.header-main-color .top-bar-end .custom li a {
    border-left-color: $white-1;
}

.header-menu1 {
	float: none !important;
}

@media (min-width: 992px) and (max-width: 1350px) {
	.header-menu1 .input-group {
		width: 140px;
	}
}

.header-main-banner {
	position: absolute;
	top: 0;
	display: block;
	width: 100%;
	z-index: 99;
	.horizontal-main,
	.horizontalMenu>.horizontalMenu-list {
		background: 0 0;
	}
}

.header-style .horizontalMenu {
	float: inherit !important;
}

.top-bar {
	.select2-dropdown.select2-dropdown--below {
		width: 180px !important;
	}
	.select-country .select2-container {
		width: 150px !important;
	}
}

.header-text1 .text-property {
	position: relative;
	top: -15px;
}
.header-main-absolute{
	.top-bar{
		position: absolute;
		z-index: 101;
		width: 100%;
		background: transparent;
		border-bottom: 1px solid $white-1;
		.top-bar-start ul{
			border-left-color : $white-1;
			&.contact{
				border-right : 1px solid $white-1;
				li .text-dark{
					color:$white-9 !important;
				}
			}
			.select-country .select2-container--default .select2-selection--single .select2-selection__rendered{
				color:$white-9 !important;
			}
		}
		.top-bar-end{
			border-right : 1px solid $white-1;
		}
		.text-muted{
			color:$white-9 !important;
		}
		i{
			color:$white-5;
		}
	}	
	.horizontal-main{
		margin-top:45px;
		transition:all 0.1s;
	}
	.sticky-wrapper.is-sticky{
		top:0;
		.horizontal-main{
			margin-top:0;
			transition:all 0.1s;
		}
	}
}
.header-main-color{
	.top-bar{
		.top-bar-start ul{
			border-left-color : $white-1;
			border-right-color: $white-1;
			&.contact{
				li .text-dark{
					color:$white-9 !important;
				}
			}
			.select-country .select2-container--default .select2-selection--single .select2-selection__rendered{
				color:$white-9 !important;
			}
			li a {
				border-left: 1px solid $white-1 !important;
			}
		}
		.top-bar-end{
			border-left : 0px solid $white-1;
			border-right : 1px solid $white-1;
		}
		.text-muted{
			color:$white-9 !important;
		}
		i{
			color:$white-5;
		}
	}
}
.header-logo{
	display:block;
}

@media only screen and (max-width: 991px) {
	.sticky-wrapper.absolute.banners .horizontalMenu>.horizontalMenu-list>li>a.active {
		background-color: $primary !important;
		color: $white !important;
	}
}

.header .header-right-icons .dropdown-menu {
    margin-top: 8px;
}