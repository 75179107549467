/*----- Item Cards -----*/

.item-card {
	.cardbody {
		position: relative;
		padding: 30px 15px;
		border-radius: 0 0 .2rem .2rem;
	}
	.cardtitle {
		span {
			display: block;
			font-size: .75rem;
		}
		a {
			color: #1f252d;
			font-weight: 500;
			text-decoration: none;
		}
	}
	.cardprice {
		position: absolute;
		top: 30px;
		right: 15px;
		span {
			&.type--strikethrough {
				opacity: .7;
				text-decoration: line-through;
				font-size: 14px;
			}
			display: block;
			color: #1f252d;
			font-size: 18px;
			font-weight: 600;
		}
	}
}

@media (min-width: 993px) and (max-width: 1024px) {
	.item-card2 .icons.text-muted.mr-4,
	.item-card9 .icons.text-muted.mr-4 {
		margin-right: 0.5rem !important;
	}
}

@media (max-width: 1024px) {
	.item-card2 p.leading-tight,
	.item-card9 p.leading-tight {
		-webkit-line-clamp: 1 !important;
	}
}

@media (max-width: 400px) {
	.item-search-menu ul li a {
		width: 100%;
		display: block;
		margin: 0 0 8px 0 !important;
	}
}

@media (max-width: 767px) {
	.item2-gl-nav {
		display: block !important;
		.item2-gl-menu li {
			text-align: center;
			margin-bottom: 10px;
			margin: 0 auto;
			padding: 10px 0;
		}
		label {
			display: block;
			float: none;
			text-align: center;
			width: 30%;
		}
		.d-flex {
			text-align: center;
			width: 100%;
			justify-content: center;
		}
		.form-control.select-sm.w-70 {
			width: 70% !important;
		}
	}
}

@media (max-width: 320px) {
	.item-card7-desc ul li,
	.item-card2-desc ul li {
		font-size: 11px;
	}
	.item-card .item-card-desc .item-card-text {
		top: 2.5rem !important;
	}
	.item7-card-desc a {
		font-size: 11px;
	}
}

.item-box {
	.item-box-wrap {
		margin-top: 15px;
	}
	.stamp i {
		font-size: 18px;
		font-size: 25px;
		line-height: 2;
	}
	.item-box-wrap h5 {
		font-size: 20px;
		font-weight: 600;
	}
}

.item-single .item-single-desc .item-single-desc-list .listunorder {
	border: 0;
	padding: 5px;
	margin-bottom: 0;
	font-size: 15px;
}

.item-card {
	.item-card-desc:before {
		content: '';
		display: block;
		position: absolute;		
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
		z-index: 1;
	}
	overflow: hidden;
	border-radius: 8px;
	position: relative;
	clear: both;
	margin: 0;
	max-width: 1000px;
	list-style: none;
	text-align: center;
}

.item-card-desc {
	position: relative;
	a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
	}
}

.item-card .item-card-desc .item-card-text {
	position: absolute;
	top: 0px;
	left: 0;
	text-align: center;
	width: 100%;
	height: 100%;
	h4 {
		position: relative;
		top: 43%;
		left: 0;
		right: 0;
		text-align: center;
		color: $white;
		z-index: 2;
		font-size: 20px;
	}
	
	span.badge {
		position: absolute;
		top: 58%;
		left: 0;
		right: 0;
		text-align: center;
		color: $white;
		z-index: 2;
		width: 100%;
		height: auto;
		margin: 0 auto;
	}
}
.location-style {
	h4{
		top:37% !important;
	}
	h6 {
		position: relative;
		top: 39%;
		left: 0;
		right: 0;
		text-align: center;
		color: $white-9;
		z-index: 2;
		font-size: 14px;
	}

}
.item-card-text {
	span {
		font-size: 18px;
		margin: .5rem;
		font-weight: 400;
		display: block;
	}
	h4 {
		font-size: 20px;
		font-weight: 500;
	}
}

.item-card {
	.item-card-btn {
		padding: 0;
		margin: 0;
		opacity: 0;
		left: 37.7%;
		text-align: center;
		position: absolute;
		bottom: -30px;
		z-index: 1;
		transition: all .5s ease 0s;
	}
	&:hover {
		.item-card-btn {
			opacity: 1;
			bottom: 25%;
		}
		.data1 .item-card-img img {
			transform: scale(1);
		}
	}
	.item-card-btn a {
		display: block;
		position: relative;
		transition: all .3s ease-in-out;
		&:after,
		&:before {
			transform: translateX(-50%);
			position: absolute;
			left: 50%;
			top: -30px;
		}
		&:after {
			content: '';
			height: 15px;
			width: 15px;
			border-radius: 0;
			transform: translateX(-50%) rotate(45deg);
			top: -20px;
			z-index: -1;
		}
	}
}

.item-card2-icons {	
	position: absolute;
	top: 24px;
	right: 24px;
	z-index: 98;
	&.top-spacing{
		top: 15px;
		right: 15px;
	}
	&.top-spacing-left{
		top: 15px;
		left: 15px;
		width: fit-content;
	}
	a {
		width: 2rem;
		display: inline-block;
		height: 2rem;
		text-align: center;
		border-radius: 100px;
		line-height: 2.19rem;
		border-radius: 8px;
		color: $white;		
	}
}

.item-list .list-group-item {
	border: 0;
	margin-bottom: 0;
	border-bottom: 1px solid #e8ebf3;
}

.item-card2-icons a:hover {
	color: $white !important;
}

.item-list .list-group-item i {
	margin-right: 5px;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
}

.item-user .item-user-icons a {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	text-align: center;
	border-radius: 100px;
	line-height: 2rem;
	margin-top: .3rem;
	&:hover {
		color: $white;
	}
}

.item-card3-img img {
	width: 100%;
	height: 100%;
	max-height: 200px;
	margin: 0 auto;
	position: relative;
	margin-bottom: -35px;
}

.item-card3-desc {
	img {
		border: 3px solid $white;
	}
	h4 {
		position: relative;
		top: 5px;
	}
}

.item-card4-img a {
	position: absolute;
	top: 35px;
	left: 30px;
}

.item-card5-img a {
	position: absolute;
	right: 12px;
	top: 15px;
}

.item-card5-icon a {
	width: 2rem;
	height: 2rem;
	display: inline-block;
	text-align: center;
	border-radius: 100px;
	line-height: 2rem;
	border-radius: 50px;
	background: $background;
	color: #212529;
}

.item-card6-img img {
	width: 100%;
	height: 100%;
	max-height: 200px;
	margin: 0 auto;
}

.item-card6-footer .item-card6-body {
	padding: 10px;
}

.item-search-tabs {
	margin-top: 2rem;
	.item-search-menu ul {
		padding: 0;
		margin-bottom:20px;
		position:relative;
		&:before{
			content: '';
			position: absolute;
			width: 100%;
			height: 1px;
			border: 0;
			bottom: 0;
			background:rgba($white,0.1);
		}
	}
}

.item-search-menu ul li {
	.active {
		background: transparent;
		color: $white;
		border-radius: 3px 3px 0 0;
		position:relative;
		&:before{
			content: '';
			position: absolute;
			width: 100%;
			height: 2px;
			border: 0;
			bottom: 0;
			left: 0;
			right: 0;			
		}
	}
	a {
		padding: 12px 15px;
		color: $white;
		text-align: center;
		letter-spacing: .5px;
		background: 0 0;
		margin: 0 5px;
		border-radius: 3px 3px 0 0;
		display:block;
		font-size: 14px;
		text-transform: uppercase;
		font-weight: 400;
	}
}

.item-search-tabs {
	.tab-content {
		border: 1px solid $black-1;
		background: $black-4;
		padding: 15px;
		margin: 0 0;
		border-radius: 2px;
	}
	&.travel-content {
		.select2-container {
			width: 100% !important;
		}
	}
	.form-control.border-white {
		border: 1px solid $white !important;
	}
}

.item-all-cat {
	.item-all-card {
		position: relative;
		padding: 1.5rem 1.5rem;
		border-radius: 4px;
		margin-bottom: 1.5rem;
		color: $white;
		border: 1px solid #e8ebf3;
	}
	.category-type .item-all-card img {
		width: 7rem;
		height: 7rem;
		border-radius: 50%;
		padding: 2.3rem 0;
	}
	.row .item-all-card {
		margin-bottom: 1.5rem;
		box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.1), 0 2px 9px 0 rgba(62, 57, 107, 0.1);
	}
	.item-all-card a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}

.iteam-all-icon i {
	font-size: 1.5rem;
}

.item-all-card img.imag-service {
	width: 40%;
}

.item-card7-overlaytext {
	position: relative;
}

.item-card7-imgs .bdir {
	position: absolute;
	top: 12px;
	left: 12px;
	padding: 3px 7px;
	z-index: 2;
}

.item-card7-overlaytext {
	a {
		span {
			padding: 4px 10px;
			display: inline-block;
		}
		position: absolute;
		bottom: 12px;
		left: 12px;
		z-index: 2;		
		border-radius: 3px;
		padding: 2px 10px;
	}
	span {
		position: absolute;
		bottom: 12px;
		right: 12px;
		color: $white;
		width: 2rem;
		display: inline-block;
		height: 2rem;
		text-align: center;
		line-height: 2.1rem;
		border-radius: 50%;
	}
	h4 {
		position: absolute;
		bottom: 12px;
		right: 12px;
		color: $white;
		padding: 5px;
		text-align: center;
		border-radius: 3px;		
		z-index: 1;
	}
}

.item-card7-imgs {
	position: relative;
	overflow: hidden;
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
	&:before {
		content: '';
		display: block;
		position: absolute;		
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
		z-index: 1;
	}
}

.item-card8-img {
	height: 100%;
	position: relative;
	overflow: hidden;
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
}

.item-card7-img {
	height: 100%;
	position: relative;
}

.item-card9-img {
	position: relative;
	height: 100%;
}

.item-card8-imgs {
	position: relative;
	overflow: hidden;
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
	img {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
		-webkit-transition: .3s ease-in-out;
		transition: .3s ease-in-out;
	}
	&:before {
		content: '';
		display: block;
		position: absolute;		
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
		z-index: 1;
	}
}

.item-card8-overlaytext {
	position: relative;
	h6 {
		position: absolute;
		bottom: 21px;
		left: 21px;
		z-index: 2;
		padding: 8px;
		color: $white;
		border-radius: 5px;
	}
}

.item-card2-img:before {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	right: 0;
	top: 0;
	z-index: 1;
}

.item-card7-imgs a {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	i {
		margin: 0 auto;
		top: 35%;
		font-size: 40px;
		text-align: center;
		left: 0;
		right: 0;
		display: block;
		color: $white;
		position: absolute;
	}
}

@media (min-width: 569px) and (max-width: 767px) {
	.item-card7-imgs a i {
		top: 29%;
	}
}

.item-card9-img .item-card9-imgs a {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 98;
	i {
		margin: 0 auto;
		top: 35%;
		font-size: 40px;
		text-align: center;
		left: 0;
		right: 0;
		display: block;
		color: $white;
		position: absolute;
	}
}

.item-card2-img a {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	i {
		margin: 0 auto;
		top: 35%;
		font-size: 40px;
		text-align: center;
		left: 0;
		right: 0;
		display: block;
		color: $white;
		position: absolute;
	}
}

.item-card7-desc ul li {
	margin-right: 1rem;
	font-size: 13px;
	&:last-child {
		margin-right: 0;
	}
}

.item1-card-img img {
	border: 1px solid #e8ebf3;
	margin: 0 auto;
	padding: 5px;
}

.item1-card .item1-card-info .item1-card-icons li {
	display: inline-block;
	margin-right: 15px;
	a {
		color: #a7b4c9;
	}
}

.item1-card-tab {
	text-align: center;
	.tab-content .tab-pane {
		border: 1px solid #e8ebf3;
	}
}

.item1-tab-heading {
	padding: 15px;
	border: 1px solid #e8ebf3;
	text-align: center;
	border-radius: 50px;
	margin: 0 auto;
	display: inline-table;
}

.item1-tabs-menu ul li {
	a {
		padding: 10px 20px 11px 20px;
	}
	.active {
		color: $white;
		border-radius: 25px;
	}
}

.item1-card-tab .tab-content {
	padding: 15px;
	text-align: initial;
}

.item-video {
	a {
		display: inline-block;
		width: 2rem;
		height: 2rem;
		text-align: center;
		border-radius: 100px;
		line-height: 2rem;
		background: $white;
		color: #212529;
		font-size: 25px;
		align-items: center;
		margin: 0 auto;
		justify-content: center;
	}
	i {
		margin-left: 5px;
	}
}

.item2-card .item2-card-img {
	border: 1px solid #e8ebf3;
	padding: 5px;
	img {
		width: 200px;
		height: 100px;
		text-align: center;
		margin: 0 auto;
	}
}

.item2-card-info {
	padding-top: 15px;
}

.item2-btn {
	padding-top: 40px;
}

.items-gallery .items-blog-tab-heading {
	text-align: center;
	border-radius: 50px;
	margin: 0 auto;
	display: block;
	padding: 0px;
	margin-bottom: 30px;
}
.items-blog-tab-menu{
	border-bottom:2px solid #e0e5ec;
}
.items-blog-tab-heading .items-blog-tab-menu li a {
	padding: 10px 20px 11px 20px;
	color: $color;
	background: transparent;
	border-radius: 0px;
	display: block;
    font-size: 16px;
	&.active{
		position:relative;
		&:before{
			content: '';
			position: absolute;
			width: 80%;
			height: 4px;
			bottom: -3px;
			left: 0;
			right: 0;
			border-radius: 7px;
			margin: 0 auto;
			text-align: center;			
		}
	}
}

.items-gallery .tab-content {
	padding: 15px;
	text-align: initial;
}

.item2-gl .item2-gl-nav select {
	width: 20%;
}

.item2-gl-nav {
	padding: 15px;
	border: 1px solid #e8ebf3;
	border-radius: 8px;
	background: $white;
	.item2-gl-menu {
		margin-right: 10px;
		border: 1px solid #e8ebf3;
		border-radius: 8px;
		overflow: hidden;
		li a {
			width: 38px;
			height: 38px;
			line-height: 40px;
			display: block;
			text-align: center;
			margin: 0 auto;
			color: $color;
			font-size: 20px;
			background:$background;
		}
	}
}

.item2-gl .tab-content {
	padding-top: 1.5rem;
	text-align: initial;
}

.item-card9-icons {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 98;
	a {
		width: 2rem;
		display: inline-block;
		height: 2rem;
		text-align: center;
		border-radius: 100px;
		line-height: 2.2rem;
		border-radius: 50px;
		color: $white;
		margin: 2px;
		&:hover {
			color: $white;
		}
	}
}

.item-card9-cost h3 {
	margin-top: 5px;
}

.item-card9-imgs a {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.item-card9-desc ul {
	display: inline-flex;
	margin-bottom: 0;
	li {
		width: 50%;
		float: left;
	}
}

.item3-medias .item3-lists li {
	a {
		padding: 5px 4px 5px 0;
		font-size: 12px;
	}
	i {
		margin-right: 5px;
	}
	margin-right: 5px;
}

.item-all-cat {
	.item-all-card a:hover .item-all-text h5 {
		color: $color !important;
	}
	.category-type .item-all-card:hover {
		background: #f6f7fb;
	}
}

.item-card2-img {
	position: relative;
	overflow: hidden;
	border-radius: 7px 7px 0 0;
    margin: 15px 15px 0 15px;
}

.item-card2-desc ul li {
	margin-right: 1.5rem;
	font-size: 13px;
	&:last-child {
		margin-right: 0;
	}
}

.item7-card-desc a:first-child {
	margin-right: 1rem;
}

.item7-card-img {
	position: relative;
	overflow: hidden;
	a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}

.item-cards7-ic {
	display: inline-block;
	&.realestate-list li {
		a {
			i {
				width: 1rem;
			}
			color: #3d4e67;
		}
		width: 50%;
	}
	li {
		float: left;
		width: 50%;
		margin-right: 0 !important;
		margin-bottom: .5rem;
	}
}

.item7-card-text span {
	position: absolute;
	bottom: 10px;
	left: 10px;
}

.item-card-img {
	overflow: hidden;
}

.item-card-desc {
	img {
		opacity: 0.95;
		-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
		transition: opacity 0.35s, transform 0.35s;
		-webkit-transform: scale3d(1.05, 1.05, 1);
		transform: scale3d(1.05, 1.05, 1);
		width: 100%;
		height: 100%;
	}
	&:hover {
		img {
			-webkit-transform: scale3d(1, 1, 1);
			transform: scale3d(1, 1, 1);
		}
		.item-card-text::before {
			-webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
			transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
		}
	}
	.item-card-text::before {
		position: absolute;
		top: -20px;
		left: 0;
		width: 100%;
		height: 100%;
		content: '';
		background: $white-5;
		overflow: hidden;
		-webkit-transition: -webkit-transform 0.6s;
		transition: transform 0.6s;
		-webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
		transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
	}
}

.item-card7-imgs {
	img {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transition: .3s ease-in-out;
		transition: .3s ease-in-out;
	}
	&:hover img {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}
}

.item-card9-imgs {
	overflow: hidden;
	position: relative;
	img {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
		-webkit-transition: .3s ease-in-out;
		transition: .3s ease-in-out;
	}
}

.item-card2 p.leading-tight,
.item-card9 p.leading-tight {
	display: block;
	display: -webkit-box;
	max-width: 100%;
	margin: 0 auto;
	font-size: 14px;
	line-height: 1;
	-webkit-line-clamp: 2;
	-moz-line-clamp: 2;
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.item-card9-imgs:hover img {
	-webkit-transform: scale(1);
	transform: scale(1);
}

.item-card2-img {
	img {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
		-webkit-transition: .3s ease-in-out;
		transition: .3s ease-in-out;
	}
	&:hover img {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.item1-links a {
	padding: .75rem 1.25rem;
	font-weight: 400;
	font-size: .875rem;
	color: $color;
	&.active {
		font-weight: 400;
		font-size: .875rem;
	}
}

.item-user span i {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background: #f2f3f8;
	text-align: center;
	line-height: 30px;
	font-size: 12px;
}

@media (max-width: 767px) {
	.item-user .profile-pic .d-md-flex .ml-4 {
		margin-left: 0 !important;
		margin-top: 15px;
	}
}

.item-card7-img {
	&:before {
		content: '';
		display: block;
		position: absolute;
		background: $black-4;
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
	}
	a {
		position: absolute;
	}
	h4 {
		position: absolute;
		bottom: 20px;
		right: 15px;
		font-size: 25px;
		color: $white;
	}
}

@media (max-width: 767px) {
	.item2-gl-nav {
		.item2-gl-menu {
			display: none;
		}
		h6 {
			margin-bottom: 20px !important;
		}
	}
}

.item-card2-list li {
	width: 50%;
	margin-bottom: 0.5rem;
	&:nth-last-child(2),
	&:last-child {
		margin-bottom: 0;
	}
}

.item-tags {
	position: absolute;
	bottom: 12px;
	left: 10px;
	z-index: 98;
	.tag-option {
		margin: 5px;
		padding: 3px 5px;
		font-size: 12px;
		border-radius: 3px;
		color: $white;
		float: left;
		cursor: pointer;
	}
}

.item-trans-rating {
	position: absolute;
	bottom: 12px;
	right: 10px;
	z-index: 98;
	background: $black-5;
	border-radius: 3px;
	padding: 5px;
	.rating-stars .rating-stars-container .rating-star {
		&.sm {
			color: $white-5;
		}
		&.is--active {
			color: #f1c40f;
		}
	}
}

.rating-stars .rating-stars-container .rating-star.is--hover {
	color: #f1c40f;
}

.item-card-btn-hover {
	.item-card-btn {
		padding: 0;
		margin: 0;
		opacity: 0;
		position: absolute;
		z-index: 100;
		transition: all .5s ease 0s;
		a {
			display: block;
			position: relative;
			transition: all .3s ease-in-out;
			&:after {
				content: '';
				height: 100%;
				width: 100%;
				border-radius: 0;
				transform: translateX(-50%) rotate(45deg);
				z-index: -1;
				transform: translateX(-50%);
				position: absolute;
			}
			&:before {
				transform: translateX(-50%);
				position: absolute;
			}
		}
	}
	&:hover .item-card-btn {
		opacity: 1;
		bottom: 8px;
		width: 100%;
		padding: 0 5px 0 5px;
	}
	.card-footer {
		position: relative;
	}
	&:hover .card-footer:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 99;
		background: $black-5;
	}
}

.item-card8-img a {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.item-all-cat {
	.item-all-card {
		position: relative;
		padding: 1.5rem 1.5rem;
		border-radius: 8px;
		margin-bottom: 10px;
		color: $white;
	}
	.category-type .item-all-card img {
		width: 7rem;
		height: 7rem;
		border-radius: 50%;
		padding: 2.3rem 0;
	}
	.row .item-all-card {
		margin-bottom: 1.5rem;
	}
	.item-all-card a {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}

.iteam-all-icon i {
	font-size: 1.5rem;
}

.item-all-card img.imag-service {
	width: 40%;
}
.item {
	.item-card2-img {
		border-bottom-right-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}
}
