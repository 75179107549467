/*Color variables*/
/*white variables*/
/*black variables*/
@-webkit-keyframes pulse-animation {
	0% {
		-webkit-box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.4);
		box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.4);
	}
	100% {
		-webkit-box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.4);
		box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.4);
	}
}
@keyframes pulse-animation {
	0% {
		-webkit-box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.4);
		box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.4);
	}
	100% {
		-webkit-box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.4);
		box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.4);
	}
}
/*# sourceMappingURL=color10.css.map */
.rtl {
	.form-file-label {
		&::after {
			border-right: 1px solid $primary;
		}
	}
	.message-feed {
		&:not(.right) {
			.mf-content {
				&:before {
					border-left-color: $primary;
				}
			}
		}
	}
	.arrow-ribbon.bg-primary {
		&:before {
			border-right: 15px solid $primary;
		}
	}
	.arrow-ribbon2 {
		&:before {
			border-right: 24px solid $primary;
		}
	}
	.item1-links {
		a.active {
			border-left: 2px solid $primary;
		}
	}
	.arrow-ribbon.bg-secondary {
		&:before {
			border-right: 15px solid $secondary;
			border-right: 15px solid $secondary;
		}
	}
	.pricingTable {
		.title {
			&:after {
				border-right: 26px solid rgba(255, 56, 43, 0.1);
				border-left: 26px solid rgba(255, 56, 43, 0.1);
				border-left: none;
				border-right: 26px solid rgba(255, 56, 43, 0.1);
			}
			&:before {
				border-left: 26px solid rgba(255, 56, 43, 0.1);
			}
		}
		&:hover {
			.title {
				&:before {
					border-left-color: $primary;
				}
				&:after {
					border-right-color: $primary;
				}
			}
		}
	}
}
.pattern {
	&:before {
		background: rgba(48, 52, 70, 0.55);
	}
}
.owl-controls {
	.owl-page.active {
		border-color: $primary;
		opacity: 1;
		&:hover {
			border-color: $primary;
			opacity: 1;
		}
	}
}
.owl-carousel {
	&:hover {
		.owl-nav {
			button {
				color: $primary !important;
				color: $primary !important;
			}
		}
	}
	button.owl-dot {
		background: $primary-3 !important;
	}
	button.owl-dot.active {
		background: $primary !important;
	}
}
.owl-nav {
	button {
		color: $primary !important;
		color: $primary !important;
	}
	>div {
		i {
			color: $primary;
		}
	}
}
a.bg-secondary {
	&:hover {
		background-color: #f03b3b !important;
		background-color: $secondary !important;
		background-color: $secondary !important;
	}
	&:focus {
		background-color: #f03b3b !important;
		background-color: $secondary !important;
		background-color: $secondary !important;
	}
}
button.bg-secondary {
	&:hover {
		background-color: #f03b3b !important;
		background-color: $secondary !important;
		background-color: $secondary !important;
	}
	&:focus {
		background-color: #f03b3b !important;
		background-color: $secondary !important;
		background-color: $secondary !important;
	}
}
.item-card7-overlaytext {
	span {
		background: $primary;
	}
	span.active {
		background: $primary;
	}
	h4 {
		background: $primary;
	}
	a {
		background: rgba(48, 52, 70, 0.65);
	}
}
.bg-background-color {
	&:before {
		background: rgba(48, 52, 70, 0.55) !important;
		background: rgba(48, 52, 70, 0.85);
	}
}
.bg-dark-purple {
	background-color: #2a3042 !important;
}
.btn-primary {
	color: $white;
	background-color: $primary;
	border-color: $primary;
	&:hover {
		color: $white;
		background-color: $primary-hover;
		border-color: $primary-hover;
	}
	&:focus {
		-webkit-box-shadow: 0 0 0 0px $primary-5;
		box-shadow: 0 0 0 0px $primary-5;
		color: $white;
		background-color: $primary;
		border-color: $primary;
	}
	&:disabled {
		color: $white;
		background-color: $primary;
		border-color: $primary;
	}
	&:not(:disabled) {
		&:not(.disabled) {
			&:active {
				color: $white;
				background-color: $primary;
				border-color: $primary;
				&:focus {
					color: $white;
					background-color: $primary;
					border-color: $primary;
					-webkit-box-shadow: 0 0 0 0px rgba(233, 30, 99, 0.1);
					box-shadow: 0 0 0 0px rgba(233, 30, 99, 0.1);
				}
			}
		}
		&:not(.disabled).active {
			color: $white;
			background-color: $primary;
			border-color: $primary;
			&:focus {
				color: $white;
				background-color: $primary;
				border-color: $primary;
				-webkit-box-shadow: 0 0 0 0px rgba(233, 30, 99, 0.1);
				box-shadow: 0 0 0 0px rgba(233, 30, 99, 0.1);
			}
		}
	}
}
.btn-primary.focus {
	-webkit-box-shadow: 0 0 0 0px $primary-5;
	box-shadow: 0 0 0 0px $primary-5;
	color: $white;
	background-color: $primary;
	border-color: $primary;
}
.btn-primary.disabled {
	color: $white;
	background-color: $primary;
	border-color: $primary;
}
.show {
	>.btn-primary.dropdown-toggle {
		color: $white;
		background-color: $primary;
		border-color: $primary;
		&:focus {
			-webkit-box-shadow: 0 0 0 0px rgba(233, 30, 99, 0.1);
			box-shadow: 0 0 0 0px rgba(233, 30, 99, 0.1);
		}
	}
	>.btn-outline-primary.dropdown-toggle {
		color: $white;
		background-color: $primary;
		border-color: $primary;
		&:focus {
			-webkit-box-shadow: 0 0 0 2px $primary-1;
			box-shadow: 0 0 0 2px $primary-1;
		}
	}
	>.btn-secondary.dropdown-toggle {
		color: $white !important;
		background-color: $secondary;
		border-color: $secondary;
		&:focus {
			-webkit-box-shadow: 0 0 0 2px rgba(47, 12, 108, 0.5);
			box-shadow: 0 0 0 2px rgba(47, 12, 108, 0.5);
		}
	}
	>.btn-outline-secondary.dropdown-toggle {
		color: $white;
		background-color: $secondary;
		border-color: $secondary;
		&:focus {
			-webkit-box-shadow: 0 0 0 2px rgba(244, 106, 106, 0.1);
			box-shadow: 0 0 0 2px rgba(244, 106, 106, 0.1);
		}
	}
}
.btn-outline-primary {
	color: $primary;
	background-color: transparent;
	background-image: none;
	border-color: $primary !important;
	&:hover {
		color: $white;
		background-color: $primary;
		border-color: $primary !important;
	}
	&:focus {
		-webkit-box-shadow: 0 0 0 2px $primary-1;
		box-shadow: 0 0 0 2px $primary-1;
	}
	&:disabled {
		color: $primary;
		background-color: transparent;
	}
	&:not(:disabled) {
		&:not(.disabled) {
			&:active {
				color: $white;
				background-color: $primary;
				border-color: $primary;
				&:focus {
					-webkit-box-shadow: 0 0 0 2px $primary-1;
					box-shadow: 0 0 0 2px $primary-1;
				}
			}
		}
		&:not(.disabled).active {
			color: $white;
			background-color: $primary;
			border-color: $primary;
			&:focus {
				-webkit-box-shadow: 0 0 0 2px $primary-1;
				box-shadow: 0 0 0 2px $primary-1;
			}
		}
	}
}
.btn-outline-primary.focus {
	-webkit-box-shadow: 0 0 0 2px $primary-1;
	box-shadow: 0 0 0 2px $primary-1;
}
.btn-outline-primary.disabled {
	color: $primary;
	background-color: transparent;
}
.btn-link {
	color: $primary;
}
.alert-primary {
	color: #fcfdff;
	background-color: $primary;
	border-color: $primary;
	hr {
		border-top-color: #b7cded;
	}
	.alert-link {
		color: $white;
	}
}
.label-primary {
	background-color: $primary;
}
.badge-primary {
	color: $white;
	background-color: $primary;
}
.badge-primary[href] {
	&:hover {
		color: $white;
		text-decoration: none;
		background-color: $primary;
	}
	&:focus {
		color: $white;
		text-decoration: none;
		background-color: $primary;
	}
}
.bg-primary {
	background-color: $primary !important;
}
a.bg-primary {
	&:hover {
		background-color: $primary !important;
	}
	&:focus {
		background-color: $primary !important;
	}
}
button.bg-primary {
	&:hover {
		background-color: $primary !important;
	}
	&:focus {
		background-color: $primary !important;
	}
}
.border-primary {
	border-color: $primary !important;
}
.text-primary {
	color: $primary !important;
}
a.text-primary {
	&:hover {
		color: $primary !important;
	}
	&:focus {
		color: $primary !important;
	}
}
a.text-dark {
	&:hover {
		color: $primary !important;
	}
	&:focus {
		color: $primary !important;
	}
}
.alert-secondary {
	color: #fcfdff;
	background-color: $secondary;
	border-color: $secondary;
	hr {
		border-top-color: #b7cded;
	}
	.alert-link {
		color: $white;
	}
}
.social-box.linkedin {
	i {
		background: $primary;
	}
}
.checkbox {
	span {
		&:after {
			background-color: $primary;
		}
	}
}
.user-card {
	.card-block {
		.activity-leval {
			li.active {
				background-color: $primary;
			}
		}
	}
}
#count-down {
	.clock-presenter {
		.digit {
			background: $primary;
		}
	}
}
.notifyimg {
	background: $primary;
}
.drop-icon-wrap {
	.drop-icon-item {
		&:hover {
			color: $primary;
		}
	}
}
.dropdown-item {
	&:hover {
		color: $primary;
	}
	&:focus {
		color: $primary;
	}
	&:active {
		color: $primary;
	}
}
.dropdown-item.active {
	color: $primary;
}
.custom-control-input {
	&:checked {
		~ {
			.custom-control-label {
				&::before {
					background-color: $primary;
					color: $white;
					border-color: $primary;
					background-color: $primary;
					color: $white;
					border-color: $primary;
					background-color: $primary;
				}
			}
		}
	}
	&:focus {
		~ {
			.custom-control-label {
				&::before {
					-webkit-box-shadow: 0 0 0 1px #f9faff, 0 0 0 2px rgba(105, 99, 255, 0.25);
					box-shadow: 0 0 0 1px #f9faff, 0 0 0 2px rgba(105, 99, 255, 0.25);
				}
			}
		}
	}
}
.form-checkbox {
	.custom-control-input {
		&:checked {
			~ {
				.custom-control-label {
					&::before {
						background-color: $primary;
					}
				}
			}
		}
		&:indeterminate {
			~ {
				.custom-control-label {
					&::before {
						background-color: $primary;
					}
				}
			}
		}
		&:disabled {
			&:checked {
				~ {
					.custom-control-label {
						&::before {
							background-color: $primary-5;
						}
					}
				}
			}
			&:indeterminate {
				~ {
					.custom-control-label {
						&::before {
							background-color: $primary-5;
						}
					}
				}
			}
		}
	}
}
.custom-radio {
	.custom-control-input {
		&:checked {
			~ {
				.custom-control-label {
					&::before {
						background-color: $primary;
					}
				}
			}
		}
		&:disabled {
			&:checked {
				~ {
					.custom-control-label {
						&::before {
							background-color: $primary-5;
						}
					}
				}
			}
		}
	}
}
.form-file-input {
	&:focus {
		~ {
			.form-file-label {
				border-color: $primary;
				-webkit-box-shadow: 0 0 0 2px rgba(105, 99, 255, 0.25);
				box-shadow: 0 0 0 2px rgba(105, 99, 255, 0.25);
				&::after {
					border-color: $primary;
				}
			}
		}
	}
}
.form-file-label {
	&::after {
		background-color: $primary;
		border-left: 1px solid $primary;
	}
}
.form-range {
	&::-webkit-slider-thumb {
		background-color: $primary;
		&:focus {
			outline: none;
			-webkit-box-shadow: 0 0 0 1px #f9faff, 0 0 0 2px rgba(105, 99, 255, 0.25);
			box-shadow: 0 0 0 1px #f9faff, 0 0 0 2px rgba(105, 99, 255, 0.25);
		}
	}
	&::-moz-range-thumb {
		background-color: $primary;
		&:focus {
			outline: none;
			-webkit-box-shadow: 0 0 0 1px #f9faff, 0 0 0 2px rgba(105, 99, 255, 0.25);
			box-shadow: 0 0 0 1px #f9faff, 0 0 0 2px rgba(105, 99, 255, 0.25);
		}
	}
	&::-ms-thumb {
		background-color: $primary;
		&:focus {
			outline: none;
			-webkit-box-shadow: 0 0 0 1px #f9faff, 0 0 0 2px rgba(105, 99, 255, 0.25);
			box-shadow: 0 0 0 1px #f9faff, 0 0 0 2px rgba(105, 99, 255, 0.25);
		}
	}
}
.nav-link.active {
	background-color: $primary;
}
.nav-pills {
	.nav-link.active {
		background-color: $primary;
	}
	.show {
		>.nav-link {
			background-color: $primary;
		}
	}
}
.page-link {
	&:hover {
		color: $primary;
	}
}
.page-item.active {
	.page-link {
		background-color: $primary;
		border-color: $primary;
	}
}
.range.range-primary {
	input[type="range"] {
		&::-webkit-slider-thumb {
			background-color: $primary;
		}
		&::-moz-slider-thumb {
			background-color: $primary;
		}
		outline-color: $primary;
	}
	output {
		background-color: $primary;
	}
}
.nav-tabs {
	.nav-item1 {
		.nav-link {
			&:hover {
				&:not(.disabled) {
					background: $primary;
				}
			}
		}
		.nav-link.active {
			background: $primary;
		}
	}
	.nav-link {
		&:hover {
			&:not(.disabled) {
				color: $white;
				background: $primary;
			}
		}
	}
	.nav-link.active {
		color: $white;
		background: $primary;
	}
}
.panel.price {
	>.panel-heading {
		background: $primary;
		color: $white;
	}
}

.heading-secondary {
	color: $secondary !important;
	padding: 5px;
}
.page-header {
	.breadcrumb-item {
		a {
			color: #495057;
		}
	}
}
.breadcrumb-item1 {
	a {
		&:hover {
			color: $primary;
		}
	}
}
.panel-title1 {
	a {
		color: $primary;
	}
}
.header {
	background: linear-gradient(120deg, #0f75ff 60%, $primary 100%);
}
.header-bg {
	background: #eef2f9;
	background: -webkit-gradient(linear, left top, right top, from(#eef2f9), to($primary));
	background: linear-gradient(to right, #eef2f9, $primary);
}
.navtab-wizard.nav-tabs {
	.nav-link {
		&:hover {
			color: $white;
		}
	}
}
.expanel-primary {
	>.expanel-heading {
		color: $white !important;
		background-color: $primary !important;
		border-color: $primary !important;
	}
}
.error-img {
	.form-control {
		&:focus {
			color: $white;
			background-color: $white-2;
			border-color: $primary;
			outline: 0;
			-webkit-box-shadow: 0 0 0 2px rgba(105, 99, 255, 0.25);
			box-shadow: 0 0 0 2px rgba(105, 99, 255, 0.25);
		}
	}
	&:before {
		background: rgba(48, 52, 70, 0.85);
	}
}
.avatar {
	background: $primary no-repeat center/cover;
}
.spinner {
	background: linear-gradient(120deg, $primary 0%, $primary 100%);
}
.spinner-lg {
	background-color: $primary;
}
.double-bounce1 {
	background: linear-gradient(120deg, $primary 0%, $primary 100%);
}
.double-bounce2 {
	background: linear-gradient(120deg, $primary 0%, $primary 100%);
}
.cube1 {
	background: linear-gradient(120deg, $primary 0%, $primary 100%);
}
.cube2 {
	background: linear-gradient(120deg, $primary 0%, $primary 100%);
}
.lds-heart {
	div {
		background: $primary;
		&:after {
			background: $primary;
		}
		&:before {
			background: $primary;
		}
	}
}
.lds-ring {
	div {
		border: 6px solid $primary;
		border-color: $primary transparent transparent transparent;
	}
}
.lds-hourglass {
	&:after {
		border: 26px solid $primary;
		border-color: $primary transparent;
	}
}
.mediaicon {
	border: 1px solid $primary;
	background: $primary;
}
a.chip {
	&:hover {
		background-color: $primary;
	}
}
.highlight {
	.s {
		color: $primary;
	}
}
.selectgroup-input {
	&:checked {
		+ {
			.selectgroup-button {
				border-color: $primary;
				z-index: 1;
				color: $primary;
				background: #f6f7fb;
			}
		}
	}
	&:focus {
		+ {
			.selectgroup-button {
				border-color: $primary;
				z-index: 2;
				color: $primary;
				-webkit-box-shadow: 0 0 0 2px rgba(105, 99, 255, 0.25);
				box-shadow: 0 0 0 2px rgba(105, 99, 255, 0.25);
			}
		}
	}
}
.form-switch-input {
	&:checked {
		~ {
			.form-switch-indicator {
				background: $primary;
			}
		}
	}
	&:focus {
		~ {
			.form-switch-indicator {
				-webkit-box-shadow: 0 0 0 2px rgba(105, 99, 255, 0.25);
				box-shadow: 0 0 0 2px rgba(105, 99, 255, 0.25);
				border-color: $primary;
			}
		}
	}
}
.imagecheck-input {
	&:focus {
		~ {
			.imagecheck-figure {
				border-color: transparent;
				-webkit-box-shadow: none;
				box-shadow: none;
			}
		}
	}
}
.imagecheck-figure {
	&:before {
		background: $primary url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center center/50% 50%;
		color: $white;
	}
}
.colorinput-input {
	&:focus {
		~ {
			.colorinput-color {
				border-color: transparent;
				-webkit-box-shadow: none;
				box-shadow: none;
			}
		}
	}
}
.ui-datepicker {
	.ui-datepicker-title {
		color: $primary;
	}
}
#back-to-top {
	background: $primary;
	&:hover {
		background: $white;
		color: $primary;
		border: 2px solid $primary;
	}
}
#chat-message {
	background: linear-gradient(-225deg, rgba(74, 61, 184, 0.8) 48%, rgba(50, 228, 179, 0.8) 100%);
}
.tabs-menu {
	ul {
		li {
			.active {
				background: $primary;
			}
		}
	}
	ul.eductaional-tabs {
		li {
			.active {
				background: $primary;
			}
		}
	}
}
.tabs-menu1 {
	ul {
		li {
			a {
				background: rgba(85, 110, 230, 0.15);
				color: $primary;
			}
			.active {
				background: $primary;
				color: $white;
			}
		}
	}
}
.wideget-user-tab {
	.tabs-menu1 {
		ul {
			li {
				.active {
					&:after {
						background: $primary !important;
					}
				}
			}
		}
	}
}
.rating-stars {
	.rating-stars-container {
		.rating-star.is--active {
			.fa-heart {
				color: $primary;
			}
		}
		.rating-star.is--hover {
			.fa-heart {
				color: $primary;
			}
		}
	}
}
.message-feed {
	&:not(.right) {
		.mf-content {
			background: $primary;
			color: $white;
			&:before {
				border-right-color: $primary;
			}
		}
	}
}
.msb-reply {
	button {
		background: $primary;
	}
}
.wizard-card {
	.moving-tab {
		background-color: $primary !important;
	}
	.choice {
		&:hover {
			.icon {
				border-color: $primary !important;
				color: $primary !important;
			}
		}
	}
	.choice.active {
		.icon {
			border-color: $primary !important;
			color: $primary !important;
		}
	}
}
.cal1 {
	.clndr {
		.clndr-table {
			.header-days {
				background: $primary;
			}
			tr {
				.day.today.event {
					background: $primary;
				}
				.day.my-today.event {
					background: $primary;
				}
				.day.today {
					&:hover {
						background: $primary;
						color: $white;
					}
				}
				.day.my-today {
					&:hover {
						background: $primary;
						color: $white;
					}
				}
			}
		}
		.clndr-controls {
			.clndr-control-button {
				.clndr-previous-button {
					color: $primary;
					background: #e6f2fe;
					&:hover {
						background: $primary;
					}
				}
				.clndr-next-button {
					color: $primary;
					background: #e6f2fe;
					&:hover {
						background: $primary;
					}
				}
			}
		}
	}
}
.fc {
	button {
		background: $primary;
	}
}
.fc-event-dot {
	background-color: $primary;
	color: #f1f1f1 !important;
}
#sidebar {
	.accordion-toggle {
		i {
			background: linear-gradient(120deg, #0f75ff 60%, $primary 100%);
		}
	}
	ul {
		li.active {
			>a {
				color: $primary;
				&:hover {
					color: $primary;
				}
			}
		}
		li {
			a {
				&:hover {
					color: $primary;
				}
			}
		}
	}
	li {
		a[aria-expanded="true"].active {
			&:before {
				background: linear-gradient(120deg, #0f75ff 60%, $primary 100%);
			}
		}
	}
	.collapse {
		li {
			a {
				&:hover {
					color: $primary;
				}
			}
		}
	}
}
.sweet-alert {
	button {
		background-color: $primary !important;
	}
}
.timeline__item {
	&:after {
		border: 6px solid $primary;
	}
}
.label-primary.arrowed {
	&:before {
		border-right-color: $primary;
	}
}
.widgetdate {
	p {
		border-bottom: 2px solid $primary;
	}
}
.widgetbox {
	p {
		border-bottom: 2px solid $primary;
	}
}
.datebox {
	p {
		border-bottom: 2px solid $primary;
	}
}
.arrow-ribbon.bg-primary {
	&:before {
		border-left: 15px solid $primary;
	}
}
.arrow-ribbon2 {
	&:before {
		border-left: 24px solid $primary;
	}
}
.badge-offer.bg-primary {
	&:after {
		border-top: 12px solid $primary;
	}
}
.badge-offer1.bg-primary {
	&:after {
		border-top: 12px solid $primary;
	}
}
.social-icons {
	li {
		a {
			&:hover {
				border-color: $primary;
				background: $primary;
				color: $white;
			}
		}
	}
}
.social {
	li {
		a {
			&:hover {
				color: $primary;
			}
		}
	}
}
.footer-main {
	a {
		&:hover {
			color: $primary;
		}
	}
}
.footer-links {
	a {
		&:hover {
			color: $primary;
		}
	}
}
.product-tags {
	li {
		a {
			&:hover {
				background: $primary;
				border-color: $primary;
			}
		}
	}
}
.info {
	.counter-icon {
		border: 1px solid $white-6;
		border: 0px solid $white-9 !important;
		background: $primary-1;
		i {
			color: $white;
		}
	}
}
.counter-icon {
	border: 1px solid $primary;
	i {
		color: $primary;
	}
	&:before {
		border: 3px solid $primary-1;
	}
	&:after {
		background: $primary-8;
	}
}
.header-links {
	li {
		a.active {
			background: $primary;
			color: $white;
		}
		a {
			&:hover {
				background: $primary;
				color: $white;
			}
		}
	}
}
.card-pay {
	.tabs-menu {
		li {
			a.active {
				background: $primary;
			}
		}
	}
}
.item1-tabs-menu {
	ul {
		li {
			.active {
				background: $primary;
			}
		}
	}
}
.items-blog-tab-heading {
	.items-blog-tab-menu {
		li {
			.active {
				background: transparent;
				color: $primary;
			}
			a.active {
				&:before {
					background: $primary;
				}
			}
		}
	}
}
.item2-gl-nav {
	.item2-gl-menu {
		li {
			.active {
				color: $white;
				background: $primary;
			}
		}
	}
}
.item-all-cat {
	.item-all-card {
		&:hover {
			.item-all-text {
				h5 {
					color: $primary !important;
				}
			}
		}
	}
	.category-type {
		.item-all-card {
			img {
				background: linear-gradient(120deg, #3ba2ff 0%, #6963ff 100%);
			}
		}
	}
}
.carousel-indicators {
	li.active {
		color: $white;
		background: $primary;
	}
}
.item-cards7-ic.realestate-list {
	li {
		a {
			&:hover {
				color: $primary;
			}
		}
	}
}
.item1-links {
	a {
		&:hover {
			color: $primary;
		}
	}
	a.active {
		background: rgba(241, 238, 247, 0.6);
		color: $primary;
		border-right: 2px solid $primary;
	}
}
.settings-tab {
	.tabs-menu {
		li {
			a.active {
				background: $primary;
			}
		}
	}
}
.ads-tabs {
	.tabs-menus {
		ul {
			li {
				.active {
					background: $primary;
					color: $white;
				}
			}
		}
	}
}
.showmore-button-inner {
	border: 1px solid $primary;
	color: $primary;
}
.register-right {
	.nav-tabs {
		.nav-link {
			&:hover {
				background: $primary;
				color: $white;
			}
		}
	}
}
.gradient-icon {
	background: linear-gradient(120deg, #3ba2ff 0%, $primary 100%);
}
.axgmap-img {
	border: 3px solid $primary;
}
code {
	color: $primary;
}
#gdpr-cookie-message {
	h4 {
		color: $primary;
	}
	h5 {
		color: $primary;
	}
	a {
		color: $primary;
		border-bottom: 1px solid $primary;
		&:hover {
			border-bottom: 1px solid $primary;
			-webkit-transition: all 0.3s ease-in;
			transition: all 0.3s ease-in;
			color: $primary;
		}
	}
	button {
		background: $secondary;
	}
}
button#gdpr-cookie-advanced {
	color: $white;
	background-color: $primary !important;
}
.ui-widget-header {
	background: $primary;
	border-color: $primary;
	.ui-state-default {
		background: $primary;
		border-color: $primary;
	}
}
.ui-state-default {
	background: $primary;
	border-color: $primary;
}
.ui-widget-content {
	.ui-state-default {
		background: $primary;
		border-color: $primary;
	}
}
.app-sidebar {
	.side-menu {
		li.active {
			>a {
				color: $primary;
			}
		}
		li {
			a.active {
				color: $primary;
			}
			ul {
				li {
					a {
						&:hover {
							color: $primary;
						}
					}
				}
			}
			a {
				&:hover {
					color: $primary;
				}
			}
		}
	}
	.slide-menu {
		li.active {
			>a {
				color: $primary;
			}
		}
		.slide-item {
			&:hover {
				color: $primary;
			}
		}
	}
	.side-menu__item.active {
		background: rgba(48, 52, 70, 0.15) !important;
		color: $primary !important;
		&:hover {
			background: rgba(48, 52, 70, 0.15) !important;
			color: $primary !important;
		}
		.side-menu__label {
			color: $primary;
		}
		.side-menu__icon {
			color: $primary;
		}
	}
	&:before {
		background: $white;
	}
	.side-menu__item {
		&:hover {
			color: $primary;
		}
	}
}

.form-inline {
	.nav-search {
		.btn {
			border: 2px solid $primary;
		}
	}
}

.wrapper {
	>h1 {
		span {
			border-bottom: 2px solid $primary;
			color: $primary;
		}
	}
}

.tab_wrapper {
	>ul {
		li.active {
			border-color: $primary;
			background: $primary;
			color: $white;
		}
	}
	.controller {
		span {
			background: $primary;
		}
	}
	.content_wrapper {
		.accordian_header.active {
			color: $primary;
			&:after {
				background: $primary;
			}
			.arrow {
				border-top: 3px solid $primary;
				border-left: 3px solid $primary;
			}
		}
	}
}
.tab_wrapper.left_side {
	>ul {
		li.active {
			&:after {
				background: $primary;
			}
		}
	}
}
.tab_wrapper.right_side {
	>ul {
		li.active {
			&:after {
				background: $primary;
			}
		}
	}
}
.addui-slider {
	.addui-slider-track {
		.addui-slider-range {
			background: $primary;
		}
		.addui-slider-handle {
			&:after {
				background: $primary;
			}
		}
	}
}
.accordionjs {
	.acc_section.acc_active {
		>.acc_head {
			background: $primary;
		}
	}
}
.perfect-datetimepicker {
	tbody {
		td.selected {
			border: 1px solid $primary;
			background-color: $primary;
		}
	}
}
div.conv-form-wrapper {
	div.options {
		div.option {
			border: 1px solid $primary;
			color: $primary;
		}
		div.option.selected {
			background: $primary;
			color: $white;
		}
	}
	div#messages {
		div.message.from {
			background: $primary;
		}
	}
}
form.convFormDynamic {
	button.submit {
		border: 1px solid $primary;
		background: $primary;
		&:hover {
			background: $primary;
			color: $white;
		}
	}
}
.exzoom {
	.exzoom_nav {
		.exzoom_nav_inner {
			span.current {
				border: 1px solid $primary;
			}
		}
	}
	.exzoom_btn {
		a {
			color: $primary;
		}
	}
}
.prev {
	&:hover {
		color: $primary !important;
	}
}
.next {
	&:hover {
		color: $primary !important;
	}
}
.g_f-s {
	.prev {
		&:hover {
			i {
				-webkit-transform: translate(-5px, 0px);
				transform: translate(-5px, 0px);
				color: $primary;
			}
		}
	}
	.next {
		&:hover {
			i {
				-webkit-transform: translate(5px, 0px);
				transform: translate(5px, 0px);
				color: $primary;
			}
		}
	}
	.close-button {
		>* {
			&:hover {
				color: $primary;
			}
		}
	}
}
.horizontalMenu {
	>.horizontalMenu-list {
		>li {
			>.horizontal-megamenu {
				.link-list {
					li {
						a {
							&:hover {
								color: $primary;
							}
						}
						a.active {
							color: $primary;
						}
					}
				}
			}
			>ul.sub-menu {
				>li {
					>a.active {
						color: $primary !important;
					}
					>ul.sub-menu {
						>li {
							>a.active {
								color: $primary !important;
							}
						}
					}
				}
			}
		}
	}
}
.register {
	background: linear-gradient(120deg, #4f4ac7 0%, $primary 100%);
	.nav-tabs {
		.nav-link.active {
			color: $primary;
			border: 1px solid $primary;
		}
	}
}
.btnRegister {
	background: $primary;
}
.pretty {
	input {
		&:checked {
			~ {
				.state.p-primary {
					label {
						&:after {
							background-color: $primary !important;
						}
					}
				}
				.state.p-primary-o {
					label {
						&:before {
							border-color: $primary;
						}
					}
					.icon1 {
						color: $primary;
						stroke: $primary;
					}
					.svg {
						color: $primary;
						stroke: $primary;
					}
					svg {
						color: $primary;
						stroke: $primary;
					}
				}
			}
		}
	}
}
.pretty.p-toggle {
	.state.p-primary {
		label {
			&:after {
				background-color: $primary !important;
			}
		}
	}
	.state.p-primary-o {
		label {
			&:before {
				border-color: $primary;
			}
		}
		.icon1 {
			color: $primary;
			stroke: $primary;
		}
		.svg {
			color: $primary;
			stroke: $primary;
		}
		svg {
			color: $primary;
			stroke: $primary;
		}
	}
}
.pretty.p-default {
	&:not(.p-fill) {
		input {
			&:checked {
				~ {
					.state.p-primary-o {
						label {
							&:after {
								background-color: $primary !important;
							}
						}
					}
				}
			}
		}
	}
}
.pretty.p-switch {
	input {
		&:checked {
			~ {
				.state.p-primary {
					&:before {
						border-color: $primary;
					}
				}
			}
		}
	}
}
.pretty.p-switch.p-fill {
	input {
		&:checked {
			~ {
				.state.p-primary {
					&:before {
						background-color: $primary !important;
					}
				}
			}
		}
	}
}
.bg-background2 {
	&:before {
		background: rgba(48, 52, 70, 0.85);
	}
}
.bg-background {
	&:before {
		background: rgba(48, 52, 70, 0.85);
	}
}
.bg-background3 {
	&:before {
		background: rgba(48, 52, 70, 0.85);
	}
}
.bg-background-5 {
	&:before {
		background: rgba(48, 52, 70, 0.85);
	}
}
.bg-background-6 {
	&:before {
		background: rgba(48, 52, 70, 0.85);
	}
}
.slider-header {
	.item {
		&:before {
			background: rgba(48, 52, 70, 0.85);
		}
	}
}
.bg-background2.bg-background-primary-color {
	&:before {
		background: rgba(10, 46, 216, 0.65) !important;
	}
}
.select2-container--default.select2-container--focus {
	.select2-selection--multiple {
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}
.selectize-input.focus {
	border-color: #467fcf;
	-webkit-box-shadow: 0 0 0 2px rgba(105, 99, 255, 0.25);
	box-shadow: 0 0 0 2px rgba(105, 99, 255, 0.25);
}
.item-card {
	&:hover {
		.item-card-desc {
			&:before {
				background: rgba(48, 52, 70, 0.1);
			}
		}
	}
	.item-card-desc {
		&:before {
			background: rgba(48, 52, 70, 0.2);
			background: rgba(48, 52, 70, 0.5);
		}
	}
}
.item-card7-imgs {
	&:hover {
		a {
			background: rgba(48, 52, 70, 0.4);
		}
	}
	&:before {
		background: rgba(48, 52, 70, 0.1);
	}
}
.item-card2-img {
	&:hover {
		a {
			background: transparent;
		}
	}
	&:before {
		background: rgba(48, 52, 70, 0.1);
	}
}
.app-sidebar-footer {
	background: #22337d;
}
.masthead {
	.masthead-bg {
		background: linear-gradient(-225deg, rgba(74, 61, 184, 0.8) 48%, rgba(50, 228, 179, 0.8) 100%) !important;
	}
}
.bg-secondary {
	background-color: $secondary !important;
	background-color: $secondary !important;
}
.btn-secondary {
	color: $white !important;
	background-color: $secondary;
	border-color: $secondary;
	&:hover {
		color: $white !important;
		background-color: #ef4e4e;
		border-color: #ef4e4e;
	}
	&:focus {
		-webkit-box-shadow: 0 0 0 2px rgba(244, 106, 106, 0.5);
		box-shadow: none;
		color: $white !important;
		background-color: $secondary;
		border-color: $secondary;
	}
	&:disabled {
		color: $white !important;
		background-color: $secondary;
		border-color: $secondary;
	}
	&:not(:disabled) {
		&:not(.disabled) {
			&:active {
				color: $white !important;
				background-color: $secondary;
				border-color: $secondary;
				&:focus {
					-webkit-box-shadow: 0 0 0 2px rgba(47, 12, 108, 0.5);
					box-shadow: none;
				}
			}
		}
		&:not(.disabled).active {
			color: $white !important;
			background-color: $secondary;
			border-color: $secondary;
			&:focus {
				-webkit-box-shadow: 0 0 0 2px rgba(47, 12, 108, 0.5);
				box-shadow: none;
			}
		}
	}
}
.btn-secondary.focus {
	-webkit-box-shadow: 0 0 0 2px rgba(244, 106, 106, 0.5);
	box-shadow: 0 0 0 2px rgba(244, 106, 106, 0.5);
	color: $white !important;
	background-color: $secondary;
	border-color: $secondary;
}
.btn-secondary.disabled {
	color: $white !important;
	background-color: $secondary;
	border-color: $secondary;
}
.btn-outline-secondary {
	color: $secondary;
	background-color: transparent;
	background-image: none;
	border-color: $secondary;
	&:hover {
		color: $white;
		background-color: $secondary;
		border-color: $secondary;
	}
	&:focus {
		-webkit-box-shadow: 0 0 0 2px rgba(244, 106, 106, 0.1);
		box-shadow: 0 0 0 2px rgba(244, 106, 106, 0.1);
		color: $white;
		background-color: $secondary;
		border-color: $secondary;
	}
	&:disabled {
		color: $secondary;
		background-color: transparent;
	}
	&:not(:disabled) {
		&:not(.disabled).active {
			color: $white;
			background-color: $secondary;
			border-color: $secondary;
			&:focus {
				-webkit-box-shadow: 0 0 0 2px rgba(244, 106, 106, 0.1);
				box-shadow: 0 0 0 2px rgba(244, 106, 106, 0.1);
			}
		}
		&:not(.disabled) {
			&:active {
				color: $white;
				background-color: $secondary;
				border-color: $secondary;
				&:focus {
					-webkit-box-shadow: 0 0 0 2px rgba(244, 106, 106, 0.1);
					box-shadow: 0 0 0 2px rgba(244, 106, 106, 0.1);
				}
			}
		}
	}
}
.btn-outline-secondary.focus {
	-webkit-box-shadow: 0 0 0 2px rgba(244, 106, 106, 0.1);
	box-shadow: 0 0 0 2px rgba(244, 106, 106, 0.1);
	color: $white;
	background-color: $secondary;
	border-color: $secondary;
}
.btn-outline-secondary.disabled {
	color: $secondary;
	background-color: transparent;
}
.bg-linkedin {
	background: #0077b5;
}
.bg-facebook {
	background: #3C5A99;
}
.accent-2 {
	border-top: 2px solid $primary;
	border-top: 2px solid $primary;
}
.header-main {
	i {
		color: $primary;
		color: $primary;
	}
}
.btn-orange {
	color: $white;
	background-color: #e67605;
	border-color: #e67605;
}
.arrow-ribbon.bg-secondary {
	&:before {
		border-left: 15px solid $secondary;
		border-left: 15px solid $secondary;
	}
}
.select2-container--default {
	.select2-results__option--highlighted[aria-selected] {
		background-color: #ececf3;
		color: $primary;
	}
}
.sticky-wrapper.relative.banners {
	.horizontalMenu {
		>.horizontalMenu-list {
			>li {
				>a.active {
					background-color: $primary;
				}
				&:hover {
					>a {
						background-color: $primary;
					}
				}
			}
		}
	}
}
.pricingTable2.pink {
	.pricing-plans {
		background-color: $primary;
		background-color: $primary;
	}
}
.pricingTable {
	.title {
		&:after {
			border-left: 26px solid rgba(255, 56, 43, 0.1);
			content: "";
			border-right: 26px solid rgba(255, 56, 43, 0.1);
			border-right: none;
			border-left: 26px solid rgba(255, 56, 43, 0.1);
		}
		&:before {
			content: "";
			border-right: 26px solid rgba(255, 56, 43, 0.1);
		}
		color: $primary;
	}
	&:hover {
		border: 1px solid #ff382b;
		border: 1px solid $primary;
		border: 1px solid $primary;
		.pricingTable-signup {
			background: $primary;
			color: $white;
			background: $primary;
			color: $white;
		}
		.title {
			background: $primary;
			color: $white;
			&:before {
				border-right-color: $primary;
				border-right-color: $primary;
			}
			&:after {
				border-left-color: $primary;
				border-left-color: $primary;
			}
		}
	}
	.pricingTable-signup {
		background: rgba(255, 56, 43, 0.12);
		color: $primary;
		color: $primary;
	}
}
.pricingTable.advance-pricing {
	border: 1px solid #ff382b;
	border: 1px solid $primary;
	border: 1px solid $primary;
}
.construction-image {
	&:before {
		content: '';
		background: $primary-8;
	}
}
.construction {
	z-index: 1;
}
.countdown-timer-wrapper {
	.timer {
		.timer-wrapper {
			.time {
				color: $primary;
				background: $white;
			}
		}
	}
}
.horizontal-main {
	background: transparent;
}
.bg-card-light {
	&:hover {
		border-color: $primary;
		border-color: $primary;
	}
}
.bg-card {
	&:hover {
		border: 1px solid $primary;
	}
	.cat-img {
		i {
			color: $primary;
		}
	}
}
.sticky-wrapper.absolute.banners {
	.horizontalMenu {
		>.horizontalMenu-list {
			>li {
				>a.active {
					background-color: $white;
					color: $primary !important;
				}
				&:hover {
					>a {
						background-color: $white;
						color: $primary !important;
					}
				}
			}
		}
	}
	.horizontal-main {
		&:after {
			background: $white;
		}
		.horizontalMenu {
			>.horizontalMenu-list {
				>li {
					&:hover {
						a {
							>.fa {
								color: $primary !important;
							}
						}
					}
					a {
						color: #495057;
						>.fa {
							color: #adafbb;
						}
					}
					a.active, a:hover {
						>.fa {
							color: $primary;
						}
						color: $primary;
					}
					ul.sub-menu {
						>li {
							>a {
								&:hover {
									color: $primary !important;
								}
							}
						}
					}
				}
			}
		}
	}
}
.ribbon-1 {
	&:after {
		border-top: 13px solid $primary;
	}
	span {
		background: $primary;
		&:before {
			background: $primary;
		}
		&:after {
			background: $primary;
		}
	}
}
.status-border {
	&:hover {
		border: 1px solid $primary !important;
		i {
			color: $primary !important;
		}
	}
}
.rev-btn {
	background-color: $primary;
	border-color: $primary;
	&:hover {
		background-color: $primary !important;
		border-color: $primary;
	}
}
a {
	&:hover {
		color: $primary;
	}
}
.breadcrumb-item.active {
	color: $primary;
}
.label.arrowed-in {
	&:before {
		border-right-color: $primary;
	}
}
.label.arrowed {
	&:before {
		border-right-color: $primary;
	}
}
.item-card8-overlaytext {
	h6 {
		background: $primary;
	}
}
.onoffswitch-checkbox {
	&:checked {
		+ {
			.onoffswitch-label {
				background: $primary;
				border-color: $primary;
				&:before {
					border-color: $primary;
				}
			}
		}
	}
}
.onoffswitch2-checkbox {
	&:checked {
		+ {
			.onoffswitch2-label {
				background: $primary;
				&:before {
					border-color: $primary;
				}
			}
			.onoffswitch-label2 {
				border-color: $primary;
			}
		}
	}
}
button#ihavecookiesBtn {
	background: $secondary;
}
.ribbon-2 {
	&:after {
		border-top: 13px solid $secondary;
	}
	span {
		background: $secondary;
		&:before {
			background: $secondary;
		}
		&:after {
			background: $secondary;
		}
	}
}
.pricingTable2.blue {
	.pricing-plans {
		background-color: $secondary;
	}
}
.badge-secondary {
	color: $white;
	background-color: $secondary;
}
.badge-secondary[href] {
	&:focus {
		color: $white;
		text-decoration: none;
		background-color: $secondary;
	}
	&:hover {
		color: $white;
		text-decoration: none;
		background-color: $secondary;
	}
}
.modal-header {
	.close.btn {
		background-color: $secondary;
		border-color: $secondary;
	}
}
.text-secondary {
	color: $secondary !important;
}
a.text-secondary {
	&:focus {
		color: $secondary !important;
	}
	&:hover {
		color: $secondary !important;
	}
}
.header-main-absolute {
	.top-bar {
		.dropdown-item {
			i {
				color: $primary !important;
			}
		}
	}
}
.bg-secondary-transparent {
	background-color: rgba(244, 106, 106, 0.1) !important;
}
.bg-secondary-transparent-2 {
	background-color: rgba(244, 106, 106, 0.2);
}
.bg-primary-transparent {
	background-color: $primary-1 !important;
}
.bg-primary-transparent-2 {
	background-color: $primary-2;
}
.bg-secondary-light {
	background-color: #fef0f0;
}
.bg-primary-light {
	background-color: #eef0fc;
}
.bg-purple-dark-transparent-5 {
	background-color: rgba(48, 52, 70, 0.5);
}
.bg-background-light {
	&:before {
		background: $primary-1 !important;
	}
}
.error-image {
	&:before {
		background: $primary-8;
	}
}
.countdown {
	.number {
		background: $white;
	}
}
.details-accordion {
	.card {
		.card-header[aria-expanded="true"] {
			color: $primary;
		}
	}
}
.sw-theme-dots {
	>ul.step-anchor {
		>li.active {
			>a {
				color: $primary;
				&:after {
					background: $primary;
				}
			}
		}
	}
}
.login-tabs {
	.nav-tabs {
		.nav-link.active {
			background: $primary;
		}
	}
}
.special-property-offer {
	.item-card2-icons {
		a {
			background: #f8f8fb;
			color: $primary;
			&:hover {
				background: $primary;
				color: $white;
			}
		}
	}
}
.item-card2-icons {
	a.bg-purple-dark-transparent-5 {
		&:hover {
			background: $primary !important;
		}
	}
}
.item-search-menu {
	ul {
		li {
			.active {
				&:before {
					background: $primary;
				}
			}
		}
	}
}
.slide-menu {
	li.active {
		>a {
			&:hover {
				color: $primary !important;
			}
		}
	}
}
.user-tabs {
	ul {
		li {
			a.active {
				background: $primary;
				color: $white;
			}
		}
	}
}
.card-before-style {
	&:before {
		background: $primary-05;
	}
	&:after {
		background: rgba(85, 110, 230, 0.075);
	}
}
.main-shift-img {
	&:before {
		background: $primary;
	}
}
.category-image-shape {
	&:before {
		background: $primary-1;
	}
}
.view-btn {
	&:hover {
		background: $primary !important;
		color: $white;
	}
}
.status-img-circle {
	.svg-country-img {
		fill: $primary;
	}
}
.gallery-close-button {
	background: $primary;
	color: $white !important;
}
.spcl-cat-card {
	&:hover {
		background: $primary;
		color: $white;
		.item-card {
			.item-card-desc {
				&:before {
					background: rgba(48, 52, 70, 0.8);
				}
			}
		}
	}
	.item-card {
		.item-card-desc {
			&:before {
				background: rgba(48, 52, 70, 0);
			}
		}
	}
}
.selectgroup-sm {
	.selectgroup-button {
		border-color: $primary;
		color: $primary;
	}
	.selectgroup-input {
		&:checked {
			+ {
				.selectgroup-button {
					color: $white;
					border-color: $primary;
					background: $primary;
				}
			}
		}
	}
}
.br-theme-bars-horizontal {
	.br-widget {
		a.br-active {
			background-color: $primary;
		}
		a.br-selected {
			background-color: $primary;
		}
		.br-current-rating {
			color: $primary;
		}
	}
}
.br-theme-bars-pill {
	.br-widget {
		a {
			background-color: #f5f4fb;
			color: $primary;
		}
		a.br-active {
			background-color: $primary;
			color: white;
		}
		a.br-selected {
			background-color: $primary;
			color: white;
		}
	}
}
.br-theme-bars-square {
	.br-widget {
		a.br-active {
			border: 2px solid $primary;
			color: $primary;
		}
		a.br-selected {
			border: 2px solid $primary;
			color: $primary;
		}
	}
}
.br-theme-bars-movie {
	.br-widget {
		a.br-active {
			background-color: $primary;
		}
		a.br-selected {
			background-color: $primary;
		}
		.br-current-rating {
			color: $primary;
		}
	}
}
.br-theme-bars-1to10 {
	.br-widget {
		a.br-active {
			background-color: $primary;
		}
		a.br-selected {
			background-color: $primary;
		}
		.br-current-rating {
			color: $primary;
		}
	}
}
.br-theme-fontawesome-stars-o {
	.br-widget {
		a.br-active {
			&:after {
				content: '\f005';
				color: $primary;
			}
		}
		a.br-selected {
			&:after {
				content: '\f005';
				color: $primary;
			}
		}
		a.br-fractional {
			&:after {
				content: '\f123';
				color: $primary;
			}
		}
	}
}
.location.item-card {
	.item-card-desc {
		&:before {
			background: rgba(48, 52, 70, 0.65);
		}
	}
	&:hover {
		.item-card-desc {
			&:before {
				background: rgba(48, 52, 70, 0.85);
			}
		}
	}
}
.offer-card {
	&:before {
		background: rgba(48, 52, 70, 0.85);
	}
	.offer-absolute {
		background: rgba(48, 52, 70, 0.15);
	}
}
.item-card8-imgs {
	&:before {
		background: rgba(48, 52, 70, 0.3);
	}
}
.modal-open {
	#homeVideo.modal {
		background: rgba(48, 52, 70, 0.8);
	}
}
.img-color1 {
	display: none;
}
.img-color2 {
	display: none;
}
.img-color3 {
	display: none;
}
.img-color4 {
	display: none;
}
.img-color5 {
	display: none;
}
.img-color6 {
	display: none;
}
.img-color7 {
	display: none;
}
.img-color8 {
	display: none;
}
.img-color9 {
	display: none;
}
@media (max-width: 480px) {
	.rtl {
		.zoom-container {
			.arrow-ribbon2 {
				&:before {
					border-right: 17px solid $primary;
				}
			}
		}
	}
	.zoom-container {
		.arrow-ribbon2 {
			&:before {
				border-top: 17px solid transparent;
				border-left: 17px solid $primary;
				border-bottom: 17px solid transparent;
			}
		}
	}
}
@media (min-width: 992px) {
	.horizontalMenu {
		>.horizontalMenu-list {
			>li {
				>.horizontal-megamenu {
					.menu_form {
						input[type="submit"] {
							background-color: $primary;
							color: $white;
						}
						input[type="button"] {
							background-color: $primary;
							color: $white;
						}
					}
				}
				>a.active {
					background-color: transparent;
					color: $white;
				}
				&:hover {
					>a {
						background-color: transparent;
						color: $white !important;
						.fa {
							background-color: transparent;
							color: $white !important;
						}
					}
				}
				>ul.sub-menu {
					>li {
						&:hover {
							>a {
								background-color: transparent;
								color: $primary;
							}
						}
						>ul.sub-menu {
							>li {
								&:hover {
									>a {
										i {
											color: $primary;
										}
										background-color: transparent;
										color: $primary;
									}
								}
								>ul.sub-menu {
									>li {
										&:hover {
											a {
												background-color: transparent;
												color: $primary;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.sticky-wrapper.is-sticky {
		.horizontal-main {
			background: $white !important;
			&:after {
				background: $primary;
			}
		}
		.horizontalMenu {
			>.horizontalMenu-list {
				>li {
					>a.active {
						background-color: transparent;
						color: $primary !important;
						.fa {
							color: $primary !important;
							background: none;
						}
					}
					>a {
						&:hover {
							background-color: transparent;
							color: $primary !important;
							.fa {
								color: $primary !important;
								background: none;
							}
						}
					}
					&:hover {
						>a {
							color: $primary !important;
							.fa {
								color: $primary !important;
							}
						}
					}
				}
			}
		}
	}
}
@media (max-width: 992px) {
	.search-show {
		.nav-search {
			background: -webkit-gradient(linear, left top, right top, from(#6963ff), to(#3ba2ff));
			background: linear-gradient(to right, #6963ff, #3ba2ff);
		}
	}
	.nav-item.with-sub {
		.sub-item {
			border-top: 2px solid $primary;
		}
	}
}
@media (max-width: 991px) {
	.horizontalMenu {
		>.horizontalMenu-list {
			>li {
				>a.active {
					background-color: $white;
					color: $primary !important;
				}
			}
		}
	}
}
